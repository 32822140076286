/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { merchantManagementAddCustomComapnyEarningSetting } from '../fn/merchant-management/merchant-management-add-custom-comapny-earning-setting';
import { MerchantManagementAddCustomComapnyEarningSetting$Params } from '../fn/merchant-management/merchant-management-add-custom-comapny-earning-setting';
import { merchantManagementAddCustomComapnyEarningSetting$Plain } from '../fn/merchant-management/merchant-management-add-custom-comapny-earning-setting-plain';
import { MerchantManagementAddCustomComapnyEarningSetting$Plain$Params } from '../fn/merchant-management/merchant-management-add-custom-comapny-earning-setting-plain';
import { merchantManagementAddMerchant } from '../fn/merchant-management/merchant-management-add-merchant';
import { MerchantManagementAddMerchant$Params } from '../fn/merchant-management/merchant-management-add-merchant';
import { merchantManagementAddMerchant$Plain } from '../fn/merchant-management/merchant-management-add-merchant-plain';
import { MerchantManagementAddMerchant$Plain$Params } from '../fn/merchant-management/merchant-management-add-merchant-plain';
import { merchantManagementCreateRoleForMerchant } from '../fn/merchant-management/merchant-management-create-role-for-merchant';
import { MerchantManagementCreateRoleForMerchant$Params } from '../fn/merchant-management/merchant-management-create-role-for-merchant';
import { merchantManagementDeleteMerchant } from '../fn/merchant-management/merchant-management-delete-merchant';
import { MerchantManagementDeleteMerchant$Params } from '../fn/merchant-management/merchant-management-delete-merchant';
import { merchantManagementDeleteMerchant$Plain } from '../fn/merchant-management/merchant-management-delete-merchant-plain';
import { MerchantManagementDeleteMerchant$Plain$Params } from '../fn/merchant-management/merchant-management-delete-merchant-plain';
import { merchantManagementDeleteRoleForMerchant } from '../fn/merchant-management/merchant-management-delete-role-for-merchant';
import { MerchantManagementDeleteRoleForMerchant$Params } from '../fn/merchant-management/merchant-management-delete-role-for-merchant';
import { merchantManagementDeleteRoleForMerchant$Plain } from '../fn/merchant-management/merchant-management-delete-role-for-merchant-plain';
import { MerchantManagementDeleteRoleForMerchant$Plain$Params } from '../fn/merchant-management/merchant-management-delete-role-for-merchant-plain';
import { merchantManagementGetAllCompaniesBrand } from '../fn/merchant-management/merchant-management-get-all-companies-brand';
import { MerchantManagementGetAllCompaniesBrand$Params } from '../fn/merchant-management/merchant-management-get-all-companies-brand';
import { merchantManagementGetAllCompaniesBrand$Plain } from '../fn/merchant-management/merchant-management-get-all-companies-brand-plain';
import { MerchantManagementGetAllCompaniesBrand$Plain$Params } from '../fn/merchant-management/merchant-management-get-all-companies-brand-plain';
import { merchantManagementGetAllMerchant } from '../fn/merchant-management/merchant-management-get-all-merchant';
import { MerchantManagementGetAllMerchant$Params } from '../fn/merchant-management/merchant-management-get-all-merchant';
import { merchantManagementGetAllMerchant$Plain } from '../fn/merchant-management/merchant-management-get-all-merchant-plain';
import { MerchantManagementGetAllMerchant$Plain$Params } from '../fn/merchant-management/merchant-management-get-all-merchant-plain';
import { merchantManagementGetAllMerchantEarningSetting } from '../fn/merchant-management/merchant-management-get-all-merchant-earning-setting';
import { MerchantManagementGetAllMerchantEarningSetting$Params } from '../fn/merchant-management/merchant-management-get-all-merchant-earning-setting';
import { merchantManagementGetAllMerchantEarningSetting$Plain } from '../fn/merchant-management/merchant-management-get-all-merchant-earning-setting-plain';
import { MerchantManagementGetAllMerchantEarningSetting$Plain$Params } from '../fn/merchant-management/merchant-management-get-all-merchant-earning-setting-plain';
import { merchantManagementGetAllMerchantWithdrawalrequestsForAdmin } from '../fn/merchant-management/merchant-management-get-all-merchant-withdrawalrequests-for-admin';
import { MerchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Params } from '../fn/merchant-management/merchant-management-get-all-merchant-withdrawalrequests-for-admin';
import { merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Plain } from '../fn/merchant-management/merchant-management-get-all-merchant-withdrawalrequests-for-admin-plain';
import { MerchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Plain$Params } from '../fn/merchant-management/merchant-management-get-all-merchant-withdrawalrequests-for-admin-plain';
import { merchantManagementGetAllRolesForMerchant } from '../fn/merchant-management/merchant-management-get-all-roles-for-merchant';
import { MerchantManagementGetAllRolesForMerchant$Params } from '../fn/merchant-management/merchant-management-get-all-roles-for-merchant';
import { merchantManagementGetAllRolesForMerchant$Plain } from '../fn/merchant-management/merchant-management-get-all-roles-for-merchant-plain';
import { MerchantManagementGetAllRolesForMerchant$Plain$Params } from '../fn/merchant-management/merchant-management-get-all-roles-for-merchant-plain';
import { merchantManagementGetBrandsCustomEarning } from '../fn/merchant-management/merchant-management-get-brands-custom-earning';
import { MerchantManagementGetBrandsCustomEarning$Params } from '../fn/merchant-management/merchant-management-get-brands-custom-earning';
import { merchantManagementGetBrandsCustomEarning$Plain } from '../fn/merchant-management/merchant-management-get-brands-custom-earning-plain';
import { MerchantManagementGetBrandsCustomEarning$Plain$Params } from '../fn/merchant-management/merchant-management-get-brands-custom-earning-plain';
import { merchantManagementGetCompanyCustomEarning } from '../fn/merchant-management/merchant-management-get-company-custom-earning';
import { MerchantManagementGetCompanyCustomEarning$Params } from '../fn/merchant-management/merchant-management-get-company-custom-earning';
import { merchantManagementGetCompanyCustomEarning$Plain } from '../fn/merchant-management/merchant-management-get-company-custom-earning-plain';
import { MerchantManagementGetCompanyCustomEarning$Plain$Params } from '../fn/merchant-management/merchant-management-get-company-custom-earning-plain';
import { merchantManagementGetMenusPagesPermissionForMerchantByRoleId } from '../fn/merchant-management/merchant-management-get-menus-pages-permission-for-merchant-by-role-id';
import { MerchantManagementGetMenusPagesPermissionForMerchantByRoleId$Params } from '../fn/merchant-management/merchant-management-get-menus-pages-permission-for-merchant-by-role-id';
import { merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Plain } from '../fn/merchant-management/merchant-management-get-menus-pages-permission-for-merchant-by-role-id-plain';
import { MerchantManagementGetMenusPagesPermissionForMerchantByRoleId$Plain$Params } from '../fn/merchant-management/merchant-management-get-menus-pages-permission-for-merchant-by-role-id-plain';
import { merchantManagementGetMenusPagesPermissionsForMerchant } from '../fn/merchant-management/merchant-management-get-menus-pages-permissions-for-merchant';
import { MerchantManagementGetMenusPagesPermissionsForMerchant$Params } from '../fn/merchant-management/merchant-management-get-menus-pages-permissions-for-merchant';
import { merchantManagementGetMenusPagesPermissionsForMerchant$Plain } from '../fn/merchant-management/merchant-management-get-menus-pages-permissions-for-merchant-plain';
import { MerchantManagementGetMenusPagesPermissionsForMerchant$Plain$Params } from '../fn/merchant-management/merchant-management-get-menus-pages-permissions-for-merchant-plain';
import { merchantManagementGetMerchantCompany } from '../fn/merchant-management/merchant-management-get-merchant-company';
import { MerchantManagementGetMerchantCompany$Params } from '../fn/merchant-management/merchant-management-get-merchant-company';
import { merchantManagementGetMerchantCompany$Plain } from '../fn/merchant-management/merchant-management-get-merchant-company-plain';
import { MerchantManagementGetMerchantCompany$Plain$Params } from '../fn/merchant-management/merchant-management-get-merchant-company-plain';
import { merchantManagementUpdateMerchant } from '../fn/merchant-management/merchant-management-update-merchant';
import { MerchantManagementUpdateMerchant$Params } from '../fn/merchant-management/merchant-management-update-merchant';
import { merchantManagementUpdateMerchant$Plain } from '../fn/merchant-management/merchant-management-update-merchant-plain';
import { MerchantManagementUpdateMerchant$Plain$Params } from '../fn/merchant-management/merchant-management-update-merchant-plain';
import { merchantManagementUpdateMerchantEarningSetting } from '../fn/merchant-management/merchant-management-update-merchant-earning-setting';
import { MerchantManagementUpdateMerchantEarningSetting$Params } from '../fn/merchant-management/merchant-management-update-merchant-earning-setting';
import { merchantManagementUpdateMerchantEarningSetting$Plain } from '../fn/merchant-management/merchant-management-update-merchant-earning-setting-plain';
import { MerchantManagementUpdateMerchantEarningSetting$Plain$Params } from '../fn/merchant-management/merchant-management-update-merchant-earning-setting-plain';
import { merchantManagementUpdateMerchantWithdrawalStatusByAdmin } from '../fn/merchant-management/merchant-management-update-merchant-withdrawal-status-by-admin';
import { MerchantManagementUpdateMerchantWithdrawalStatusByAdmin$Params } from '../fn/merchant-management/merchant-management-update-merchant-withdrawal-status-by-admin';
import { merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Plain } from '../fn/merchant-management/merchant-management-update-merchant-withdrawal-status-by-admin-plain';
import { MerchantManagementUpdateMerchantWithdrawalStatusByAdmin$Plain$Params } from '../fn/merchant-management/merchant-management-update-merchant-withdrawal-status-by-admin-plain';
import { merchantManagementUpdateRoleForMerchant } from '../fn/merchant-management/merchant-management-update-role-for-merchant';
import { MerchantManagementUpdateRoleForMerchant$Params } from '../fn/merchant-management/merchant-management-update-role-for-merchant';
import { merchantManagementUpdateRoleWithPermissionsForMerchant } from '../fn/merchant-management/merchant-management-update-role-with-permissions-for-merchant';
import { MerchantManagementUpdateRoleWithPermissionsForMerchant$Params } from '../fn/merchant-management/merchant-management-update-role-with-permissions-for-merchant';
import { OutputAllMenuPagesPermissionDto } from '../models/output-all-menu-pages-permission-dto';
import { OutputAllRolesDto } from '../models/output-all-roles-dto';
import { OutputGetAllBrandsDto } from '../models/output-get-all-brands-dto';
import { OutputGetAllCompanysDto } from '../models/output-get-all-companys-dto';
import { OutputGetAllMerchantForTableDto } from '../models/output-get-all-merchant-for-table-dto';
import { OutPutGetCustomBrandEarningSettingDto } from '../models/out-put-get-custom-brand-earning-setting-dto';
import { OutPutGetCustomEarningSettingDto } from '../models/out-put-get-custom-earning-setting-dto';
import { OutputMerchantEarningSettingDto } from '../models/output-merchant-earning-setting-dto';
import { OutputMerchantWithdrawalRequestForAdminDto } from '../models/output-merchant-withdrawal-request-for-admin-dto';

@Injectable({ providedIn: 'root' })
export class MerchantManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `merchantManagementGetAllMerchant()` */
  static readonly MerchantManagementGetAllMerchantPath = '/api/Merchant/MerchantManagement/GetAllMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllMerchant$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchant$Plain$Response(params?: MerchantManagementGetAllMerchant$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllMerchantForTableDto>>> {
    return merchantManagementGetAllMerchant$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllMerchant$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchant$Plain(params?: MerchantManagementGetAllMerchant$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllMerchantForTableDto>> {
    return this.merchantManagementGetAllMerchant$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMerchantForTableDto>>): Array<OutputGetAllMerchantForTableDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllMerchant()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchant$Response(params?: MerchantManagementGetAllMerchant$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllMerchantForTableDto>>> {
    return merchantManagementGetAllMerchant(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllMerchant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchant(params?: MerchantManagementGetAllMerchant$Params, context?: HttpContext): Observable<Array<OutputGetAllMerchantForTableDto>> {
    return this.merchantManagementGetAllMerchant$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMerchantForTableDto>>): Array<OutputGetAllMerchantForTableDto> => r.body)
    );
  }

  /** Path part for operation `merchantManagementGetMerchantCompany()` */
  static readonly MerchantManagementGetMerchantCompanyPath = '/api/Merchant/MerchantManagement/GetMerchantCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetMerchantCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMerchantCompany$Plain$Response(params?: MerchantManagementGetMerchantCompany$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetAllCompanysDto>> {
    return merchantManagementGetMerchantCompany$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetMerchantCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMerchantCompany$Plain(params?: MerchantManagementGetMerchantCompany$Plain$Params, context?: HttpContext): Observable<OutputGetAllCompanysDto> {
    return this.merchantManagementGetMerchantCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetAllCompanysDto>): OutputGetAllCompanysDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetMerchantCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMerchantCompany$Response(params?: MerchantManagementGetMerchantCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetAllCompanysDto>> {
    return merchantManagementGetMerchantCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetMerchantCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMerchantCompany(params?: MerchantManagementGetMerchantCompany$Params, context?: HttpContext): Observable<OutputGetAllCompanysDto> {
    return this.merchantManagementGetMerchantCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetAllCompanysDto>): OutputGetAllCompanysDto => r.body)
    );
  }

  /** Path part for operation `merchantManagementAddMerchant()` */
  static readonly MerchantManagementAddMerchantPath = '/api/Merchant/MerchantManagement/AddMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementAddMerchant$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementAddMerchant$Plain$Response(params?: MerchantManagementAddMerchant$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return merchantManagementAddMerchant$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementAddMerchant$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementAddMerchant$Plain(params?: MerchantManagementAddMerchant$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.merchantManagementAddMerchant$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementAddMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementAddMerchant$Response(params?: MerchantManagementAddMerchant$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return merchantManagementAddMerchant(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementAddMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementAddMerchant(params?: MerchantManagementAddMerchant$Params, context?: HttpContext): Observable<boolean> {
    return this.merchantManagementAddMerchant$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `merchantManagementUpdateMerchant()` */
  static readonly MerchantManagementUpdateMerchantPath = '/api/Merchant/MerchantManagement/UpdateMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementUpdateMerchant$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchant$Plain$Response(params?: MerchantManagementUpdateMerchant$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return merchantManagementUpdateMerchant$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementUpdateMerchant$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchant$Plain(params?: MerchantManagementUpdateMerchant$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.merchantManagementUpdateMerchant$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementUpdateMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchant$Response(params?: MerchantManagementUpdateMerchant$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return merchantManagementUpdateMerchant(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementUpdateMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchant(params?: MerchantManagementUpdateMerchant$Params, context?: HttpContext): Observable<boolean> {
    return this.merchantManagementUpdateMerchant$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `merchantManagementDeleteMerchant()` */
  static readonly MerchantManagementDeleteMerchantPath = '/api/Merchant/MerchantManagement/DeleteMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementDeleteMerchant$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementDeleteMerchant$Plain$Response(params?: MerchantManagementDeleteMerchant$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return merchantManagementDeleteMerchant$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementDeleteMerchant$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementDeleteMerchant$Plain(params?: MerchantManagementDeleteMerchant$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.merchantManagementDeleteMerchant$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementDeleteMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementDeleteMerchant$Response(params?: MerchantManagementDeleteMerchant$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return merchantManagementDeleteMerchant(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementDeleteMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementDeleteMerchant(params?: MerchantManagementDeleteMerchant$Params, context?: HttpContext): Observable<boolean> {
    return this.merchantManagementDeleteMerchant$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `merchantManagementGetAllRolesForMerchant()` */
  static readonly MerchantManagementGetAllRolesForMerchantPath = '/api/Merchant/MerchantManagement/GetAllRolesForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllRolesForMerchant$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllRolesForMerchant$Plain$Response(params?: MerchantManagementGetAllRolesForMerchant$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllRolesDto>>> {
    return merchantManagementGetAllRolesForMerchant$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllRolesForMerchant$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllRolesForMerchant$Plain(params?: MerchantManagementGetAllRolesForMerchant$Plain$Params, context?: HttpContext): Observable<Array<OutputAllRolesDto>> {
    return this.merchantManagementGetAllRolesForMerchant$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllRolesDto>>): Array<OutputAllRolesDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllRolesForMerchant()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllRolesForMerchant$Response(params?: MerchantManagementGetAllRolesForMerchant$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllRolesDto>>> {
    return merchantManagementGetAllRolesForMerchant(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllRolesForMerchant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllRolesForMerchant(params?: MerchantManagementGetAllRolesForMerchant$Params, context?: HttpContext): Observable<Array<OutputAllRolesDto>> {
    return this.merchantManagementGetAllRolesForMerchant$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllRolesDto>>): Array<OutputAllRolesDto> => r.body)
    );
  }

  /** Path part for operation `merchantManagementCreateRoleForMerchant()` */
  static readonly MerchantManagementCreateRoleForMerchantPath = '/api/Merchant/MerchantManagement/CreateRoleForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementCreateRoleForMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementCreateRoleForMerchant$Response(params?: MerchantManagementCreateRoleForMerchant$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return merchantManagementCreateRoleForMerchant(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementCreateRoleForMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementCreateRoleForMerchant(params?: MerchantManagementCreateRoleForMerchant$Params, context?: HttpContext): Observable<void> {
    return this.merchantManagementCreateRoleForMerchant$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `merchantManagementDeleteRoleForMerchant()` */
  static readonly MerchantManagementDeleteRoleForMerchantPath = '/api/Merchant/MerchantManagement/DeleteRoleForMerchant';

  /**
   * DeleteRole.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementDeleteRoleForMerchant$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementDeleteRoleForMerchant$Plain$Response(params?: MerchantManagementDeleteRoleForMerchant$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return merchantManagementDeleteRoleForMerchant$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * DeleteRole.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementDeleteRoleForMerchant$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementDeleteRoleForMerchant$Plain(params?: MerchantManagementDeleteRoleForMerchant$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.merchantManagementDeleteRoleForMerchant$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * DeleteRole.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementDeleteRoleForMerchant()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementDeleteRoleForMerchant$Response(params?: MerchantManagementDeleteRoleForMerchant$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return merchantManagementDeleteRoleForMerchant(this.http, this.rootUrl, params, context);
  }

  /**
   * DeleteRole.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementDeleteRoleForMerchant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementDeleteRoleForMerchant(params?: MerchantManagementDeleteRoleForMerchant$Params, context?: HttpContext): Observable<boolean> {
    return this.merchantManagementDeleteRoleForMerchant$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `merchantManagementUpdateRoleForMerchant()` */
  static readonly MerchantManagementUpdateRoleForMerchantPath = '/api/Merchant/MerchantManagement/UpdateRoleForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementUpdateRoleForMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateRoleForMerchant$Response(params?: MerchantManagementUpdateRoleForMerchant$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return merchantManagementUpdateRoleForMerchant(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementUpdateRoleForMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateRoleForMerchant(params?: MerchantManagementUpdateRoleForMerchant$Params, context?: HttpContext): Observable<void> {
    return this.merchantManagementUpdateRoleForMerchant$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `merchantManagementUpdateRoleWithPermissionsForMerchant()` */
  static readonly MerchantManagementUpdateRoleWithPermissionsForMerchantPath = '/api/Merchant/MerchantManagement/UpdateRoleWithPermissionsForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementUpdateRoleWithPermissionsForMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateRoleWithPermissionsForMerchant$Response(params?: MerchantManagementUpdateRoleWithPermissionsForMerchant$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return merchantManagementUpdateRoleWithPermissionsForMerchant(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementUpdateRoleWithPermissionsForMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateRoleWithPermissionsForMerchant(params?: MerchantManagementUpdateRoleWithPermissionsForMerchant$Params, context?: HttpContext): Observable<void> {
    return this.merchantManagementUpdateRoleWithPermissionsForMerchant$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `merchantManagementGetMenusPagesPermissionForMerchantByRoleId()` */
  static readonly MerchantManagementGetMenusPagesPermissionForMerchantByRoleIdPath = '/api/Merchant/MerchantManagement/GetMenusPagesPermissionForMerchantByRoleId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Plain$Response(params?: MerchantManagementGetMenusPagesPermissionForMerchantByRoleId$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>> {
    return merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Plain(params?: MerchantManagementGetMenusPagesPermissionForMerchantByRoleId$Plain$Params, context?: HttpContext): Observable<Array<OutputAllMenuPagesPermissionDto>> {
    return this.merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>): Array<OutputAllMenuPagesPermissionDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetMenusPagesPermissionForMerchantByRoleId()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Response(params?: MerchantManagementGetMenusPagesPermissionForMerchantByRoleId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>> {
    return merchantManagementGetMenusPagesPermissionForMerchantByRoleId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMenusPagesPermissionForMerchantByRoleId(params?: MerchantManagementGetMenusPagesPermissionForMerchantByRoleId$Params, context?: HttpContext): Observable<Array<OutputAllMenuPagesPermissionDto>> {
    return this.merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>): Array<OutputAllMenuPagesPermissionDto> => r.body)
    );
  }

  /** Path part for operation `merchantManagementGetMenusPagesPermissionsForMerchant()` */
  static readonly MerchantManagementGetMenusPagesPermissionsForMerchantPath = '/api/Merchant/MerchantManagement/GetMenusPagesPermissionsForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetMenusPagesPermissionsForMerchant$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMenusPagesPermissionsForMerchant$Plain$Response(params?: MerchantManagementGetMenusPagesPermissionsForMerchant$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>> {
    return merchantManagementGetMenusPagesPermissionsForMerchant$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetMenusPagesPermissionsForMerchant$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMenusPagesPermissionsForMerchant$Plain(params?: MerchantManagementGetMenusPagesPermissionsForMerchant$Plain$Params, context?: HttpContext): Observable<Array<OutputAllMenuPagesPermissionDto>> {
    return this.merchantManagementGetMenusPagesPermissionsForMerchant$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>): Array<OutputAllMenuPagesPermissionDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetMenusPagesPermissionsForMerchant()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMenusPagesPermissionsForMerchant$Response(params?: MerchantManagementGetMenusPagesPermissionsForMerchant$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>> {
    return merchantManagementGetMenusPagesPermissionsForMerchant(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetMenusPagesPermissionsForMerchant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMenusPagesPermissionsForMerchant(params?: MerchantManagementGetMenusPagesPermissionsForMerchant$Params, context?: HttpContext): Observable<Array<OutputAllMenuPagesPermissionDto>> {
    return this.merchantManagementGetMenusPagesPermissionsForMerchant$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>): Array<OutputAllMenuPagesPermissionDto> => r.body)
    );
  }

  /** Path part for operation `merchantManagementUpdateMerchantEarningSetting()` */
  static readonly MerchantManagementUpdateMerchantEarningSettingPath = '/api/admin/MerchantManagement/UpdateMerchantEarningSetting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementUpdateMerchantEarningSetting$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchantEarningSetting$Plain$Response(params?: MerchantManagementUpdateMerchantEarningSetting$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return merchantManagementUpdateMerchantEarningSetting$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementUpdateMerchantEarningSetting$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchantEarningSetting$Plain(params?: MerchantManagementUpdateMerchantEarningSetting$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.merchantManagementUpdateMerchantEarningSetting$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementUpdateMerchantEarningSetting()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchantEarningSetting$Response(params?: MerchantManagementUpdateMerchantEarningSetting$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return merchantManagementUpdateMerchantEarningSetting(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementUpdateMerchantEarningSetting$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchantEarningSetting(params?: MerchantManagementUpdateMerchantEarningSetting$Params, context?: HttpContext): Observable<boolean> {
    return this.merchantManagementUpdateMerchantEarningSetting$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `merchantManagementGetAllMerchantEarningSetting()` */
  static readonly MerchantManagementGetAllMerchantEarningSettingPath = '/api/admin/MerchantManagement/GetAllMerchantEarningSetting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllMerchantEarningSetting$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchantEarningSetting$Plain$Response(params?: MerchantManagementGetAllMerchantEarningSetting$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputMerchantEarningSettingDto>>> {
    return merchantManagementGetAllMerchantEarningSetting$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllMerchantEarningSetting$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchantEarningSetting$Plain(params?: MerchantManagementGetAllMerchantEarningSetting$Plain$Params, context?: HttpContext): Observable<Array<OutputMerchantEarningSettingDto>> {
    return this.merchantManagementGetAllMerchantEarningSetting$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputMerchantEarningSettingDto>>): Array<OutputMerchantEarningSettingDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllMerchantEarningSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchantEarningSetting$Response(params?: MerchantManagementGetAllMerchantEarningSetting$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputMerchantEarningSettingDto>>> {
    return merchantManagementGetAllMerchantEarningSetting(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllMerchantEarningSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchantEarningSetting(params?: MerchantManagementGetAllMerchantEarningSetting$Params, context?: HttpContext): Observable<Array<OutputMerchantEarningSettingDto>> {
    return this.merchantManagementGetAllMerchantEarningSetting$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputMerchantEarningSettingDto>>): Array<OutputMerchantEarningSettingDto> => r.body)
    );
  }

  /** Path part for operation `merchantManagementGetAllCompaniesBrand()` */
  static readonly MerchantManagementGetAllCompaniesBrandPath = '/api/admin/MerchantManagement/GetAllCompaniesBrand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllCompaniesBrand$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllCompaniesBrand$Plain$Response(params?: MerchantManagementGetAllCompaniesBrand$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllBrandsDto>>> {
    return merchantManagementGetAllCompaniesBrand$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllCompaniesBrand$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllCompaniesBrand$Plain(params?: MerchantManagementGetAllCompaniesBrand$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllBrandsDto>> {
    return this.merchantManagementGetAllCompaniesBrand$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBrandsDto>>): Array<OutputGetAllBrandsDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllCompaniesBrand()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllCompaniesBrand$Response(params?: MerchantManagementGetAllCompaniesBrand$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllBrandsDto>>> {
    return merchantManagementGetAllCompaniesBrand(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllCompaniesBrand$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllCompaniesBrand(params?: MerchantManagementGetAllCompaniesBrand$Params, context?: HttpContext): Observable<Array<OutputGetAllBrandsDto>> {
    return this.merchantManagementGetAllCompaniesBrand$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBrandsDto>>): Array<OutputGetAllBrandsDto> => r.body)
    );
  }

  /** Path part for operation `merchantManagementGetAllMerchantWithdrawalrequestsForAdmin()` */
  static readonly MerchantManagementGetAllMerchantWithdrawalrequestsForAdminPath = '/api/admin/MerchantManagement/GetAllMerchantWithdrawalrequestsForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Plain$Response(params?: MerchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputMerchantWithdrawalRequestForAdminDto>>> {
    return merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Plain(params?: MerchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Plain$Params, context?: HttpContext): Observable<Array<OutputMerchantWithdrawalRequestForAdminDto>> {
    return this.merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputMerchantWithdrawalRequestForAdminDto>>): Array<OutputMerchantWithdrawalRequestForAdminDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllMerchantWithdrawalrequestsForAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Response(params?: MerchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputMerchantWithdrawalRequestForAdminDto>>> {
    return merchantManagementGetAllMerchantWithdrawalrequestsForAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchantWithdrawalrequestsForAdmin(params?: MerchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Params, context?: HttpContext): Observable<Array<OutputMerchantWithdrawalRequestForAdminDto>> {
    return this.merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputMerchantWithdrawalRequestForAdminDto>>): Array<OutputMerchantWithdrawalRequestForAdminDto> => r.body)
    );
  }

  /** Path part for operation `merchantManagementGetBrandsCustomEarning()` */
  static readonly MerchantManagementGetBrandsCustomEarningPath = '/api/admin/MerchantManagement/GetBrandsCustomEarning';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetBrandsCustomEarning$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetBrandsCustomEarning$Plain$Response(params?: MerchantManagementGetBrandsCustomEarning$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutPutGetCustomBrandEarningSettingDto>>> {
    return merchantManagementGetBrandsCustomEarning$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetBrandsCustomEarning$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetBrandsCustomEarning$Plain(params?: MerchantManagementGetBrandsCustomEarning$Plain$Params, context?: HttpContext): Observable<Array<OutPutGetCustomBrandEarningSettingDto>> {
    return this.merchantManagementGetBrandsCustomEarning$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetCustomBrandEarningSettingDto>>): Array<OutPutGetCustomBrandEarningSettingDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetBrandsCustomEarning()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetBrandsCustomEarning$Response(params?: MerchantManagementGetBrandsCustomEarning$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutPutGetCustomBrandEarningSettingDto>>> {
    return merchantManagementGetBrandsCustomEarning(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetBrandsCustomEarning$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetBrandsCustomEarning(params?: MerchantManagementGetBrandsCustomEarning$Params, context?: HttpContext): Observable<Array<OutPutGetCustomBrandEarningSettingDto>> {
    return this.merchantManagementGetBrandsCustomEarning$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetCustomBrandEarningSettingDto>>): Array<OutPutGetCustomBrandEarningSettingDto> => r.body)
    );
  }

  /** Path part for operation `merchantManagementGetCompanyCustomEarning()` */
  static readonly MerchantManagementGetCompanyCustomEarningPath = '/api/admin/MerchantManagement/GetCompanyCustomEarning';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetCompanyCustomEarning$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetCompanyCustomEarning$Plain$Response(params?: MerchantManagementGetCompanyCustomEarning$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutPutGetCustomEarningSettingDto>>> {
    return merchantManagementGetCompanyCustomEarning$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetCompanyCustomEarning$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetCompanyCustomEarning$Plain(params?: MerchantManagementGetCompanyCustomEarning$Plain$Params, context?: HttpContext): Observable<Array<OutPutGetCustomEarningSettingDto>> {
    return this.merchantManagementGetCompanyCustomEarning$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetCustomEarningSettingDto>>): Array<OutPutGetCustomEarningSettingDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetCompanyCustomEarning()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetCompanyCustomEarning$Response(params?: MerchantManagementGetCompanyCustomEarning$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutPutGetCustomEarningSettingDto>>> {
    return merchantManagementGetCompanyCustomEarning(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetCompanyCustomEarning$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetCompanyCustomEarning(params?: MerchantManagementGetCompanyCustomEarning$Params, context?: HttpContext): Observable<Array<OutPutGetCustomEarningSettingDto>> {
    return this.merchantManagementGetCompanyCustomEarning$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetCustomEarningSettingDto>>): Array<OutPutGetCustomEarningSettingDto> => r.body)
    );
  }

  /** Path part for operation `merchantManagementUpdateMerchantWithdrawalStatusByAdmin()` */
  static readonly MerchantManagementUpdateMerchantWithdrawalStatusByAdminPath = '/api/admin/MerchantManagement/UpdateMerchantWithdrawalStatusByAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Plain$Response(params?: MerchantManagementUpdateMerchantWithdrawalStatusByAdmin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Plain(params?: MerchantManagementUpdateMerchantWithdrawalStatusByAdmin$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementUpdateMerchantWithdrawalStatusByAdmin()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Response(params?: MerchantManagementUpdateMerchantWithdrawalStatusByAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return merchantManagementUpdateMerchantWithdrawalStatusByAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchantWithdrawalStatusByAdmin(params?: MerchantManagementUpdateMerchantWithdrawalStatusByAdmin$Params, context?: HttpContext): Observable<boolean> {
    return this.merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `merchantManagementAddCustomComapnyEarningSetting()` */
  static readonly MerchantManagementAddCustomComapnyEarningSettingPath = '/api/admin/MerchantManagement/AddCustomComapnyEarningSetting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementAddCustomComapnyEarningSetting$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementAddCustomComapnyEarningSetting$Plain$Response(params?: MerchantManagementAddCustomComapnyEarningSetting$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return merchantManagementAddCustomComapnyEarningSetting$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementAddCustomComapnyEarningSetting$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementAddCustomComapnyEarningSetting$Plain(params?: MerchantManagementAddCustomComapnyEarningSetting$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.merchantManagementAddCustomComapnyEarningSetting$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementAddCustomComapnyEarningSetting()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementAddCustomComapnyEarningSetting$Response(params?: MerchantManagementAddCustomComapnyEarningSetting$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return merchantManagementAddCustomComapnyEarningSetting(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `merchantManagementAddCustomComapnyEarningSetting$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementAddCustomComapnyEarningSetting(params?: MerchantManagementAddCustomComapnyEarningSetting$Params, context?: HttpContext): Observable<boolean> {
    return this.merchantManagementAddCustomComapnyEarningSetting$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
