/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { dashboardGetChartData } from '../fn/dashboard/dashboard-get-chart-data';
import { DashboardGetChartData$Params } from '../fn/dashboard/dashboard-get-chart-data';
import { dashboardGetChartData$Plain } from '../fn/dashboard/dashboard-get-chart-data-plain';
import { DashboardGetChartData$Plain$Params } from '../fn/dashboard/dashboard-get-chart-data-plain';
import { dashboardGetChartDataForAdmin } from '../fn/dashboard/dashboard-get-chart-data-for-admin';
import { DashboardGetChartDataForAdmin$Params } from '../fn/dashboard/dashboard-get-chart-data-for-admin';
import { dashboardGetChartDataForAdmin$Plain } from '../fn/dashboard/dashboard-get-chart-data-for-admin-plain';
import { DashboardGetChartDataForAdmin$Plain$Params } from '../fn/dashboard/dashboard-get-chart-data-for-admin-plain';
import { OutputGetAllChartDataDto } from '../models/output-get-all-chart-data-dto';

@Injectable({ providedIn: 'root' })
export class DashboardService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `dashboardGetChartData()` */
  static readonly DashboardGetChartDataPath = '/api/Merchant/Dashboard/GetChartData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardGetChartData$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardGetChartData$Plain$Response(params?: DashboardGetChartData$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetAllChartDataDto>> {
    return dashboardGetChartData$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dashboardGetChartData$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardGetChartData$Plain(params?: DashboardGetChartData$Plain$Params, context?: HttpContext): Observable<OutputGetAllChartDataDto> {
    return this.dashboardGetChartData$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetAllChartDataDto>): OutputGetAllChartDataDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardGetChartData()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardGetChartData$Response(params?: DashboardGetChartData$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetAllChartDataDto>> {
    return dashboardGetChartData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dashboardGetChartData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardGetChartData(params?: DashboardGetChartData$Params, context?: HttpContext): Observable<OutputGetAllChartDataDto> {
    return this.dashboardGetChartData$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetAllChartDataDto>): OutputGetAllChartDataDto => r.body)
    );
  }

  /** Path part for operation `dashboardGetChartDataForAdmin()` */
  static readonly DashboardGetChartDataForAdminPath = '/api/admin/Dashboard/GetChartDataForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardGetChartDataForAdmin$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardGetChartDataForAdmin$Plain$Response(params?: DashboardGetChartDataForAdmin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetAllChartDataDto>> {
    return dashboardGetChartDataForAdmin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dashboardGetChartDataForAdmin$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardGetChartDataForAdmin$Plain(params?: DashboardGetChartDataForAdmin$Plain$Params, context?: HttpContext): Observable<OutputGetAllChartDataDto> {
    return this.dashboardGetChartDataForAdmin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetAllChartDataDto>): OutputGetAllChartDataDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardGetChartDataForAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardGetChartDataForAdmin$Response(params?: DashboardGetChartDataForAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetAllChartDataDto>> {
    return dashboardGetChartDataForAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dashboardGetChartDataForAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardGetChartDataForAdmin(params?: DashboardGetChartDataForAdmin$Params, context?: HttpContext): Observable<OutputGetAllChartDataDto> {
    return this.dashboardGetChartDataForAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetAllChartDataDto>): OutputGetAllChartDataDto => r.body)
    );
  }

}
