/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { OutputAllClientsDto } from '../models/output-all-clients-dto';
import { OutputAllMenuPagesPermissionDto } from '../models/output-all-menu-pages-permission-dto';
import { OutputAllRolesDto } from '../models/output-all-roles-dto';
import { OutputAllUsersDto } from '../models/output-all-users-dto';
import { OutputGetAllCompanysDto } from '../models/output-get-all-companys-dto';
import { OutputGetAllMerchantDto } from '../models/output-get-all-merchant-dto';
import { OutputGetAllMerchantForAdmin } from '../models/output-get-all-merchant-for-admin';
import { userManagementAcceptMerchantCompany } from '../fn/user-management/user-management-accept-merchant-company';
import { UserManagementAcceptMerchantCompany$Params } from '../fn/user-management/user-management-accept-merchant-company';
import { userManagementAcceptMerchantCompany$Plain } from '../fn/user-management/user-management-accept-merchant-company-plain';
import { UserManagementAcceptMerchantCompany$Plain$Params } from '../fn/user-management/user-management-accept-merchant-company-plain';
import { userManagementCreateClient } from '../fn/user-management/user-management-create-client';
import { UserManagementCreateClient$Params } from '../fn/user-management/user-management-create-client';
import { userManagementCreateClient$Plain } from '../fn/user-management/user-management-create-client-plain';
import { UserManagementCreateClient$Plain$Params } from '../fn/user-management/user-management-create-client-plain';
import { userManagementCreateCompany } from '../fn/user-management/user-management-create-company';
import { UserManagementCreateCompany$Params } from '../fn/user-management/user-management-create-company';
import { userManagementCreateCompany$Plain } from '../fn/user-management/user-management-create-company-plain';
import { UserManagementCreateCompany$Plain$Params } from '../fn/user-management/user-management-create-company-plain';
import { userManagementCreateMerchantForAdmin } from '../fn/user-management/user-management-create-merchant-for-admin';
import { UserManagementCreateMerchantForAdmin$Params } from '../fn/user-management/user-management-create-merchant-for-admin';
import { userManagementCreateMerchantForAdmin$Plain } from '../fn/user-management/user-management-create-merchant-for-admin-plain';
import { UserManagementCreateMerchantForAdmin$Plain$Params } from '../fn/user-management/user-management-create-merchant-for-admin-plain';
import { userManagementCreateRole } from '../fn/user-management/user-management-create-role';
import { UserManagementCreateRole$Params } from '../fn/user-management/user-management-create-role';
import { userManagementCreateUser } from '../fn/user-management/user-management-create-user';
import { UserManagementCreateUser$Params } from '../fn/user-management/user-management-create-user';
import { userManagementDeleteClient } from '../fn/user-management/user-management-delete-client';
import { UserManagementDeleteClient$Params } from '../fn/user-management/user-management-delete-client';
import { userManagementDeleteCompany } from '../fn/user-management/user-management-delete-company';
import { UserManagementDeleteCompany$Params } from '../fn/user-management/user-management-delete-company';
import { userManagementDeleteCompany$Plain } from '../fn/user-management/user-management-delete-company-plain';
import { UserManagementDeleteCompany$Plain$Params } from '../fn/user-management/user-management-delete-company-plain';
import { userManagementDeleteMerchantForAdmin } from '../fn/user-management/user-management-delete-merchant-for-admin';
import { UserManagementDeleteMerchantForAdmin$Params } from '../fn/user-management/user-management-delete-merchant-for-admin';
import { userManagementDeleteMerchantForAdmin$Plain } from '../fn/user-management/user-management-delete-merchant-for-admin-plain';
import { UserManagementDeleteMerchantForAdmin$Plain$Params } from '../fn/user-management/user-management-delete-merchant-for-admin-plain';
import { userManagementDeleteRole } from '../fn/user-management/user-management-delete-role';
import { UserManagementDeleteRole$Params } from '../fn/user-management/user-management-delete-role';
import { userManagementDeleteRole$Plain } from '../fn/user-management/user-management-delete-role-plain';
import { UserManagementDeleteRole$Plain$Params } from '../fn/user-management/user-management-delete-role-plain';
import { userManagementDeleteUser } from '../fn/user-management/user-management-delete-user';
import { UserManagementDeleteUser$Params } from '../fn/user-management/user-management-delete-user';
import { userManagementGetAllClients } from '../fn/user-management/user-management-get-all-clients';
import { UserManagementGetAllClients$Params } from '../fn/user-management/user-management-get-all-clients';
import { userManagementGetAllClients$Plain } from '../fn/user-management/user-management-get-all-clients-plain';
import { UserManagementGetAllClients$Plain$Params } from '../fn/user-management/user-management-get-all-clients-plain';
import { userManagementGetAllCompany } from '../fn/user-management/user-management-get-all-company';
import { UserManagementGetAllCompany$Params } from '../fn/user-management/user-management-get-all-company';
import { userManagementGetAllCompany$Plain } from '../fn/user-management/user-management-get-all-company-plain';
import { UserManagementGetAllCompany$Plain$Params } from '../fn/user-management/user-management-get-all-company-plain';
import { userManagementGetAllCompanyRequests } from '../fn/user-management/user-management-get-all-company-requests';
import { UserManagementGetAllCompanyRequests$Params } from '../fn/user-management/user-management-get-all-company-requests';
import { userManagementGetAllCompanyRequests$Plain } from '../fn/user-management/user-management-get-all-company-requests-plain';
import { UserManagementGetAllCompanyRequests$Plain$Params } from '../fn/user-management/user-management-get-all-company-requests-plain';
import { userManagementGetAllMerchantForAdmin } from '../fn/user-management/user-management-get-all-merchant-for-admin';
import { UserManagementGetAllMerchantForAdmin$Params } from '../fn/user-management/user-management-get-all-merchant-for-admin';
import { userManagementGetAllMerchantForAdmin$Plain } from '../fn/user-management/user-management-get-all-merchant-for-admin-plain';
import { UserManagementGetAllMerchantForAdmin$Plain$Params } from '../fn/user-management/user-management-get-all-merchant-for-admin-plain';
import { userManagementGetAllMerchantForDropDown } from '../fn/user-management/user-management-get-all-merchant-for-drop-down';
import { UserManagementGetAllMerchantForDropDown$Params } from '../fn/user-management/user-management-get-all-merchant-for-drop-down';
import { userManagementGetAllMerchantForDropDown$Plain } from '../fn/user-management/user-management-get-all-merchant-for-drop-down-plain';
import { UserManagementGetAllMerchantForDropDown$Plain$Params } from '../fn/user-management/user-management-get-all-merchant-for-drop-down-plain';
import { userManagementGetAllRoles } from '../fn/user-management/user-management-get-all-roles';
import { UserManagementGetAllRoles$Params } from '../fn/user-management/user-management-get-all-roles';
import { userManagementGetAllRoles$Plain } from '../fn/user-management/user-management-get-all-roles-plain';
import { UserManagementGetAllRoles$Plain$Params } from '../fn/user-management/user-management-get-all-roles-plain';
import { userManagementGetAllUsers } from '../fn/user-management/user-management-get-all-users';
import { UserManagementGetAllUsers$Params } from '../fn/user-management/user-management-get-all-users';
import { userManagementGetAllUsers$Plain } from '../fn/user-management/user-management-get-all-users-plain';
import { UserManagementGetAllUsers$Plain$Params } from '../fn/user-management/user-management-get-all-users-plain';
import { userManagementGetCompanyById } from '../fn/user-management/user-management-get-company-by-id';
import { UserManagementGetCompanyById$Params } from '../fn/user-management/user-management-get-company-by-id';
import { userManagementGetCompanyById$Plain } from '../fn/user-management/user-management-get-company-by-id-plain';
import { UserManagementGetCompanyById$Plain$Params } from '../fn/user-management/user-management-get-company-by-id-plain';
import { userManagementGetMenusPagesPermissionByRoleId } from '../fn/user-management/user-management-get-menus-pages-permission-by-role-id';
import { UserManagementGetMenusPagesPermissionByRoleId$Params } from '../fn/user-management/user-management-get-menus-pages-permission-by-role-id';
import { userManagementGetMenusPagesPermissionByRoleId$Plain } from '../fn/user-management/user-management-get-menus-pages-permission-by-role-id-plain';
import { UserManagementGetMenusPagesPermissionByRoleId$Plain$Params } from '../fn/user-management/user-management-get-menus-pages-permission-by-role-id-plain';
import { userManagementGetMenusPagesPermissions } from '../fn/user-management/user-management-get-menus-pages-permissions';
import { UserManagementGetMenusPagesPermissions$Params } from '../fn/user-management/user-management-get-menus-pages-permissions';
import { userManagementGetMenusPagesPermissions$Plain } from '../fn/user-management/user-management-get-menus-pages-permissions-plain';
import { UserManagementGetMenusPagesPermissions$Plain$Params } from '../fn/user-management/user-management-get-menus-pages-permissions-plain';
import { userManagementRejectMerchantCompany } from '../fn/user-management/user-management-reject-merchant-company';
import { UserManagementRejectMerchantCompany$Params } from '../fn/user-management/user-management-reject-merchant-company';
import { userManagementRejectMerchantCompany$Plain } from '../fn/user-management/user-management-reject-merchant-company-plain';
import { UserManagementRejectMerchantCompany$Plain$Params } from '../fn/user-management/user-management-reject-merchant-company-plain';
import { userManagementUpdateClient } from '../fn/user-management/user-management-update-client';
import { UserManagementUpdateClient$Params } from '../fn/user-management/user-management-update-client';
import { userManagementUpdateCompany } from '../fn/user-management/user-management-update-company';
import { UserManagementUpdateCompany$Params } from '../fn/user-management/user-management-update-company';
import { userManagementUpdateCompany$Plain } from '../fn/user-management/user-management-update-company-plain';
import { UserManagementUpdateCompany$Plain$Params } from '../fn/user-management/user-management-update-company-plain';
import { userManagementUpdateMerchantForAdmin } from '../fn/user-management/user-management-update-merchant-for-admin';
import { UserManagementUpdateMerchantForAdmin$Params } from '../fn/user-management/user-management-update-merchant-for-admin';
import { userManagementUpdateMerchantForAdmin$Plain } from '../fn/user-management/user-management-update-merchant-for-admin-plain';
import { UserManagementUpdateMerchantForAdmin$Plain$Params } from '../fn/user-management/user-management-update-merchant-for-admin-plain';
import { userManagementUpdatePassword } from '../fn/user-management/user-management-update-password';
import { UserManagementUpdatePassword$Params } from '../fn/user-management/user-management-update-password';
import { userManagementUpdatePassword$Plain } from '../fn/user-management/user-management-update-password-plain';
import { UserManagementUpdatePassword$Plain$Params } from '../fn/user-management/user-management-update-password-plain';
import { userManagementUpdateRole } from '../fn/user-management/user-management-update-role';
import { UserManagementUpdateRole$Params } from '../fn/user-management/user-management-update-role';
import { userManagementUpdateRoleWithPermissions } from '../fn/user-management/user-management-update-role-with-permissions';
import { UserManagementUpdateRoleWithPermissions$Params } from '../fn/user-management/user-management-update-role-with-permissions';
import { userManagementUpdateUser } from '../fn/user-management/user-management-update-user';
import { UserManagementUpdateUser$Params } from '../fn/user-management/user-management-update-user';

@Injectable({ providedIn: 'root' })
export class UserManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userManagementUpdatePassword()` */
  static readonly UserManagementUpdatePasswordPath = '/api/admin/UserManagement/UpdatePassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdatePassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdatePassword$Plain$Response(params?: UserManagementUpdatePassword$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementUpdatePassword$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdatePassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdatePassword$Plain(params?: UserManagementUpdatePassword$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementUpdatePassword$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdatePassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdatePassword$Response(params?: UserManagementUpdatePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementUpdatePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdatePassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdatePassword(params?: UserManagementUpdatePassword$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementUpdatePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userManagementCreateCompany()` */
  static readonly UserManagementCreateCompanyPath = '/api/admin/UserManagement/CreateCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementCreateCompany$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateCompany$Plain$Response(params?: UserManagementCreateCompany$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementCreateCompany$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementCreateCompany$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateCompany$Plain(params?: UserManagementCreateCompany$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementCreateCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementCreateCompany()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateCompany$Response(params?: UserManagementCreateCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementCreateCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementCreateCompany$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateCompany(params?: UserManagementCreateCompany$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementCreateCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userManagementDeleteCompany()` */
  static readonly UserManagementDeleteCompanyPath = '/api/admin/UserManagement/DeleteCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementDeleteCompany$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementDeleteCompany$Plain$Response(params?: UserManagementDeleteCompany$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementDeleteCompany$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementDeleteCompany$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementDeleteCompany$Plain(params?: UserManagementDeleteCompany$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementDeleteCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementDeleteCompany()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementDeleteCompany$Response(params?: UserManagementDeleteCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementDeleteCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementDeleteCompany$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementDeleteCompany(params?: UserManagementDeleteCompany$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementDeleteCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userManagementUpdateCompany()` */
  static readonly UserManagementUpdateCompanyPath = '/api/admin/UserManagement/UpdateCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdateCompany$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateCompany$Plain$Response(params?: UserManagementUpdateCompany$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementUpdateCompany$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdateCompany$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateCompany$Plain(params?: UserManagementUpdateCompany$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementUpdateCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdateCompany()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateCompany$Response(params?: UserManagementUpdateCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementUpdateCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdateCompany$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateCompany(params?: UserManagementUpdateCompany$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementUpdateCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userManagementGetAllCompany()` */
  static readonly UserManagementGetAllCompanyPath = '/api/admin/UserManagement/GetAllCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllCompany$Plain$Response(params?: UserManagementGetAllCompany$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllCompanysDto>>> {
    return userManagementGetAllCompany$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllCompany$Plain(params?: UserManagementGetAllCompany$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllCompanysDto>> {
    return this.userManagementGetAllCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCompanysDto>>): Array<OutputGetAllCompanysDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllCompany$Response(params?: UserManagementGetAllCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllCompanysDto>>> {
    return userManagementGetAllCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllCompany(params?: UserManagementGetAllCompany$Params, context?: HttpContext): Observable<Array<OutputGetAllCompanysDto>> {
    return this.userManagementGetAllCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCompanysDto>>): Array<OutputGetAllCompanysDto> => r.body)
    );
  }

  /** Path part for operation `userManagementGetCompanyById()` */
  static readonly UserManagementGetCompanyByIdPath = '/api/admin/UserManagement/GetCompanyById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetCompanyById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetCompanyById$Plain$Response(params?: UserManagementGetCompanyById$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetAllCompanysDto>> {
    return userManagementGetCompanyById$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetCompanyById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetCompanyById$Plain(params?: UserManagementGetCompanyById$Plain$Params, context?: HttpContext): Observable<OutputGetAllCompanysDto> {
    return this.userManagementGetCompanyById$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetAllCompanysDto>): OutputGetAllCompanysDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetCompanyById()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetCompanyById$Response(params?: UserManagementGetCompanyById$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetAllCompanysDto>> {
    return userManagementGetCompanyById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetCompanyById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetCompanyById(params?: UserManagementGetCompanyById$Params, context?: HttpContext): Observable<OutputGetAllCompanysDto> {
    return this.userManagementGetCompanyById$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetAllCompanysDto>): OutputGetAllCompanysDto => r.body)
    );
  }

  /** Path part for operation `userManagementGetAllCompanyRequests()` */
  static readonly UserManagementGetAllCompanyRequestsPath = '/api/admin/UserManagement/GetAllCompanyRequests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllCompanyRequests$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllCompanyRequests$Plain$Response(params?: UserManagementGetAllCompanyRequests$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllCompanysDto>>> {
    return userManagementGetAllCompanyRequests$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllCompanyRequests$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllCompanyRequests$Plain(params?: UserManagementGetAllCompanyRequests$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllCompanysDto>> {
    return this.userManagementGetAllCompanyRequests$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCompanysDto>>): Array<OutputGetAllCompanysDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllCompanyRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllCompanyRequests$Response(params?: UserManagementGetAllCompanyRequests$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllCompanysDto>>> {
    return userManagementGetAllCompanyRequests(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllCompanyRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllCompanyRequests(params?: UserManagementGetAllCompanyRequests$Params, context?: HttpContext): Observable<Array<OutputGetAllCompanysDto>> {
    return this.userManagementGetAllCompanyRequests$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCompanysDto>>): Array<OutputGetAllCompanysDto> => r.body)
    );
  }

  /** Path part for operation `userManagementGetAllRoles()` */
  static readonly UserManagementGetAllRolesPath = '/api/admin/UserManagement/GetAllRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllRoles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllRoles$Plain$Response(params?: UserManagementGetAllRoles$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllRolesDto>>> {
    return userManagementGetAllRoles$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllRoles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllRoles$Plain(params?: UserManagementGetAllRoles$Plain$Params, context?: HttpContext): Observable<Array<OutputAllRolesDto>> {
    return this.userManagementGetAllRoles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllRolesDto>>): Array<OutputAllRolesDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllRoles$Response(params?: UserManagementGetAllRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllRolesDto>>> {
    return userManagementGetAllRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllRoles(params?: UserManagementGetAllRoles$Params, context?: HttpContext): Observable<Array<OutputAllRolesDto>> {
    return this.userManagementGetAllRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllRolesDto>>): Array<OutputAllRolesDto> => r.body)
    );
  }

  /** Path part for operation `userManagementCreateRole()` */
  static readonly UserManagementCreateRolePath = '/api/admin/UserManagement/CreateRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementCreateRole()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateRole$Response(params?: UserManagementCreateRole$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userManagementCreateRole(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementCreateRole$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateRole(params?: UserManagementCreateRole$Params, context?: HttpContext): Observable<void> {
    return this.userManagementCreateRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userManagementDeleteRole()` */
  static readonly UserManagementDeleteRolePath = '/api/admin/UserManagement/DeleteRole';

  /**
   * DeleteRole.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementDeleteRole$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteRole$Plain$Response(params?: UserManagementDeleteRole$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementDeleteRole$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * DeleteRole.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementDeleteRole$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteRole$Plain(params?: UserManagementDeleteRole$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementDeleteRole$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * DeleteRole.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementDeleteRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteRole$Response(params?: UserManagementDeleteRole$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementDeleteRole(this.http, this.rootUrl, params, context);
  }

  /**
   * DeleteRole.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementDeleteRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteRole(params?: UserManagementDeleteRole$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementDeleteRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userManagementUpdateRole()` */
  static readonly UserManagementUpdateRolePath = '/api/admin/UserManagement/UpdateRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdateRole()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateRole$Response(params?: UserManagementUpdateRole$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userManagementUpdateRole(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdateRole$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateRole(params?: UserManagementUpdateRole$Params, context?: HttpContext): Observable<void> {
    return this.userManagementUpdateRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userManagementUpdateRoleWithPermissions()` */
  static readonly UserManagementUpdateRoleWithPermissionsPath = '/api/admin/UserManagement/UpdateRoleWithPermissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdateRoleWithPermissions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateRoleWithPermissions$Response(params?: UserManagementUpdateRoleWithPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userManagementUpdateRoleWithPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdateRoleWithPermissions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateRoleWithPermissions(params?: UserManagementUpdateRoleWithPermissions$Params, context?: HttpContext): Observable<void> {
    return this.userManagementUpdateRoleWithPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userManagementGetAllClients()` */
  static readonly UserManagementGetAllClientsPath = '/api/admin/UserManagement/GetAllClients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllClients$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllClients$Plain$Response(params?: UserManagementGetAllClients$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllClientsDto>>> {
    return userManagementGetAllClients$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllClients$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllClients$Plain(params?: UserManagementGetAllClients$Plain$Params, context?: HttpContext): Observable<Array<OutputAllClientsDto>> {
    return this.userManagementGetAllClients$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllClientsDto>>): Array<OutputAllClientsDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllClients()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllClients$Response(params?: UserManagementGetAllClients$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllClientsDto>>> {
    return userManagementGetAllClients(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllClients$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllClients(params?: UserManagementGetAllClients$Params, context?: HttpContext): Observable<Array<OutputAllClientsDto>> {
    return this.userManagementGetAllClients$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllClientsDto>>): Array<OutputAllClientsDto> => r.body)
    );
  }

  /** Path part for operation `userManagementCreateClient()` */
  static readonly UserManagementCreateClientPath = '/api/admin/UserManagement/CreateClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementCreateClient$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateClient$Plain$Response(params?: UserManagementCreateClient$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementCreateClient$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementCreateClient$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateClient$Plain(params?: UserManagementCreateClient$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementCreateClient$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementCreateClient()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateClient$Response(params?: UserManagementCreateClient$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementCreateClient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementCreateClient$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateClient(params?: UserManagementCreateClient$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementCreateClient$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userManagementDeleteClient()` */
  static readonly UserManagementDeleteClientPath = '/api/admin/UserManagement/DeleteClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementDeleteClient()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteClient$Response(params?: UserManagementDeleteClient$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userManagementDeleteClient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementDeleteClient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteClient(params?: UserManagementDeleteClient$Params, context?: HttpContext): Observable<void> {
    return this.userManagementDeleteClient$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userManagementUpdateClient()` */
  static readonly UserManagementUpdateClientPath = '/api/admin/UserManagement/UpdateClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdateClient()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateClient$Response(params?: UserManagementUpdateClient$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userManagementUpdateClient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdateClient$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateClient(params?: UserManagementUpdateClient$Params, context?: HttpContext): Observable<void> {
    return this.userManagementUpdateClient$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userManagementGetAllUsers()` */
  static readonly UserManagementGetAllUsersPath = '/api/admin/UserManagement/GetAllUsers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllUsers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllUsers$Plain$Response(params?: UserManagementGetAllUsers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllUsersDto>>> {
    return userManagementGetAllUsers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllUsers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllUsers$Plain(params?: UserManagementGetAllUsers$Plain$Params, context?: HttpContext): Observable<Array<OutputAllUsersDto>> {
    return this.userManagementGetAllUsers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllUsersDto>>): Array<OutputAllUsersDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllUsers$Response(params?: UserManagementGetAllUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllUsersDto>>> {
    return userManagementGetAllUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllUsers(params?: UserManagementGetAllUsers$Params, context?: HttpContext): Observable<Array<OutputAllUsersDto>> {
    return this.userManagementGetAllUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllUsersDto>>): Array<OutputAllUsersDto> => r.body)
    );
  }

  /** Path part for operation `userManagementCreateUser()` */
  static readonly UserManagementCreateUserPath = '/api/admin/UserManagement/CreateUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementCreateUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateUser$Response(params?: UserManagementCreateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userManagementCreateUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementCreateUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateUser(params?: UserManagementCreateUser$Params, context?: HttpContext): Observable<void> {
    return this.userManagementCreateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userManagementDeleteUser()` */
  static readonly UserManagementDeleteUserPath = '/api/admin/UserManagement/DeleteUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementDeleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteUser$Response(params?: UserManagementDeleteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userManagementDeleteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementDeleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteUser(params?: UserManagementDeleteUser$Params, context?: HttpContext): Observable<void> {
    return this.userManagementDeleteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userManagementUpdateUser()` */
  static readonly UserManagementUpdateUserPath = '/api/admin/UserManagement/UpdateUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdateUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateUser$Response(params?: UserManagementUpdateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userManagementUpdateUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdateUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateUser(params?: UserManagementUpdateUser$Params, context?: HttpContext): Observable<void> {
    return this.userManagementUpdateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userManagementGetMenusPagesPermissionByRoleId()` */
  static readonly UserManagementGetMenusPagesPermissionByRoleIdPath = '/api/admin/UserManagement/GetMenusPagesPermissionByRoleId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetMenusPagesPermissionByRoleId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetMenusPagesPermissionByRoleId$Plain$Response(params?: UserManagementGetMenusPagesPermissionByRoleId$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>> {
    return userManagementGetMenusPagesPermissionByRoleId$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetMenusPagesPermissionByRoleId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetMenusPagesPermissionByRoleId$Plain(params?: UserManagementGetMenusPagesPermissionByRoleId$Plain$Params, context?: HttpContext): Observable<Array<OutputAllMenuPagesPermissionDto>> {
    return this.userManagementGetMenusPagesPermissionByRoleId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>): Array<OutputAllMenuPagesPermissionDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetMenusPagesPermissionByRoleId()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetMenusPagesPermissionByRoleId$Response(params?: UserManagementGetMenusPagesPermissionByRoleId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>> {
    return userManagementGetMenusPagesPermissionByRoleId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetMenusPagesPermissionByRoleId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetMenusPagesPermissionByRoleId(params?: UserManagementGetMenusPagesPermissionByRoleId$Params, context?: HttpContext): Observable<Array<OutputAllMenuPagesPermissionDto>> {
    return this.userManagementGetMenusPagesPermissionByRoleId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>): Array<OutputAllMenuPagesPermissionDto> => r.body)
    );
  }

  /** Path part for operation `userManagementGetMenusPagesPermissions()` */
  static readonly UserManagementGetMenusPagesPermissionsPath = '/api/admin/UserManagement/GetMenusPagesPermissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetMenusPagesPermissions$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetMenusPagesPermissions$Plain$Response(params?: UserManagementGetMenusPagesPermissions$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>> {
    return userManagementGetMenusPagesPermissions$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetMenusPagesPermissions$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetMenusPagesPermissions$Plain(params?: UserManagementGetMenusPagesPermissions$Plain$Params, context?: HttpContext): Observable<Array<OutputAllMenuPagesPermissionDto>> {
    return this.userManagementGetMenusPagesPermissions$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>): Array<OutputAllMenuPagesPermissionDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetMenusPagesPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetMenusPagesPermissions$Response(params?: UserManagementGetMenusPagesPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>> {
    return userManagementGetMenusPagesPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetMenusPagesPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetMenusPagesPermissions(params?: UserManagementGetMenusPagesPermissions$Params, context?: HttpContext): Observable<Array<OutputAllMenuPagesPermissionDto>> {
    return this.userManagementGetMenusPagesPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>): Array<OutputAllMenuPagesPermissionDto> => r.body)
    );
  }

  /** Path part for operation `userManagementCreateMerchantForAdmin()` */
  static readonly UserManagementCreateMerchantForAdminPath = '/api/admin/UserManagement/CreateMerchantForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementCreateMerchantForAdmin$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateMerchantForAdmin$Plain$Response(params?: UserManagementCreateMerchantForAdmin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementCreateMerchantForAdmin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementCreateMerchantForAdmin$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateMerchantForAdmin$Plain(params?: UserManagementCreateMerchantForAdmin$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementCreateMerchantForAdmin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementCreateMerchantForAdmin()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateMerchantForAdmin$Response(params?: UserManagementCreateMerchantForAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementCreateMerchantForAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementCreateMerchantForAdmin$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateMerchantForAdmin(params?: UserManagementCreateMerchantForAdmin$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementCreateMerchantForAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userManagementDeleteMerchantForAdmin()` */
  static readonly UserManagementDeleteMerchantForAdminPath = '/api/admin/UserManagement/DeleteMerchantForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementDeleteMerchantForAdmin$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteMerchantForAdmin$Plain$Response(params?: UserManagementDeleteMerchantForAdmin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementDeleteMerchantForAdmin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementDeleteMerchantForAdmin$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteMerchantForAdmin$Plain(params?: UserManagementDeleteMerchantForAdmin$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementDeleteMerchantForAdmin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementDeleteMerchantForAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteMerchantForAdmin$Response(params?: UserManagementDeleteMerchantForAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementDeleteMerchantForAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementDeleteMerchantForAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteMerchantForAdmin(params?: UserManagementDeleteMerchantForAdmin$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementDeleteMerchantForAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userManagementUpdateMerchantForAdmin()` */
  static readonly UserManagementUpdateMerchantForAdminPath = '/api/admin/UserManagement/UpdateMerchantForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdateMerchantForAdmin$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateMerchantForAdmin$Plain$Response(params?: UserManagementUpdateMerchantForAdmin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementUpdateMerchantForAdmin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdateMerchantForAdmin$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateMerchantForAdmin$Plain(params?: UserManagementUpdateMerchantForAdmin$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementUpdateMerchantForAdmin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdateMerchantForAdmin()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateMerchantForAdmin$Response(params?: UserManagementUpdateMerchantForAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementUpdateMerchantForAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdateMerchantForAdmin$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateMerchantForAdmin(params?: UserManagementUpdateMerchantForAdmin$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementUpdateMerchantForAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userManagementGetAllMerchantForAdmin()` */
  static readonly UserManagementGetAllMerchantForAdminPath = '/api/admin/UserManagement/GetAllMerchantForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllMerchantForAdmin$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllMerchantForAdmin$Plain$Response(params?: UserManagementGetAllMerchantForAdmin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllMerchantForAdmin>>> {
    return userManagementGetAllMerchantForAdmin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllMerchantForAdmin$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllMerchantForAdmin$Plain(params?: UserManagementGetAllMerchantForAdmin$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllMerchantForAdmin>> {
    return this.userManagementGetAllMerchantForAdmin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMerchantForAdmin>>): Array<OutputGetAllMerchantForAdmin> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllMerchantForAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllMerchantForAdmin$Response(params?: UserManagementGetAllMerchantForAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllMerchantForAdmin>>> {
    return userManagementGetAllMerchantForAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllMerchantForAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllMerchantForAdmin(params?: UserManagementGetAllMerchantForAdmin$Params, context?: HttpContext): Observable<Array<OutputGetAllMerchantForAdmin>> {
    return this.userManagementGetAllMerchantForAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMerchantForAdmin>>): Array<OutputGetAllMerchantForAdmin> => r.body)
    );
  }

  /** Path part for operation `userManagementGetAllMerchantForDropDown()` */
  static readonly UserManagementGetAllMerchantForDropDownPath = '/api/admin/UserManagement/GetAllMerchantForDropDown';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllMerchantForDropDown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllMerchantForDropDown$Plain$Response(params?: UserManagementGetAllMerchantForDropDown$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllMerchantDto>>> {
    return userManagementGetAllMerchantForDropDown$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllMerchantForDropDown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllMerchantForDropDown$Plain(params?: UserManagementGetAllMerchantForDropDown$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllMerchantDto>> {
    return this.userManagementGetAllMerchantForDropDown$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMerchantDto>>): Array<OutputGetAllMerchantDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllMerchantForDropDown()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllMerchantForDropDown$Response(params?: UserManagementGetAllMerchantForDropDown$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllMerchantDto>>> {
    return userManagementGetAllMerchantForDropDown(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllMerchantForDropDown$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllMerchantForDropDown(params?: UserManagementGetAllMerchantForDropDown$Params, context?: HttpContext): Observable<Array<OutputGetAllMerchantDto>> {
    return this.userManagementGetAllMerchantForDropDown$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMerchantDto>>): Array<OutputGetAllMerchantDto> => r.body)
    );
  }

  /** Path part for operation `userManagementAcceptMerchantCompany()` */
  static readonly UserManagementAcceptMerchantCompanyPath = '/api/admin/UserManagement/AcceptMerchantCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementAcceptMerchantCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementAcceptMerchantCompany$Plain$Response(params?: UserManagementAcceptMerchantCompany$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementAcceptMerchantCompany$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementAcceptMerchantCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementAcceptMerchantCompany$Plain(params?: UserManagementAcceptMerchantCompany$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementAcceptMerchantCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementAcceptMerchantCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementAcceptMerchantCompany$Response(params?: UserManagementAcceptMerchantCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementAcceptMerchantCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementAcceptMerchantCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementAcceptMerchantCompany(params?: UserManagementAcceptMerchantCompany$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementAcceptMerchantCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userManagementRejectMerchantCompany()` */
  static readonly UserManagementRejectMerchantCompanyPath = '/api/admin/UserManagement/RejectMerchantCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementRejectMerchantCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementRejectMerchantCompany$Plain$Response(params?: UserManagementRejectMerchantCompany$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementRejectMerchantCompany$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementRejectMerchantCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementRejectMerchantCompany$Plain(params?: UserManagementRejectMerchantCompany$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementRejectMerchantCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementRejectMerchantCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementRejectMerchantCompany$Response(params?: UserManagementRejectMerchantCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userManagementRejectMerchantCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userManagementRejectMerchantCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementRejectMerchantCompany(params?: UserManagementRejectMerchantCompany$Params, context?: HttpContext): Observable<boolean> {
    return this.userManagementRejectMerchantCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
