/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { itemManagementCreateBrand } from '../fn/item-management/item-management-create-brand';
import { ItemManagementCreateBrand$Params } from '../fn/item-management/item-management-create-brand';
import { itemManagementCreateBrand$Plain } from '../fn/item-management/item-management-create-brand-plain';
import { ItemManagementCreateBrand$Plain$Params } from '../fn/item-management/item-management-create-brand-plain';
import { itemManagementCreateCategory } from '../fn/item-management/item-management-create-category';
import { ItemManagementCreateCategory$Params } from '../fn/item-management/item-management-create-category';
import { itemManagementCreateCategory$Plain } from '../fn/item-management/item-management-create-category-plain';
import { ItemManagementCreateCategory$Plain$Params } from '../fn/item-management/item-management-create-category-plain';
import { itemManagementCreateColor } from '../fn/item-management/item-management-create-color';
import { ItemManagementCreateColor$Params } from '../fn/item-management/item-management-create-color';
import { itemManagementCreateColor$Plain } from '../fn/item-management/item-management-create-color-plain';
import { ItemManagementCreateColor$Plain$Params } from '../fn/item-management/item-management-create-color-plain';
import { itemManagementCreateColorCategory } from '../fn/item-management/item-management-create-color-category';
import { ItemManagementCreateColorCategory$Params } from '../fn/item-management/item-management-create-color-category';
import { itemManagementCreateColorCategory$Plain } from '../fn/item-management/item-management-create-color-category-plain';
import { ItemManagementCreateColorCategory$Plain$Params } from '../fn/item-management/item-management-create-color-category-plain';
import { itemManagementCreateFilterFeature } from '../fn/item-management/item-management-create-filter-feature';
import { ItemManagementCreateFilterFeature$Params } from '../fn/item-management/item-management-create-filter-feature';
import { itemManagementCreateFilterFeature$Plain } from '../fn/item-management/item-management-create-filter-feature-plain';
import { ItemManagementCreateFilterFeature$Plain$Params } from '../fn/item-management/item-management-create-filter-feature-plain';
import { itemManagementCreateFilterFeatureContent } from '../fn/item-management/item-management-create-filter-feature-content';
import { ItemManagementCreateFilterFeatureContent$Params } from '../fn/item-management/item-management-create-filter-feature-content';
import { itemManagementCreateFilterFeatureContent$Plain } from '../fn/item-management/item-management-create-filter-feature-content-plain';
import { ItemManagementCreateFilterFeatureContent$Plain$Params } from '../fn/item-management/item-management-create-filter-feature-content-plain';
import { itemManagementCreateGenderLink } from '../fn/item-management/item-management-create-gender-link';
import { ItemManagementCreateGenderLink$Params } from '../fn/item-management/item-management-create-gender-link';
import { itemManagementCreateGenderLink$Plain } from '../fn/item-management/item-management-create-gender-link-plain';
import { ItemManagementCreateGenderLink$Plain$Params } from '../fn/item-management/item-management-create-gender-link-plain';
import { itemManagementCreateItem } from '../fn/item-management/item-management-create-item';
import { ItemManagementCreateItem$Params } from '../fn/item-management/item-management-create-item';
import { itemManagementCreateItem$Plain } from '../fn/item-management/item-management-create-item-plain';
import { ItemManagementCreateItem$Plain$Params } from '../fn/item-management/item-management-create-item-plain';
import { itemManagementCreateMaterial } from '../fn/item-management/item-management-create-material';
import { ItemManagementCreateMaterial$Params } from '../fn/item-management/item-management-create-material';
import { itemManagementCreateMaterial$Plain } from '../fn/item-management/item-management-create-material-plain';
import { ItemManagementCreateMaterial$Plain$Params } from '../fn/item-management/item-management-create-material-plain';
import { itemManagementCreateSeason } from '../fn/item-management/item-management-create-season';
import { ItemManagementCreateSeason$Params } from '../fn/item-management/item-management-create-season';
import { itemManagementCreateSeason$Plain } from '../fn/item-management/item-management-create-season-plain';
import { ItemManagementCreateSeason$Plain$Params } from '../fn/item-management/item-management-create-season-plain';
import { itemManagementCreateSize } from '../fn/item-management/item-management-create-size';
import { ItemManagementCreateSize$Params } from '../fn/item-management/item-management-create-size';
import { itemManagementCreateSize$Plain } from '../fn/item-management/item-management-create-size-plain';
import { ItemManagementCreateSize$Plain$Params } from '../fn/item-management/item-management-create-size-plain';
import { itemManagementCreateSizeCategory } from '../fn/item-management/item-management-create-size-category';
import { ItemManagementCreateSizeCategory$Params } from '../fn/item-management/item-management-create-size-category';
import { itemManagementCreateSizeCategory$Plain } from '../fn/item-management/item-management-create-size-category-plain';
import { ItemManagementCreateSizeCategory$Plain$Params } from '../fn/item-management/item-management-create-size-category-plain';
import { itemManagementCreateSizeName } from '../fn/item-management/item-management-create-size-name';
import { ItemManagementCreateSizeName$Params } from '../fn/item-management/item-management-create-size-name';
import { itemManagementCreateSizeName$Plain } from '../fn/item-management/item-management-create-size-name-plain';
import { ItemManagementCreateSizeName$Plain$Params } from '../fn/item-management/item-management-create-size-name-plain';
import { itemManagementCreateStyle } from '../fn/item-management/item-management-create-style';
import { ItemManagementCreateStyle$Params } from '../fn/item-management/item-management-create-style';
import { itemManagementCreateStyle$Plain } from '../fn/item-management/item-management-create-style-plain';
import { ItemManagementCreateStyle$Plain$Params } from '../fn/item-management/item-management-create-style-plain';
import { itemManagementCreateSubCategory } from '../fn/item-management/item-management-create-sub-category';
import { ItemManagementCreateSubCategory$Params } from '../fn/item-management/item-management-create-sub-category';
import { itemManagementCreateSubCategory$Plain } from '../fn/item-management/item-management-create-sub-category-plain';
import { ItemManagementCreateSubCategory$Plain$Params } from '../fn/item-management/item-management-create-sub-category-plain';
import { itemManagementDeleteBrand } from '../fn/item-management/item-management-delete-brand';
import { ItemManagementDeleteBrand$Params } from '../fn/item-management/item-management-delete-brand';
import { itemManagementDeleteBrand$Plain } from '../fn/item-management/item-management-delete-brand-plain';
import { ItemManagementDeleteBrand$Plain$Params } from '../fn/item-management/item-management-delete-brand-plain';
import { itemManagementDeleteBulkItem } from '../fn/item-management/item-management-delete-bulk-item';
import { ItemManagementDeleteBulkItem$Params } from '../fn/item-management/item-management-delete-bulk-item';
import { itemManagementDeleteBulkItem$Plain } from '../fn/item-management/item-management-delete-bulk-item-plain';
import { ItemManagementDeleteBulkItem$Plain$Params } from '../fn/item-management/item-management-delete-bulk-item-plain';
import { itemManagementDeleteCategory } from '../fn/item-management/item-management-delete-category';
import { ItemManagementDeleteCategory$Params } from '../fn/item-management/item-management-delete-category';
import { itemManagementDeleteColor } from '../fn/item-management/item-management-delete-color';
import { ItemManagementDeleteColor$Params } from '../fn/item-management/item-management-delete-color';
import { itemManagementDeleteColor$Plain } from '../fn/item-management/item-management-delete-color-plain';
import { ItemManagementDeleteColor$Plain$Params } from '../fn/item-management/item-management-delete-color-plain';
import { itemManagementDeleteColorCategory } from '../fn/item-management/item-management-delete-color-category';
import { ItemManagementDeleteColorCategory$Params } from '../fn/item-management/item-management-delete-color-category';
import { itemManagementDeleteColorCategory$Plain } from '../fn/item-management/item-management-delete-color-category-plain';
import { ItemManagementDeleteColorCategory$Plain$Params } from '../fn/item-management/item-management-delete-color-category-plain';
import { itemManagementDeleteFilterFeature } from '../fn/item-management/item-management-delete-filter-feature';
import { ItemManagementDeleteFilterFeature$Params } from '../fn/item-management/item-management-delete-filter-feature';
import { itemManagementDeleteFilterFeature$Plain } from '../fn/item-management/item-management-delete-filter-feature-plain';
import { ItemManagementDeleteFilterFeature$Plain$Params } from '../fn/item-management/item-management-delete-filter-feature-plain';
import { itemManagementDeleteFilterFeatureContent } from '../fn/item-management/item-management-delete-filter-feature-content';
import { ItemManagementDeleteFilterFeatureContent$Params } from '../fn/item-management/item-management-delete-filter-feature-content';
import { itemManagementDeleteFilterFeatureContent$Plain } from '../fn/item-management/item-management-delete-filter-feature-content-plain';
import { ItemManagementDeleteFilterFeatureContent$Plain$Params } from '../fn/item-management/item-management-delete-filter-feature-content-plain';
import { itemManagementDeleteGenderLink } from '../fn/item-management/item-management-delete-gender-link';
import { ItemManagementDeleteGenderLink$Params } from '../fn/item-management/item-management-delete-gender-link';
import { itemManagementDeleteGenderLink$Plain } from '../fn/item-management/item-management-delete-gender-link-plain';
import { ItemManagementDeleteGenderLink$Plain$Params } from '../fn/item-management/item-management-delete-gender-link-plain';
import { itemManagementDeleteItem } from '../fn/item-management/item-management-delete-item';
import { ItemManagementDeleteItem$Params } from '../fn/item-management/item-management-delete-item';
import { itemManagementDeleteItem$Plain } from '../fn/item-management/item-management-delete-item-plain';
import { ItemManagementDeleteItem$Plain$Params } from '../fn/item-management/item-management-delete-item-plain';
import { itemManagementDeleteMaterial } from '../fn/item-management/item-management-delete-material';
import { ItemManagementDeleteMaterial$Params } from '../fn/item-management/item-management-delete-material';
import { itemManagementDeleteMaterial$Plain } from '../fn/item-management/item-management-delete-material-plain';
import { ItemManagementDeleteMaterial$Plain$Params } from '../fn/item-management/item-management-delete-material-plain';
import { itemManagementDeleteSeason } from '../fn/item-management/item-management-delete-season';
import { ItemManagementDeleteSeason$Params } from '../fn/item-management/item-management-delete-season';
import { itemManagementDeleteSeason$Plain } from '../fn/item-management/item-management-delete-season-plain';
import { ItemManagementDeleteSeason$Plain$Params } from '../fn/item-management/item-management-delete-season-plain';
import { itemManagementDeleteSize } from '../fn/item-management/item-management-delete-size';
import { ItemManagementDeleteSize$Params } from '../fn/item-management/item-management-delete-size';
import { itemManagementDeleteSize$Plain } from '../fn/item-management/item-management-delete-size-plain';
import { ItemManagementDeleteSize$Plain$Params } from '../fn/item-management/item-management-delete-size-plain';
import { itemManagementDeleteSizeCategory } from '../fn/item-management/item-management-delete-size-category';
import { ItemManagementDeleteSizeCategory$Params } from '../fn/item-management/item-management-delete-size-category';
import { itemManagementDeleteSizeCategory$Plain } from '../fn/item-management/item-management-delete-size-category-plain';
import { ItemManagementDeleteSizeCategory$Plain$Params } from '../fn/item-management/item-management-delete-size-category-plain';
import { itemManagementDeleteSizeName } from '../fn/item-management/item-management-delete-size-name';
import { ItemManagementDeleteSizeName$Params } from '../fn/item-management/item-management-delete-size-name';
import { itemManagementDeleteSizeName$Plain } from '../fn/item-management/item-management-delete-size-name-plain';
import { ItemManagementDeleteSizeName$Plain$Params } from '../fn/item-management/item-management-delete-size-name-plain';
import { itemManagementDeleteStyle } from '../fn/item-management/item-management-delete-style';
import { ItemManagementDeleteStyle$Params } from '../fn/item-management/item-management-delete-style';
import { itemManagementDeleteStyle$Plain } from '../fn/item-management/item-management-delete-style-plain';
import { ItemManagementDeleteStyle$Plain$Params } from '../fn/item-management/item-management-delete-style-plain';
import { itemManagementDeleteSubCategory } from '../fn/item-management/item-management-delete-sub-category';
import { ItemManagementDeleteSubCategory$Params } from '../fn/item-management/item-management-delete-sub-category';
import { itemManagementGetAllBrand } from '../fn/item-management/item-management-get-all-brand';
import { ItemManagementGetAllBrand$Params } from '../fn/item-management/item-management-get-all-brand';
import { itemManagementGetAllBrand$Plain } from '../fn/item-management/item-management-get-all-brand-plain';
import { ItemManagementGetAllBrand$Plain$Params } from '../fn/item-management/item-management-get-all-brand-plain';
import { itemManagementGetAllCategory } from '../fn/item-management/item-management-get-all-category';
import { ItemManagementGetAllCategory$Params } from '../fn/item-management/item-management-get-all-category';
import { itemManagementGetAllCategory$Plain } from '../fn/item-management/item-management-get-all-category-plain';
import { ItemManagementGetAllCategory$Plain$Params } from '../fn/item-management/item-management-get-all-category-plain';
import { itemManagementGetAllColor } from '../fn/item-management/item-management-get-all-color';
import { ItemManagementGetAllColor$Params } from '../fn/item-management/item-management-get-all-color';
import { itemManagementGetAllColor$Plain } from '../fn/item-management/item-management-get-all-color-plain';
import { ItemManagementGetAllColor$Plain$Params } from '../fn/item-management/item-management-get-all-color-plain';
import { itemManagementGetAllColorCategory } from '../fn/item-management/item-management-get-all-color-category';
import { ItemManagementGetAllColorCategory$Params } from '../fn/item-management/item-management-get-all-color-category';
import { itemManagementGetAllColorCategory$Plain } from '../fn/item-management/item-management-get-all-color-category-plain';
import { ItemManagementGetAllColorCategory$Plain$Params } from '../fn/item-management/item-management-get-all-color-category-plain';
import { itemManagementGetAllDataRequestsForAdmin } from '../fn/item-management/item-management-get-all-data-requests-for-admin';
import { ItemManagementGetAllDataRequestsForAdmin$Params } from '../fn/item-management/item-management-get-all-data-requests-for-admin';
import { itemManagementGetAllDataRequestsForAdmin$Plain } from '../fn/item-management/item-management-get-all-data-requests-for-admin-plain';
import { ItemManagementGetAllDataRequestsForAdmin$Plain$Params } from '../fn/item-management/item-management-get-all-data-requests-for-admin-plain';
import { itemManagementGetAllFeatureWithContent } from '../fn/item-management/item-management-get-all-feature-with-content';
import { ItemManagementGetAllFeatureWithContent$Params } from '../fn/item-management/item-management-get-all-feature-with-content';
import { itemManagementGetAllFeatureWithContent$Plain } from '../fn/item-management/item-management-get-all-feature-with-content-plain';
import { ItemManagementGetAllFeatureWithContent$Plain$Params } from '../fn/item-management/item-management-get-all-feature-with-content-plain';
import { itemManagementGetAllFilterFeature } from '../fn/item-management/item-management-get-all-filter-feature';
import { ItemManagementGetAllFilterFeature$Params } from '../fn/item-management/item-management-get-all-filter-feature';
import { itemManagementGetAllFilterFeature$Plain } from '../fn/item-management/item-management-get-all-filter-feature-plain';
import { ItemManagementGetAllFilterFeature$Plain$Params } from '../fn/item-management/item-management-get-all-filter-feature-plain';
import { itemManagementGetAllFilterFeatureContent } from '../fn/item-management/item-management-get-all-filter-feature-content';
import { ItemManagementGetAllFilterFeatureContent$Params } from '../fn/item-management/item-management-get-all-filter-feature-content';
import { itemManagementGetAllFilterFeatureContent$Plain } from '../fn/item-management/item-management-get-all-filter-feature-content-plain';
import { ItemManagementGetAllFilterFeatureContent$Plain$Params } from '../fn/item-management/item-management-get-all-filter-feature-content-plain';
import { itemManagementGetAllGenderLink } from '../fn/item-management/item-management-get-all-gender-link';
import { ItemManagementGetAllGenderLink$Params } from '../fn/item-management/item-management-get-all-gender-link';
import { itemManagementGetAllGenderLink$Plain } from '../fn/item-management/item-management-get-all-gender-link-plain';
import { ItemManagementGetAllGenderLink$Plain$Params } from '../fn/item-management/item-management-get-all-gender-link-plain';
import { itemManagementGetAllItemsForDropDown } from '../fn/item-management/item-management-get-all-items-for-drop-down';
import { ItemManagementGetAllItemsForDropDown$Params } from '../fn/item-management/item-management-get-all-items-for-drop-down';
import { itemManagementGetAllItemsForDropDown$Plain } from '../fn/item-management/item-management-get-all-items-for-drop-down-plain';
import { ItemManagementGetAllItemsForDropDown$Plain$Params } from '../fn/item-management/item-management-get-all-items-for-drop-down-plain';
import { itemManagementGetAllItemsSearchForDropDownWithImage } from '../fn/item-management/item-management-get-all-items-search-for-drop-down-with-image';
import { ItemManagementGetAllItemsSearchForDropDownWithImage$Params } from '../fn/item-management/item-management-get-all-items-search-for-drop-down-with-image';
import { itemManagementGetAllItemsSearchForDropDownWithImage$Plain } from '../fn/item-management/item-management-get-all-items-search-for-drop-down-with-image-plain';
import { ItemManagementGetAllItemsSearchForDropDownWithImage$Plain$Params } from '../fn/item-management/item-management-get-all-items-search-for-drop-down-with-image-plain';
import { itemManagementGetAllMaterial } from '../fn/item-management/item-management-get-all-material';
import { ItemManagementGetAllMaterial$Params } from '../fn/item-management/item-management-get-all-material';
import { itemManagementGetAllMaterial$Plain } from '../fn/item-management/item-management-get-all-material-plain';
import { ItemManagementGetAllMaterial$Plain$Params } from '../fn/item-management/item-management-get-all-material-plain';
import { itemManagementGetAllPagedItemsForAdmin } from '../fn/item-management/item-management-get-all-paged-items-for-admin';
import { ItemManagementGetAllPagedItemsForAdmin$Params } from '../fn/item-management/item-management-get-all-paged-items-for-admin';
import { itemManagementGetAllPagedItemsForAdmin$Plain } from '../fn/item-management/item-management-get-all-paged-items-for-admin-plain';
import { ItemManagementGetAllPagedItemsForAdmin$Plain$Params } from '../fn/item-management/item-management-get-all-paged-items-for-admin-plain';
import { itemManagementGetAllSeason } from '../fn/item-management/item-management-get-all-season';
import { ItemManagementGetAllSeason$Params } from '../fn/item-management/item-management-get-all-season';
import { itemManagementGetAllSeason$Plain } from '../fn/item-management/item-management-get-all-season-plain';
import { ItemManagementGetAllSeason$Plain$Params } from '../fn/item-management/item-management-get-all-season-plain';
import { itemManagementGetAllSize } from '../fn/item-management/item-management-get-all-size';
import { ItemManagementGetAllSize$Params } from '../fn/item-management/item-management-get-all-size';
import { itemManagementGetAllSize$Plain } from '../fn/item-management/item-management-get-all-size-plain';
import { ItemManagementGetAllSize$Plain$Params } from '../fn/item-management/item-management-get-all-size-plain';
import { itemManagementGetAllSizeCategory } from '../fn/item-management/item-management-get-all-size-category';
import { ItemManagementGetAllSizeCategory$Params } from '../fn/item-management/item-management-get-all-size-category';
import { itemManagementGetAllSizeCategory$Plain } from '../fn/item-management/item-management-get-all-size-category-plain';
import { ItemManagementGetAllSizeCategory$Plain$Params } from '../fn/item-management/item-management-get-all-size-category-plain';
import { itemManagementGetAllSizeName } from '../fn/item-management/item-management-get-all-size-name';
import { ItemManagementGetAllSizeName$Params } from '../fn/item-management/item-management-get-all-size-name';
import { itemManagementGetAllSizeName$Plain } from '../fn/item-management/item-management-get-all-size-name-plain';
import { ItemManagementGetAllSizeName$Plain$Params } from '../fn/item-management/item-management-get-all-size-name-plain';
import { itemManagementGetAllStyles } from '../fn/item-management/item-management-get-all-styles';
import { ItemManagementGetAllStyles$Params } from '../fn/item-management/item-management-get-all-styles';
import { itemManagementGetAllStyles$Plain } from '../fn/item-management/item-management-get-all-styles-plain';
import { ItemManagementGetAllStyles$Plain$Params } from '../fn/item-management/item-management-get-all-styles-plain';
import { itemManagementGetAllSubCategory } from '../fn/item-management/item-management-get-all-sub-category';
import { ItemManagementGetAllSubCategory$Params } from '../fn/item-management/item-management-get-all-sub-category';
import { itemManagementGetAllSubCategory$Plain } from '../fn/item-management/item-management-get-all-sub-category-plain';
import { ItemManagementGetAllSubCategory$Plain$Params } from '../fn/item-management/item-management-get-all-sub-category-plain';
import { itemManagementGetAllSubCategoryByCategoryId } from '../fn/item-management/item-management-get-all-sub-category-by-category-id';
import { ItemManagementGetAllSubCategoryByCategoryId$Params } from '../fn/item-management/item-management-get-all-sub-category-by-category-id';
import { itemManagementGetAllSubCategoryByCategoryId$Plain } from '../fn/item-management/item-management-get-all-sub-category-by-category-id-plain';
import { ItemManagementGetAllSubCategoryByCategoryId$Plain$Params } from '../fn/item-management/item-management-get-all-sub-category-by-category-id-plain';
import { itemManagementGetSubCategoryByGenderForDrowpdown } from '../fn/item-management/item-management-get-sub-category-by-gender-for-drowpdown';
import { ItemManagementGetSubCategoryByGenderForDrowpdown$Params } from '../fn/item-management/item-management-get-sub-category-by-gender-for-drowpdown';
import { itemManagementGetSubCategoryByGenderForDrowpdown$Plain } from '../fn/item-management/item-management-get-sub-category-by-gender-for-drowpdown-plain';
import { ItemManagementGetSubCategoryByGenderForDrowpdown$Plain$Params } from '../fn/item-management/item-management-get-sub-category-by-gender-for-drowpdown-plain';
import { itemManagementInputUpdateDataRequestById } from '../fn/item-management/item-management-input-update-data-request-by-id';
import { ItemManagementInputUpdateDataRequestById$Params } from '../fn/item-management/item-management-input-update-data-request-by-id';
import { itemManagementInputUpdateDataRequestById$Plain } from '../fn/item-management/item-management-input-update-data-request-by-id-plain';
import { ItemManagementInputUpdateDataRequestById$Plain$Params } from '../fn/item-management/item-management-input-update-data-request-by-id-plain';
import { itemManagementsyncAllItemsFromShopify } from '../fn/item-management/item-managementsync-all-items-from-shopify';
import { ItemManagementsyncAllItemsFromShopify$Params } from '../fn/item-management/item-managementsync-all-items-from-shopify';
import { itemManagementsyncAllItemsFromShopify$Plain } from '../fn/item-management/item-managementsync-all-items-from-shopify-plain';
import { ItemManagementsyncAllItemsFromShopify$Plain$Params } from '../fn/item-management/item-managementsync-all-items-from-shopify-plain';
import { itemManagementUpdateBrand } from '../fn/item-management/item-management-update-brand';
import { ItemManagementUpdateBrand$Params } from '../fn/item-management/item-management-update-brand';
import { itemManagementUpdateBrand$Plain } from '../fn/item-management/item-management-update-brand-plain';
import { ItemManagementUpdateBrand$Plain$Params } from '../fn/item-management/item-management-update-brand-plain';
import { itemManagementUpdateCategory } from '../fn/item-management/item-management-update-category';
import { ItemManagementUpdateCategory$Params } from '../fn/item-management/item-management-update-category';
import { itemManagementUpdateCategory$Plain } from '../fn/item-management/item-management-update-category-plain';
import { ItemManagementUpdateCategory$Plain$Params } from '../fn/item-management/item-management-update-category-plain';
import { itemManagementUpdateColor } from '../fn/item-management/item-management-update-color';
import { ItemManagementUpdateColor$Params } from '../fn/item-management/item-management-update-color';
import { itemManagementUpdateColor$Plain } from '../fn/item-management/item-management-update-color-plain';
import { ItemManagementUpdateColor$Plain$Params } from '../fn/item-management/item-management-update-color-plain';
import { itemManagementUpdateColorCategory } from '../fn/item-management/item-management-update-color-category';
import { ItemManagementUpdateColorCategory$Params } from '../fn/item-management/item-management-update-color-category';
import { itemManagementUpdateColorCategory$Plain } from '../fn/item-management/item-management-update-color-category-plain';
import { ItemManagementUpdateColorCategory$Plain$Params } from '../fn/item-management/item-management-update-color-category-plain';
import { itemManagementUpdateFilterFeature } from '../fn/item-management/item-management-update-filter-feature';
import { ItemManagementUpdateFilterFeature$Params } from '../fn/item-management/item-management-update-filter-feature';
import { itemManagementUpdateFilterFeature$Plain } from '../fn/item-management/item-management-update-filter-feature-plain';
import { ItemManagementUpdateFilterFeature$Plain$Params } from '../fn/item-management/item-management-update-filter-feature-plain';
import { itemManagementUpdateFilterFeatureContent } from '../fn/item-management/item-management-update-filter-feature-content';
import { ItemManagementUpdateFilterFeatureContent$Params } from '../fn/item-management/item-management-update-filter-feature-content';
import { itemManagementUpdateFilterFeatureContent$Plain } from '../fn/item-management/item-management-update-filter-feature-content-plain';
import { ItemManagementUpdateFilterFeatureContent$Plain$Params } from '../fn/item-management/item-management-update-filter-feature-content-plain';
import { itemManagementUpdateGenderLink } from '../fn/item-management/item-management-update-gender-link';
import { ItemManagementUpdateGenderLink$Params } from '../fn/item-management/item-management-update-gender-link';
import { itemManagementUpdateGenderLink$Plain } from '../fn/item-management/item-management-update-gender-link-plain';
import { ItemManagementUpdateGenderLink$Plain$Params } from '../fn/item-management/item-management-update-gender-link-plain';
import { itemManagementUpdateItem } from '../fn/item-management/item-management-update-item';
import { ItemManagementUpdateItem$Params } from '../fn/item-management/item-management-update-item';
import { itemManagementUpdateItem$Plain } from '../fn/item-management/item-management-update-item-plain';
import { ItemManagementUpdateItem$Plain$Params } from '../fn/item-management/item-management-update-item-plain';
import { itemManagementUpdateMaterial } from '../fn/item-management/item-management-update-material';
import { ItemManagementUpdateMaterial$Params } from '../fn/item-management/item-management-update-material';
import { itemManagementUpdateMaterial$Plain } from '../fn/item-management/item-management-update-material-plain';
import { ItemManagementUpdateMaterial$Plain$Params } from '../fn/item-management/item-management-update-material-plain';
import { itemManagementUpdateSeason } from '../fn/item-management/item-management-update-season';
import { ItemManagementUpdateSeason$Params } from '../fn/item-management/item-management-update-season';
import { itemManagementUpdateSeason$Plain } from '../fn/item-management/item-management-update-season-plain';
import { ItemManagementUpdateSeason$Plain$Params } from '../fn/item-management/item-management-update-season-plain';
import { itemManagementUpdateSize } from '../fn/item-management/item-management-update-size';
import { ItemManagementUpdateSize$Params } from '../fn/item-management/item-management-update-size';
import { itemManagementUpdateSize$Plain } from '../fn/item-management/item-management-update-size-plain';
import { ItemManagementUpdateSize$Plain$Params } from '../fn/item-management/item-management-update-size-plain';
import { itemManagementUpdateSizeCategory } from '../fn/item-management/item-management-update-size-category';
import { ItemManagementUpdateSizeCategory$Params } from '../fn/item-management/item-management-update-size-category';
import { itemManagementUpdateSizeCategory$Plain } from '../fn/item-management/item-management-update-size-category-plain';
import { ItemManagementUpdateSizeCategory$Plain$Params } from '../fn/item-management/item-management-update-size-category-plain';
import { itemManagementUpdateSizeName } from '../fn/item-management/item-management-update-size-name';
import { ItemManagementUpdateSizeName$Params } from '../fn/item-management/item-management-update-size-name';
import { itemManagementUpdateSizeName$Plain } from '../fn/item-management/item-management-update-size-name-plain';
import { ItemManagementUpdateSizeName$Plain$Params } from '../fn/item-management/item-management-update-size-name-plain';
import { itemManagementUpdateStyle } from '../fn/item-management/item-management-update-style';
import { ItemManagementUpdateStyle$Params } from '../fn/item-management/item-management-update-style';
import { itemManagementUpdateStyle$Plain } from '../fn/item-management/item-management-update-style-plain';
import { ItemManagementUpdateStyle$Plain$Params } from '../fn/item-management/item-management-update-style-plain';
import { itemManagementUpdateSubCategory } from '../fn/item-management/item-management-update-sub-category';
import { ItemManagementUpdateSubCategory$Params } from '../fn/item-management/item-management-update-sub-category';
import { itemManagementUpdateSubCategory$Plain } from '../fn/item-management/item-management-update-sub-category-plain';
import { ItemManagementUpdateSubCategory$Plain$Params } from '../fn/item-management/item-management-update-sub-category-plain';
import { OutputGetAllBrandsDto } from '../models/output-get-all-brands-dto';
import { OutputGetAllCategoryDto } from '../models/output-get-all-category-dto';
import { OutputGetAllColorCategoryDto } from '../models/output-get-all-color-category-dto';
import { OutputGetAllColorDto } from '../models/output-get-all-color-dto';
import { OutputGetAllDataRequestsForAdminDto } from '../models/output-get-all-data-requests-for-admin-dto';
import { OutputGetAllFeatureWithContent } from '../models/output-get-all-feature-with-content';
import { OutputGetAllFilterFeatureContentDto } from '../models/output-get-all-filter-feature-content-dto';
import { OutputGetAllFilterFeatureDto } from '../models/output-get-all-filter-feature-dto';
import { OutputGetAllGenderLinkDto } from '../models/output-get-all-gender-link-dto';
import { OutputGetAllItemForDropDownDto } from '../models/output-get-all-item-for-drop-down-dto';
import { OutputGetAllItemForDropDownWithImageDto } from '../models/output-get-all-item-for-drop-down-with-image-dto';
import { OutputGetAllItemForTableDtoListOutputPagedResponseDto } from '../models/output-get-all-item-for-table-dto-list-output-paged-response-dto';
import { OutputGetAllMaterialDto } from '../models/output-get-all-material-dto';
import { OutputGetAllSeasonDto } from '../models/output-get-all-season-dto';
import { OutputGetAllSizeCategoryDto } from '../models/output-get-all-size-category-dto';
import { OutputGetAllSizeDto } from '../models/output-get-all-size-dto';
import { OutputGetAllSizeNameDto } from '../models/output-get-all-size-name-dto';
import { OutputGetAllStylesDto } from '../models/output-get-all-styles-dto';
import { OutputGetAllSubCategoryDto } from '../models/output-get-all-sub-category-dto';
import { OutputGetAllSubCategoryForDrowpDownListDto } from '../models/output-get-all-sub-category-for-drowp-down-list-dto';

@Injectable({ providedIn: 'root' })
export class ItemManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `itemManagementsyncAllItemsFromShopify()` */
  static readonly ItemManagementsyncAllItemsFromShopifyPath = '/api/admin/ItemManagement/syncAllItemsFromShopify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementsyncAllItemsFromShopify$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementsyncAllItemsFromShopify$Plain$Response(params?: ItemManagementsyncAllItemsFromShopify$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementsyncAllItemsFromShopify$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementsyncAllItemsFromShopify$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementsyncAllItemsFromShopify$Plain(params?: ItemManagementsyncAllItemsFromShopify$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementsyncAllItemsFromShopify$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementsyncAllItemsFromShopify()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementsyncAllItemsFromShopify$Response(params?: ItemManagementsyncAllItemsFromShopify$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementsyncAllItemsFromShopify(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementsyncAllItemsFromShopify$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementsyncAllItemsFromShopify(params?: ItemManagementsyncAllItemsFromShopify$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementsyncAllItemsFromShopify$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementCreateBrand()` */
  static readonly ItemManagementCreateBrandPath = '/api/admin/ItemManagement/CreateBrand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateBrand$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateBrand$Plain$Response(params?: ItemManagementCreateBrand$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateBrand$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateBrand$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateBrand$Plain(params?: ItemManagementCreateBrand$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateBrand$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateBrand()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateBrand$Response(params?: ItemManagementCreateBrand$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateBrand(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateBrand$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateBrand(params?: ItemManagementCreateBrand$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateBrand$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementDeleteBrand()` */
  static readonly ItemManagementDeleteBrandPath = '/api/admin/ItemManagement/DeleteBrand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteBrand$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteBrand$Plain$Response(params?: ItemManagementDeleteBrand$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteBrand$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteBrand$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteBrand$Plain(params?: ItemManagementDeleteBrand$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteBrand$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteBrand()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteBrand$Response(params?: ItemManagementDeleteBrand$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteBrand(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteBrand$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteBrand(params?: ItemManagementDeleteBrand$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteBrand$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementUpdateBrand()` */
  static readonly ItemManagementUpdateBrandPath = '/api/admin/ItemManagement/UpdateBrand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateBrand$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateBrand$Plain$Response(params?: ItemManagementUpdateBrand$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateBrand$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateBrand$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateBrand$Plain(params?: ItemManagementUpdateBrand$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateBrand$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateBrand()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateBrand$Response(params?: ItemManagementUpdateBrand$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateBrand(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateBrand$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateBrand(params?: ItemManagementUpdateBrand$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateBrand$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllBrand()` */
  static readonly ItemManagementGetAllBrandPath = '/api/admin/ItemManagement/GetAllBrand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllBrand$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllBrand$Plain$Response(params?: ItemManagementGetAllBrand$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllBrandsDto>>> {
    return itemManagementGetAllBrand$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllBrand$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllBrand$Plain(params?: ItemManagementGetAllBrand$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllBrandsDto>> {
    return this.itemManagementGetAllBrand$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBrandsDto>>): Array<OutputGetAllBrandsDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllBrand()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllBrand$Response(params?: ItemManagementGetAllBrand$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllBrandsDto>>> {
    return itemManagementGetAllBrand(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllBrand$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllBrand(params?: ItemManagementGetAllBrand$Params, context?: HttpContext): Observable<Array<OutputGetAllBrandsDto>> {
    return this.itemManagementGetAllBrand$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBrandsDto>>): Array<OutputGetAllBrandsDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementCreateCategory()` */
  static readonly ItemManagementCreateCategoryPath = '/api/admin/ItemManagement/CreateCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateCategory$Plain$Response(params?: ItemManagementCreateCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateCategory$Plain(params?: ItemManagementCreateCategory$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateCategory$Response(params?: ItemManagementCreateCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateCategory(params?: ItemManagementCreateCategory$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllCategory()` */
  static readonly ItemManagementGetAllCategoryPath = '/api/admin/ItemManagement/GetAllCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllCategory$Plain$Response(params?: ItemManagementGetAllCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllCategoryDto>>> {
    return itemManagementGetAllCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllCategory$Plain(params?: ItemManagementGetAllCategory$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllCategoryDto>> {
    return this.itemManagementGetAllCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCategoryDto>>): Array<OutputGetAllCategoryDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllCategory$Response(params?: ItemManagementGetAllCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllCategoryDto>>> {
    return itemManagementGetAllCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllCategory(params?: ItemManagementGetAllCategory$Params, context?: HttpContext): Observable<Array<OutputGetAllCategoryDto>> {
    return this.itemManagementGetAllCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCategoryDto>>): Array<OutputGetAllCategoryDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementUpdateCategory()` */
  static readonly ItemManagementUpdateCategoryPath = '/api/admin/ItemManagement/UpdateCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateCategory$Plain$Response(params?: ItemManagementUpdateCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateCategory$Plain(params?: ItemManagementUpdateCategory$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateCategory$Response(params?: ItemManagementUpdateCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateCategory(params?: ItemManagementUpdateCategory$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementDeleteCategory()` */
  static readonly ItemManagementDeleteCategoryPath = '/api/admin/ItemManagement/DeleteCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteCategory$Response(params?: ItemManagementDeleteCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return itemManagementDeleteCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteCategory(params?: ItemManagementDeleteCategory$Params, context?: HttpContext): Observable<void> {
    return this.itemManagementDeleteCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `itemManagementCreateSubCategory()` */
  static readonly ItemManagementCreateSubCategoryPath = '/api/admin/ItemManagement/CreateSubCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSubCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSubCategory$Plain$Response(params?: ItemManagementCreateSubCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateSubCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSubCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSubCategory$Plain(params?: ItemManagementCreateSubCategory$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateSubCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSubCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSubCategory$Response(params?: ItemManagementCreateSubCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateSubCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSubCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSubCategory(params?: ItemManagementCreateSubCategory$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateSubCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllSubCategory()` */
  static readonly ItemManagementGetAllSubCategoryPath = '/api/admin/ItemManagement/GetAllSubCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSubCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSubCategory$Plain$Response(params?: ItemManagementGetAllSubCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>> {
    return itemManagementGetAllSubCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSubCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSubCategory$Plain(params?: ItemManagementGetAllSubCategory$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllSubCategoryDto>> {
    return this.itemManagementGetAllSubCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>): Array<OutputGetAllSubCategoryDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSubCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSubCategory$Response(params?: ItemManagementGetAllSubCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>> {
    return itemManagementGetAllSubCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSubCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSubCategory(params?: ItemManagementGetAllSubCategory$Params, context?: HttpContext): Observable<Array<OutputGetAllSubCategoryDto>> {
    return this.itemManagementGetAllSubCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>): Array<OutputGetAllSubCategoryDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllSubCategoryByCategoryId()` */
  static readonly ItemManagementGetAllSubCategoryByCategoryIdPath = '/api/admin/ItemManagement/GetAllSubCategoryByCategoryId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSubCategoryByCategoryId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSubCategoryByCategoryId$Plain$Response(params?: ItemManagementGetAllSubCategoryByCategoryId$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>> {
    return itemManagementGetAllSubCategoryByCategoryId$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSubCategoryByCategoryId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSubCategoryByCategoryId$Plain(params?: ItemManagementGetAllSubCategoryByCategoryId$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllSubCategoryDto>> {
    return this.itemManagementGetAllSubCategoryByCategoryId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>): Array<OutputGetAllSubCategoryDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSubCategoryByCategoryId()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSubCategoryByCategoryId$Response(params?: ItemManagementGetAllSubCategoryByCategoryId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>> {
    return itemManagementGetAllSubCategoryByCategoryId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSubCategoryByCategoryId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSubCategoryByCategoryId(params?: ItemManagementGetAllSubCategoryByCategoryId$Params, context?: HttpContext): Observable<Array<OutputGetAllSubCategoryDto>> {
    return this.itemManagementGetAllSubCategoryByCategoryId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>): Array<OutputGetAllSubCategoryDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementUpdateSubCategory()` */
  static readonly ItemManagementUpdateSubCategoryPath = '/api/admin/ItemManagement/UpdateSubCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSubCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSubCategory$Plain$Response(params?: ItemManagementUpdateSubCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateSubCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSubCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSubCategory$Plain(params?: ItemManagementUpdateSubCategory$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateSubCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSubCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSubCategory$Response(params?: ItemManagementUpdateSubCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateSubCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSubCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSubCategory(params?: ItemManagementUpdateSubCategory$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateSubCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementDeleteSubCategory()` */
  static readonly ItemManagementDeleteSubCategoryPath = '/api/admin/ItemManagement/DeleteSubCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSubCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSubCategory$Response(params?: ItemManagementDeleteSubCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return itemManagementDeleteSubCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSubCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSubCategory(params?: ItemManagementDeleteSubCategory$Params, context?: HttpContext): Observable<void> {
    return this.itemManagementDeleteSubCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `itemManagementGetSubCategoryByGenderForDrowpdown()` */
  static readonly ItemManagementGetSubCategoryByGenderForDrowpdownPath = '/api/admin/ItemManagement/GetSubCategoryByGenderForDrowpdown';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetSubCategoryByGenderForDrowpdown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetSubCategoryByGenderForDrowpdown$Plain$Response(params?: ItemManagementGetSubCategoryByGenderForDrowpdown$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllSubCategoryForDrowpDownListDto>>> {
    return itemManagementGetSubCategoryByGenderForDrowpdown$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetSubCategoryByGenderForDrowpdown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetSubCategoryByGenderForDrowpdown$Plain(params?: ItemManagementGetSubCategoryByGenderForDrowpdown$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllSubCategoryForDrowpDownListDto>> {
    return this.itemManagementGetSubCategoryByGenderForDrowpdown$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSubCategoryForDrowpDownListDto>>): Array<OutputGetAllSubCategoryForDrowpDownListDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetSubCategoryByGenderForDrowpdown()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetSubCategoryByGenderForDrowpdown$Response(params?: ItemManagementGetSubCategoryByGenderForDrowpdown$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllSubCategoryForDrowpDownListDto>>> {
    return itemManagementGetSubCategoryByGenderForDrowpdown(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetSubCategoryByGenderForDrowpdown$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetSubCategoryByGenderForDrowpdown(params?: ItemManagementGetSubCategoryByGenderForDrowpdown$Params, context?: HttpContext): Observable<Array<OutputGetAllSubCategoryForDrowpDownListDto>> {
    return this.itemManagementGetSubCategoryByGenderForDrowpdown$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSubCategoryForDrowpDownListDto>>): Array<OutputGetAllSubCategoryForDrowpDownListDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementCreateItem()` */
  static readonly ItemManagementCreateItemPath = '/api/admin/ItemManagement/CreateItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateItem$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateItem$Plain$Response(params?: ItemManagementCreateItem$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateItem$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateItem$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateItem$Plain(params?: ItemManagementCreateItem$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateItem$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateItem$Response(params?: ItemManagementCreateItem$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateItem(params?: ItemManagementCreateItem$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementUpdateItem()` */
  static readonly ItemManagementUpdateItemPath = '/api/admin/ItemManagement/UpdateItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateItem$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateItem$Plain$Response(params?: ItemManagementUpdateItem$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateItem$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateItem$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateItem$Plain(params?: ItemManagementUpdateItem$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateItem$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateItem$Response(params?: ItemManagementUpdateItem$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateItem(params?: ItemManagementUpdateItem$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllPagedItemsForAdmin()` */
  static readonly ItemManagementGetAllPagedItemsForAdminPath = '/api/admin/ItemManagement/GetAllPagedItemsForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllPagedItemsForAdmin$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementGetAllPagedItemsForAdmin$Plain$Response(params?: ItemManagementGetAllPagedItemsForAdmin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetAllItemForTableDtoListOutputPagedResponseDto>> {
    return itemManagementGetAllPagedItemsForAdmin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllPagedItemsForAdmin$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementGetAllPagedItemsForAdmin$Plain(params?: ItemManagementGetAllPagedItemsForAdmin$Plain$Params, context?: HttpContext): Observable<OutputGetAllItemForTableDtoListOutputPagedResponseDto> {
    return this.itemManagementGetAllPagedItemsForAdmin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetAllItemForTableDtoListOutputPagedResponseDto>): OutputGetAllItemForTableDtoListOutputPagedResponseDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllPagedItemsForAdmin()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementGetAllPagedItemsForAdmin$Response(params?: ItemManagementGetAllPagedItemsForAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetAllItemForTableDtoListOutputPagedResponseDto>> {
    return itemManagementGetAllPagedItemsForAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllPagedItemsForAdmin$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementGetAllPagedItemsForAdmin(params?: ItemManagementGetAllPagedItemsForAdmin$Params, context?: HttpContext): Observable<OutputGetAllItemForTableDtoListOutputPagedResponseDto> {
    return this.itemManagementGetAllPagedItemsForAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetAllItemForTableDtoListOutputPagedResponseDto>): OutputGetAllItemForTableDtoListOutputPagedResponseDto => r.body)
    );
  }

  /** Path part for operation `itemManagementDeleteItem()` */
  static readonly ItemManagementDeleteItemPath = '/api/admin/ItemManagement/DeleteItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteItem$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteItem$Plain$Response(params?: ItemManagementDeleteItem$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteItem$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteItem$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteItem$Plain(params?: ItemManagementDeleteItem$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteItem$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteItem$Response(params?: ItemManagementDeleteItem$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteItem(params?: ItemManagementDeleteItem$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementDeleteBulkItem()` */
  static readonly ItemManagementDeleteBulkItemPath = '/api/admin/ItemManagement/DeleteBulkItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteBulkItem$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteBulkItem$Plain$Response(params?: ItemManagementDeleteBulkItem$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteBulkItem$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteBulkItem$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteBulkItem$Plain(params?: ItemManagementDeleteBulkItem$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteBulkItem$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteBulkItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteBulkItem$Response(params?: ItemManagementDeleteBulkItem$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteBulkItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteBulkItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteBulkItem(params?: ItemManagementDeleteBulkItem$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteBulkItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllItemsForDropDown()` */
  static readonly ItemManagementGetAllItemsForDropDownPath = '/api/admin/ItemManagement/GetAllItemsForDropDown';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllItemsForDropDown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllItemsForDropDown$Plain$Response(params?: ItemManagementGetAllItemsForDropDown$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllItemForDropDownDto>>> {
    return itemManagementGetAllItemsForDropDown$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllItemsForDropDown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllItemsForDropDown$Plain(params?: ItemManagementGetAllItemsForDropDown$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllItemForDropDownDto>> {
    return this.itemManagementGetAllItemsForDropDown$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllItemForDropDownDto>>): Array<OutputGetAllItemForDropDownDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllItemsForDropDown()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllItemsForDropDown$Response(params?: ItemManagementGetAllItemsForDropDown$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllItemForDropDownDto>>> {
    return itemManagementGetAllItemsForDropDown(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllItemsForDropDown$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllItemsForDropDown(params?: ItemManagementGetAllItemsForDropDown$Params, context?: HttpContext): Observable<Array<OutputGetAllItemForDropDownDto>> {
    return this.itemManagementGetAllItemsForDropDown$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllItemForDropDownDto>>): Array<OutputGetAllItemForDropDownDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllItemsSearchForDropDownWithImage()` */
  static readonly ItemManagementGetAllItemsSearchForDropDownWithImagePath = '/api/admin/ItemManagement/GetAllItemsSearchForDropDownWithImage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllItemsSearchForDropDownWithImage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllItemsSearchForDropDownWithImage$Plain$Response(params?: ItemManagementGetAllItemsSearchForDropDownWithImage$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllItemForDropDownWithImageDto>>> {
    return itemManagementGetAllItemsSearchForDropDownWithImage$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllItemsSearchForDropDownWithImage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllItemsSearchForDropDownWithImage$Plain(params?: ItemManagementGetAllItemsSearchForDropDownWithImage$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllItemForDropDownWithImageDto>> {
    return this.itemManagementGetAllItemsSearchForDropDownWithImage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllItemForDropDownWithImageDto>>): Array<OutputGetAllItemForDropDownWithImageDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllItemsSearchForDropDownWithImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllItemsSearchForDropDownWithImage$Response(params?: ItemManagementGetAllItemsSearchForDropDownWithImage$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllItemForDropDownWithImageDto>>> {
    return itemManagementGetAllItemsSearchForDropDownWithImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllItemsSearchForDropDownWithImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllItemsSearchForDropDownWithImage(params?: ItemManagementGetAllItemsSearchForDropDownWithImage$Params, context?: HttpContext): Observable<Array<OutputGetAllItemForDropDownWithImageDto>> {
    return this.itemManagementGetAllItemsSearchForDropDownWithImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllItemForDropDownWithImageDto>>): Array<OutputGetAllItemForDropDownWithImageDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementCreateColor()` */
  static readonly ItemManagementCreateColorPath = '/api/admin/ItemManagement/CreateColor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateColor$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateColor$Plain$Response(params?: ItemManagementCreateColor$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateColor$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateColor$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateColor$Plain(params?: ItemManagementCreateColor$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateColor$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateColor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateColor$Response(params?: ItemManagementCreateColor$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateColor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateColor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateColor(params?: ItemManagementCreateColor$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateColor$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementDeleteColor()` */
  static readonly ItemManagementDeleteColorPath = '/api/admin/ItemManagement/DeleteColor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteColor$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteColor$Plain$Response(params?: ItemManagementDeleteColor$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteColor$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteColor$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteColor$Plain(params?: ItemManagementDeleteColor$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteColor$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteColor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteColor$Response(params?: ItemManagementDeleteColor$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteColor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteColor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteColor(params?: ItemManagementDeleteColor$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteColor$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementUpdateColor()` */
  static readonly ItemManagementUpdateColorPath = '/api/admin/ItemManagement/UpdateColor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateColor$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateColor$Plain$Response(params?: ItemManagementUpdateColor$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateColor$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateColor$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateColor$Plain(params?: ItemManagementUpdateColor$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateColor$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateColor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateColor$Response(params?: ItemManagementUpdateColor$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateColor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateColor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateColor(params?: ItemManagementUpdateColor$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateColor$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllColor()` */
  static readonly ItemManagementGetAllColorPath = '/api/admin/ItemManagement/GetAllColor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllColor$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllColor$Plain$Response(params?: ItemManagementGetAllColor$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllColorDto>>> {
    return itemManagementGetAllColor$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllColor$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllColor$Plain(params?: ItemManagementGetAllColor$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllColorDto>> {
    return this.itemManagementGetAllColor$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllColorDto>>): Array<OutputGetAllColorDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllColor()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllColor$Response(params?: ItemManagementGetAllColor$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllColorDto>>> {
    return itemManagementGetAllColor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllColor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllColor(params?: ItemManagementGetAllColor$Params, context?: HttpContext): Observable<Array<OutputGetAllColorDto>> {
    return this.itemManagementGetAllColor$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllColorDto>>): Array<OutputGetAllColorDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementCreateColorCategory()` */
  static readonly ItemManagementCreateColorCategoryPath = '/api/admin/ItemManagement/CreateColorCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateColorCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateColorCategory$Plain$Response(params?: ItemManagementCreateColorCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateColorCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateColorCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateColorCategory$Plain(params?: ItemManagementCreateColorCategory$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateColorCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateColorCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateColorCategory$Response(params?: ItemManagementCreateColorCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateColorCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateColorCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateColorCategory(params?: ItemManagementCreateColorCategory$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateColorCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementDeleteColorCategory()` */
  static readonly ItemManagementDeleteColorCategoryPath = '/api/admin/ItemManagement/DeleteColorCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteColorCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteColorCategory$Plain$Response(params?: ItemManagementDeleteColorCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteColorCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteColorCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteColorCategory$Plain(params?: ItemManagementDeleteColorCategory$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteColorCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteColorCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteColorCategory$Response(params?: ItemManagementDeleteColorCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteColorCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteColorCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteColorCategory(params?: ItemManagementDeleteColorCategory$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteColorCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementUpdateColorCategory()` */
  static readonly ItemManagementUpdateColorCategoryPath = '/api/admin/ItemManagement/UpdateColorCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateColorCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateColorCategory$Plain$Response(params?: ItemManagementUpdateColorCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateColorCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateColorCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateColorCategory$Plain(params?: ItemManagementUpdateColorCategory$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateColorCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateColorCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateColorCategory$Response(params?: ItemManagementUpdateColorCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateColorCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateColorCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateColorCategory(params?: ItemManagementUpdateColorCategory$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateColorCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllColorCategory()` */
  static readonly ItemManagementGetAllColorCategoryPath = '/api/admin/ItemManagement/GetAllColorCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllColorCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllColorCategory$Plain$Response(params?: ItemManagementGetAllColorCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllColorCategoryDto>>> {
    return itemManagementGetAllColorCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllColorCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllColorCategory$Plain(params?: ItemManagementGetAllColorCategory$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllColorCategoryDto>> {
    return this.itemManagementGetAllColorCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllColorCategoryDto>>): Array<OutputGetAllColorCategoryDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllColorCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllColorCategory$Response(params?: ItemManagementGetAllColorCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllColorCategoryDto>>> {
    return itemManagementGetAllColorCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllColorCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllColorCategory(params?: ItemManagementGetAllColorCategory$Params, context?: HttpContext): Observable<Array<OutputGetAllColorCategoryDto>> {
    return this.itemManagementGetAllColorCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllColorCategoryDto>>): Array<OutputGetAllColorCategoryDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementCreateSeason()` */
  static readonly ItemManagementCreateSeasonPath = '/api/admin/ItemManagement/CreateSeason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSeason$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSeason$Plain$Response(params?: ItemManagementCreateSeason$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateSeason$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSeason$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSeason$Plain(params?: ItemManagementCreateSeason$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateSeason$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSeason()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSeason$Response(params?: ItemManagementCreateSeason$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateSeason(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSeason$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSeason(params?: ItemManagementCreateSeason$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateSeason$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementDeleteSeason()` */
  static readonly ItemManagementDeleteSeasonPath = '/api/admin/ItemManagement/DeleteSeason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSeason$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSeason$Plain$Response(params?: ItemManagementDeleteSeason$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteSeason$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSeason$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSeason$Plain(params?: ItemManagementDeleteSeason$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteSeason$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSeason()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSeason$Response(params?: ItemManagementDeleteSeason$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteSeason(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSeason$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSeason(params?: ItemManagementDeleteSeason$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteSeason$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementUpdateSeason()` */
  static readonly ItemManagementUpdateSeasonPath = '/api/admin/ItemManagement/UpdateSeason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSeason$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSeason$Plain$Response(params?: ItemManagementUpdateSeason$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateSeason$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSeason$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSeason$Plain(params?: ItemManagementUpdateSeason$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateSeason$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSeason()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSeason$Response(params?: ItemManagementUpdateSeason$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateSeason(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSeason$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSeason(params?: ItemManagementUpdateSeason$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateSeason$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllSeason()` */
  static readonly ItemManagementGetAllSeasonPath = '/api/admin/ItemManagement/GetAllSeason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSeason$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSeason$Plain$Response(params?: ItemManagementGetAllSeason$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllSeasonDto>>> {
    return itemManagementGetAllSeason$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSeason$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSeason$Plain(params?: ItemManagementGetAllSeason$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllSeasonDto>> {
    return this.itemManagementGetAllSeason$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSeasonDto>>): Array<OutputGetAllSeasonDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSeason()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSeason$Response(params?: ItemManagementGetAllSeason$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllSeasonDto>>> {
    return itemManagementGetAllSeason(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSeason$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSeason(params?: ItemManagementGetAllSeason$Params, context?: HttpContext): Observable<Array<OutputGetAllSeasonDto>> {
    return this.itemManagementGetAllSeason$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSeasonDto>>): Array<OutputGetAllSeasonDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementCreateMaterial()` */
  static readonly ItemManagementCreateMaterialPath = '/api/admin/ItemManagement/CreateMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateMaterial$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateMaterial$Plain$Response(params?: ItemManagementCreateMaterial$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateMaterial$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateMaterial$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateMaterial$Plain(params?: ItemManagementCreateMaterial$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateMaterial$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateMaterial()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateMaterial$Response(params?: ItemManagementCreateMaterial$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateMaterial(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateMaterial$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateMaterial(params?: ItemManagementCreateMaterial$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateMaterial$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementDeleteMaterial()` */
  static readonly ItemManagementDeleteMaterialPath = '/api/admin/ItemManagement/DeleteMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteMaterial$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteMaterial$Plain$Response(params?: ItemManagementDeleteMaterial$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteMaterial$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteMaterial$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteMaterial$Plain(params?: ItemManagementDeleteMaterial$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteMaterial$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteMaterial()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteMaterial$Response(params?: ItemManagementDeleteMaterial$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteMaterial(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteMaterial$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteMaterial(params?: ItemManagementDeleteMaterial$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteMaterial$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementUpdateMaterial()` */
  static readonly ItemManagementUpdateMaterialPath = '/api/admin/ItemManagement/UpdateMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateMaterial$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateMaterial$Plain$Response(params?: ItemManagementUpdateMaterial$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateMaterial$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateMaterial$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateMaterial$Plain(params?: ItemManagementUpdateMaterial$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateMaterial$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateMaterial()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateMaterial$Response(params?: ItemManagementUpdateMaterial$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateMaterial(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateMaterial$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateMaterial(params?: ItemManagementUpdateMaterial$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateMaterial$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllMaterial()` */
  static readonly ItemManagementGetAllMaterialPath = '/api/admin/ItemManagement/GetAllMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllMaterial$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllMaterial$Plain$Response(params?: ItemManagementGetAllMaterial$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllMaterialDto>>> {
    return itemManagementGetAllMaterial$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllMaterial$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllMaterial$Plain(params?: ItemManagementGetAllMaterial$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllMaterialDto>> {
    return this.itemManagementGetAllMaterial$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMaterialDto>>): Array<OutputGetAllMaterialDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllMaterial()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllMaterial$Response(params?: ItemManagementGetAllMaterial$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllMaterialDto>>> {
    return itemManagementGetAllMaterial(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllMaterial$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllMaterial(params?: ItemManagementGetAllMaterial$Params, context?: HttpContext): Observable<Array<OutputGetAllMaterialDto>> {
    return this.itemManagementGetAllMaterial$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMaterialDto>>): Array<OutputGetAllMaterialDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementCreateSize()` */
  static readonly ItemManagementCreateSizePath = '/api/admin/ItemManagement/CreateSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSize$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSize$Plain$Response(params?: ItemManagementCreateSize$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateSize$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSize$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSize$Plain(params?: ItemManagementCreateSize$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateSize$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSize()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSize$Response(params?: ItemManagementCreateSize$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateSize(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSize$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSize(params?: ItemManagementCreateSize$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateSize$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementDeleteSize()` */
  static readonly ItemManagementDeleteSizePath = '/api/admin/ItemManagement/DeleteSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSize$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSize$Plain$Response(params?: ItemManagementDeleteSize$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteSize$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSize$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSize$Plain(params?: ItemManagementDeleteSize$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteSize$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSize()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSize$Response(params?: ItemManagementDeleteSize$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteSize(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSize$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSize(params?: ItemManagementDeleteSize$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteSize$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementUpdateSize()` */
  static readonly ItemManagementUpdateSizePath = '/api/admin/ItemManagement/UpdateSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSize$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSize$Plain$Response(params?: ItemManagementUpdateSize$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateSize$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSize$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSize$Plain(params?: ItemManagementUpdateSize$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateSize$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSize()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSize$Response(params?: ItemManagementUpdateSize$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateSize(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSize$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSize(params?: ItemManagementUpdateSize$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateSize$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllSize()` */
  static readonly ItemManagementGetAllSizePath = '/api/admin/ItemManagement/GetAllSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSize$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSize$Plain$Response(params?: ItemManagementGetAllSize$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllSizeDto>>> {
    return itemManagementGetAllSize$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSize$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSize$Plain(params?: ItemManagementGetAllSize$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllSizeDto>> {
    return this.itemManagementGetAllSize$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeDto>>): Array<OutputGetAllSizeDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSize()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSize$Response(params?: ItemManagementGetAllSize$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllSizeDto>>> {
    return itemManagementGetAllSize(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSize$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSize(params?: ItemManagementGetAllSize$Params, context?: HttpContext): Observable<Array<OutputGetAllSizeDto>> {
    return this.itemManagementGetAllSize$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeDto>>): Array<OutputGetAllSizeDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementCreateSizeName()` */
  static readonly ItemManagementCreateSizeNamePath = '/api/admin/ItemManagement/CreateSizeName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSizeName$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSizeName$Plain$Response(params?: ItemManagementCreateSizeName$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateSizeName$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSizeName$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSizeName$Plain(params?: ItemManagementCreateSizeName$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateSizeName$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSizeName()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSizeName$Response(params?: ItemManagementCreateSizeName$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateSizeName(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSizeName$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSizeName(params?: ItemManagementCreateSizeName$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateSizeName$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementDeleteSizeName()` */
  static readonly ItemManagementDeleteSizeNamePath = '/api/admin/ItemManagement/DeleteSizeName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSizeName$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSizeName$Plain$Response(params?: ItemManagementDeleteSizeName$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteSizeName$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSizeName$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSizeName$Plain(params?: ItemManagementDeleteSizeName$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteSizeName$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSizeName()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSizeName$Response(params?: ItemManagementDeleteSizeName$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteSizeName(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSizeName$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSizeName(params?: ItemManagementDeleteSizeName$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteSizeName$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementUpdateSizeName()` */
  static readonly ItemManagementUpdateSizeNamePath = '/api/admin/ItemManagement/UpdateSizeName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSizeName$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSizeName$Plain$Response(params?: ItemManagementUpdateSizeName$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateSizeName$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSizeName$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSizeName$Plain(params?: ItemManagementUpdateSizeName$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateSizeName$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSizeName()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSizeName$Response(params?: ItemManagementUpdateSizeName$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateSizeName(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSizeName$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSizeName(params?: ItemManagementUpdateSizeName$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateSizeName$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllSizeName()` */
  static readonly ItemManagementGetAllSizeNamePath = '/api/admin/ItemManagement/GetAllSizeName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSizeName$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSizeName$Plain$Response(params?: ItemManagementGetAllSizeName$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllSizeNameDto>>> {
    return itemManagementGetAllSizeName$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSizeName$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSizeName$Plain(params?: ItemManagementGetAllSizeName$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllSizeNameDto>> {
    return this.itemManagementGetAllSizeName$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeNameDto>>): Array<OutputGetAllSizeNameDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSizeName()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSizeName$Response(params?: ItemManagementGetAllSizeName$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllSizeNameDto>>> {
    return itemManagementGetAllSizeName(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSizeName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSizeName(params?: ItemManagementGetAllSizeName$Params, context?: HttpContext): Observable<Array<OutputGetAllSizeNameDto>> {
    return this.itemManagementGetAllSizeName$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeNameDto>>): Array<OutputGetAllSizeNameDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementCreateSizeCategory()` */
  static readonly ItemManagementCreateSizeCategoryPath = '/api/admin/ItemManagement/CreateSizeCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSizeCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSizeCategory$Plain$Response(params?: ItemManagementCreateSizeCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateSizeCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSizeCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSizeCategory$Plain(params?: ItemManagementCreateSizeCategory$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateSizeCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSizeCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSizeCategory$Response(params?: ItemManagementCreateSizeCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateSizeCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSizeCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSizeCategory(params?: ItemManagementCreateSizeCategory$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateSizeCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementDeleteSizeCategory()` */
  static readonly ItemManagementDeleteSizeCategoryPath = '/api/admin/ItemManagement/DeleteSizeCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSizeCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSizeCategory$Plain$Response(params?: ItemManagementDeleteSizeCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteSizeCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSizeCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSizeCategory$Plain(params?: ItemManagementDeleteSizeCategory$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteSizeCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSizeCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSizeCategory$Response(params?: ItemManagementDeleteSizeCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteSizeCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSizeCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSizeCategory(params?: ItemManagementDeleteSizeCategory$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteSizeCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementUpdateSizeCategory()` */
  static readonly ItemManagementUpdateSizeCategoryPath = '/api/admin/ItemManagement/UpdateSizeCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSizeCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSizeCategory$Plain$Response(params?: ItemManagementUpdateSizeCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateSizeCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSizeCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSizeCategory$Plain(params?: ItemManagementUpdateSizeCategory$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateSizeCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSizeCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSizeCategory$Response(params?: ItemManagementUpdateSizeCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateSizeCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSizeCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSizeCategory(params?: ItemManagementUpdateSizeCategory$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateSizeCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllSizeCategory()` */
  static readonly ItemManagementGetAllSizeCategoryPath = '/api/admin/ItemManagement/GetAllSizeCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSizeCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSizeCategory$Plain$Response(params?: ItemManagementGetAllSizeCategory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllSizeCategoryDto>>> {
    return itemManagementGetAllSizeCategory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSizeCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSizeCategory$Plain(params?: ItemManagementGetAllSizeCategory$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllSizeCategoryDto>> {
    return this.itemManagementGetAllSizeCategory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeCategoryDto>>): Array<OutputGetAllSizeCategoryDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSizeCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSizeCategory$Response(params?: ItemManagementGetAllSizeCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllSizeCategoryDto>>> {
    return itemManagementGetAllSizeCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSizeCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSizeCategory(params?: ItemManagementGetAllSizeCategory$Params, context?: HttpContext): Observable<Array<OutputGetAllSizeCategoryDto>> {
    return this.itemManagementGetAllSizeCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeCategoryDto>>): Array<OutputGetAllSizeCategoryDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementCreateStyle()` */
  static readonly ItemManagementCreateStylePath = '/api/admin/ItemManagement/CreateStyle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateStyle$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateStyle$Plain$Response(params?: ItemManagementCreateStyle$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return itemManagementCreateStyle$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateStyle$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateStyle$Plain(params?: ItemManagementCreateStyle$Plain$Params, context?: HttpContext): Observable<number> {
    return this.itemManagementCreateStyle$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateStyle()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateStyle$Response(params?: ItemManagementCreateStyle$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return itemManagementCreateStyle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateStyle$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateStyle(params?: ItemManagementCreateStyle$Params, context?: HttpContext): Observable<number> {
    return this.itemManagementCreateStyle$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `itemManagementDeleteStyle()` */
  static readonly ItemManagementDeleteStylePath = '/api/admin/ItemManagement/DeleteStyle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteStyle$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteStyle$Plain$Response(params?: ItemManagementDeleteStyle$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteStyle$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteStyle$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteStyle$Plain(params?: ItemManagementDeleteStyle$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteStyle$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteStyle()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteStyle$Response(params?: ItemManagementDeleteStyle$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteStyle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteStyle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteStyle(params?: ItemManagementDeleteStyle$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteStyle$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementUpdateStyle()` */
  static readonly ItemManagementUpdateStylePath = '/api/admin/ItemManagement/UpdateStyle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateStyle$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateStyle$Plain$Response(params?: ItemManagementUpdateStyle$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateStyle$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateStyle$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateStyle$Plain(params?: ItemManagementUpdateStyle$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateStyle$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateStyle()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateStyle$Response(params?: ItemManagementUpdateStyle$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateStyle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateStyle$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateStyle(params?: ItemManagementUpdateStyle$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateStyle$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllStyles()` */
  static readonly ItemManagementGetAllStylesPath = '/api/admin/ItemManagement/GetAllStyles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllStyles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllStyles$Plain$Response(params?: ItemManagementGetAllStyles$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylesDto>>> {
    return itemManagementGetAllStyles$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllStyles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllStyles$Plain(params?: ItemManagementGetAllStyles$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllStylesDto>> {
    return this.itemManagementGetAllStyles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylesDto>>): Array<OutputGetAllStylesDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllStyles()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllStyles$Response(params?: ItemManagementGetAllStyles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllStylesDto>>> {
    return itemManagementGetAllStyles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllStyles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllStyles(params?: ItemManagementGetAllStyles$Params, context?: HttpContext): Observable<Array<OutputGetAllStylesDto>> {
    return this.itemManagementGetAllStyles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylesDto>>): Array<OutputGetAllStylesDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementCreateGenderLink()` */
  static readonly ItemManagementCreateGenderLinkPath = '/api/admin/ItemManagement/CreateGenderLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateGenderLink$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateGenderLink$Plain$Response(params?: ItemManagementCreateGenderLink$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateGenderLink$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateGenderLink$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateGenderLink$Plain(params?: ItemManagementCreateGenderLink$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateGenderLink$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateGenderLink()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateGenderLink$Response(params?: ItemManagementCreateGenderLink$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateGenderLink(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateGenderLink$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateGenderLink(params?: ItemManagementCreateGenderLink$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateGenderLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementDeleteGenderLink()` */
  static readonly ItemManagementDeleteGenderLinkPath = '/api/admin/ItemManagement/DeleteGenderLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteGenderLink$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteGenderLink$Plain$Response(params?: ItemManagementDeleteGenderLink$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteGenderLink$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteGenderLink$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteGenderLink$Plain(params?: ItemManagementDeleteGenderLink$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteGenderLink$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteGenderLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteGenderLink$Response(params?: ItemManagementDeleteGenderLink$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteGenderLink(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteGenderLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteGenderLink(params?: ItemManagementDeleteGenderLink$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteGenderLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementUpdateGenderLink()` */
  static readonly ItemManagementUpdateGenderLinkPath = '/api/admin/ItemManagement/UpdateGenderLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateGenderLink$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateGenderLink$Plain$Response(params?: ItemManagementUpdateGenderLink$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateGenderLink$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateGenderLink$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateGenderLink$Plain(params?: ItemManagementUpdateGenderLink$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateGenderLink$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateGenderLink()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateGenderLink$Response(params?: ItemManagementUpdateGenderLink$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateGenderLink(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateGenderLink$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateGenderLink(params?: ItemManagementUpdateGenderLink$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateGenderLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllGenderLink()` */
  static readonly ItemManagementGetAllGenderLinkPath = '/api/admin/ItemManagement/GetAllGenderLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllGenderLink$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllGenderLink$Plain$Response(params?: ItemManagementGetAllGenderLink$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllGenderLinkDto>>> {
    return itemManagementGetAllGenderLink$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllGenderLink$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllGenderLink$Plain(params?: ItemManagementGetAllGenderLink$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllGenderLinkDto>> {
    return this.itemManagementGetAllGenderLink$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllGenderLinkDto>>): Array<OutputGetAllGenderLinkDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllGenderLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllGenderLink$Response(params?: ItemManagementGetAllGenderLink$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllGenderLinkDto>>> {
    return itemManagementGetAllGenderLink(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllGenderLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllGenderLink(params?: ItemManagementGetAllGenderLink$Params, context?: HttpContext): Observable<Array<OutputGetAllGenderLinkDto>> {
    return this.itemManagementGetAllGenderLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllGenderLinkDto>>): Array<OutputGetAllGenderLinkDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllDataRequestsForAdmin()` */
  static readonly ItemManagementGetAllDataRequestsForAdminPath = '/api/admin/ItemManagement/GetAllDataRequestsForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllDataRequestsForAdmin$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllDataRequestsForAdmin$Plain$Response(params?: ItemManagementGetAllDataRequestsForAdmin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllDataRequestsForAdminDto>>> {
    return itemManagementGetAllDataRequestsForAdmin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllDataRequestsForAdmin$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllDataRequestsForAdmin$Plain(params?: ItemManagementGetAllDataRequestsForAdmin$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllDataRequestsForAdminDto>> {
    return this.itemManagementGetAllDataRequestsForAdmin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllDataRequestsForAdminDto>>): Array<OutputGetAllDataRequestsForAdminDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllDataRequestsForAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllDataRequestsForAdmin$Response(params?: ItemManagementGetAllDataRequestsForAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllDataRequestsForAdminDto>>> {
    return itemManagementGetAllDataRequestsForAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllDataRequestsForAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllDataRequestsForAdmin(params?: ItemManagementGetAllDataRequestsForAdmin$Params, context?: HttpContext): Observable<Array<OutputGetAllDataRequestsForAdminDto>> {
    return this.itemManagementGetAllDataRequestsForAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllDataRequestsForAdminDto>>): Array<OutputGetAllDataRequestsForAdminDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementInputUpdateDataRequestById()` */
  static readonly ItemManagementInputUpdateDataRequestByIdPath = '/api/admin/ItemManagement/InputUpdateDataRequestById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementInputUpdateDataRequestById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementInputUpdateDataRequestById$Plain$Response(params?: ItemManagementInputUpdateDataRequestById$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementInputUpdateDataRequestById$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementInputUpdateDataRequestById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementInputUpdateDataRequestById$Plain(params?: ItemManagementInputUpdateDataRequestById$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementInputUpdateDataRequestById$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementInputUpdateDataRequestById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementInputUpdateDataRequestById$Response(params?: ItemManagementInputUpdateDataRequestById$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementInputUpdateDataRequestById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementInputUpdateDataRequestById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementInputUpdateDataRequestById(params?: ItemManagementInputUpdateDataRequestById$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementInputUpdateDataRequestById$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementCreateFilterFeature()` */
  static readonly ItemManagementCreateFilterFeaturePath = '/api/admin/ItemManagement/CreateFilterFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateFilterFeature$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateFilterFeature$Plain$Response(params?: ItemManagementCreateFilterFeature$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateFilterFeature$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateFilterFeature$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateFilterFeature$Plain(params?: ItemManagementCreateFilterFeature$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateFilterFeature$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateFilterFeature()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateFilterFeature$Response(params?: ItemManagementCreateFilterFeature$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateFilterFeature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateFilterFeature$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateFilterFeature(params?: ItemManagementCreateFilterFeature$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateFilterFeature$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementDeleteFilterFeature()` */
  static readonly ItemManagementDeleteFilterFeaturePath = '/api/admin/ItemManagement/DeleteFilterFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteFilterFeature$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteFilterFeature$Plain$Response(params?: ItemManagementDeleteFilterFeature$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteFilterFeature$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteFilterFeature$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteFilterFeature$Plain(params?: ItemManagementDeleteFilterFeature$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteFilterFeature$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteFilterFeature()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteFilterFeature$Response(params?: ItemManagementDeleteFilterFeature$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteFilterFeature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteFilterFeature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteFilterFeature(params?: ItemManagementDeleteFilterFeature$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteFilterFeature$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementUpdateFilterFeature()` */
  static readonly ItemManagementUpdateFilterFeaturePath = '/api/admin/ItemManagement/UpdateFilterFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateFilterFeature$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateFilterFeature$Plain$Response(params?: ItemManagementUpdateFilterFeature$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateFilterFeature$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateFilterFeature$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateFilterFeature$Plain(params?: ItemManagementUpdateFilterFeature$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateFilterFeature$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateFilterFeature()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateFilterFeature$Response(params?: ItemManagementUpdateFilterFeature$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateFilterFeature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateFilterFeature$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateFilterFeature(params?: ItemManagementUpdateFilterFeature$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateFilterFeature$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllFilterFeature()` */
  static readonly ItemManagementGetAllFilterFeaturePath = '/api/admin/ItemManagement/GetAllFilterFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllFilterFeature$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFilterFeature$Plain$Response(params?: ItemManagementGetAllFilterFeature$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllFilterFeatureDto>>> {
    return itemManagementGetAllFilterFeature$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllFilterFeature$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFilterFeature$Plain(params?: ItemManagementGetAllFilterFeature$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllFilterFeatureDto>> {
    return this.itemManagementGetAllFilterFeature$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFilterFeatureDto>>): Array<OutputGetAllFilterFeatureDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllFilterFeature()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFilterFeature$Response(params?: ItemManagementGetAllFilterFeature$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllFilterFeatureDto>>> {
    return itemManagementGetAllFilterFeature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllFilterFeature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFilterFeature(params?: ItemManagementGetAllFilterFeature$Params, context?: HttpContext): Observable<Array<OutputGetAllFilterFeatureDto>> {
    return this.itemManagementGetAllFilterFeature$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFilterFeatureDto>>): Array<OutputGetAllFilterFeatureDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementCreateFilterFeatureContent()` */
  static readonly ItemManagementCreateFilterFeatureContentPath = '/api/admin/ItemManagement/CreateFilterFeatureContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateFilterFeatureContent$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateFilterFeatureContent$Plain$Response(params?: ItemManagementCreateFilterFeatureContent$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateFilterFeatureContent$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateFilterFeatureContent$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateFilterFeatureContent$Plain(params?: ItemManagementCreateFilterFeatureContent$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateFilterFeatureContent$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateFilterFeatureContent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateFilterFeatureContent$Response(params?: ItemManagementCreateFilterFeatureContent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementCreateFilterFeatureContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateFilterFeatureContent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateFilterFeatureContent(params?: ItemManagementCreateFilterFeatureContent$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementCreateFilterFeatureContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementDeleteFilterFeatureContent()` */
  static readonly ItemManagementDeleteFilterFeatureContentPath = '/api/admin/ItemManagement/DeleteFilterFeatureContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteFilterFeatureContent$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteFilterFeatureContent$Plain$Response(params?: ItemManagementDeleteFilterFeatureContent$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteFilterFeatureContent$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteFilterFeatureContent$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteFilterFeatureContent$Plain(params?: ItemManagementDeleteFilterFeatureContent$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteFilterFeatureContent$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteFilterFeatureContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteFilterFeatureContent$Response(params?: ItemManagementDeleteFilterFeatureContent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementDeleteFilterFeatureContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteFilterFeatureContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteFilterFeatureContent(params?: ItemManagementDeleteFilterFeatureContent$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementDeleteFilterFeatureContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementUpdateFilterFeatureContent()` */
  static readonly ItemManagementUpdateFilterFeatureContentPath = '/api/admin/ItemManagement/UpdateFilterFeatureContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateFilterFeatureContent$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateFilterFeatureContent$Plain$Response(params?: ItemManagementUpdateFilterFeatureContent$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateFilterFeatureContent$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateFilterFeatureContent$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateFilterFeatureContent$Plain(params?: ItemManagementUpdateFilterFeatureContent$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateFilterFeatureContent$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateFilterFeatureContent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateFilterFeatureContent$Response(params?: ItemManagementUpdateFilterFeatureContent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return itemManagementUpdateFilterFeatureContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateFilterFeatureContent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateFilterFeatureContent(params?: ItemManagementUpdateFilterFeatureContent$Params, context?: HttpContext): Observable<boolean> {
    return this.itemManagementUpdateFilterFeatureContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllFilterFeatureContent()` */
  static readonly ItemManagementGetAllFilterFeatureContentPath = '/api/admin/ItemManagement/GetAllFilterFeatureContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllFilterFeatureContent$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFilterFeatureContent$Plain$Response(params?: ItemManagementGetAllFilterFeatureContent$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllFilterFeatureContentDto>>> {
    return itemManagementGetAllFilterFeatureContent$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllFilterFeatureContent$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFilterFeatureContent$Plain(params?: ItemManagementGetAllFilterFeatureContent$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllFilterFeatureContentDto>> {
    return this.itemManagementGetAllFilterFeatureContent$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFilterFeatureContentDto>>): Array<OutputGetAllFilterFeatureContentDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllFilterFeatureContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFilterFeatureContent$Response(params?: ItemManagementGetAllFilterFeatureContent$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllFilterFeatureContentDto>>> {
    return itemManagementGetAllFilterFeatureContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllFilterFeatureContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFilterFeatureContent(params?: ItemManagementGetAllFilterFeatureContent$Params, context?: HttpContext): Observable<Array<OutputGetAllFilterFeatureContentDto>> {
    return this.itemManagementGetAllFilterFeatureContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFilterFeatureContentDto>>): Array<OutputGetAllFilterFeatureContentDto> => r.body)
    );
  }

  /** Path part for operation `itemManagementGetAllFeatureWithContent()` */
  static readonly ItemManagementGetAllFeatureWithContentPath = '/api/admin/ItemManagement/GetAllFeatureWithContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllFeatureWithContent$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFeatureWithContent$Plain$Response(params?: ItemManagementGetAllFeatureWithContent$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllFeatureWithContent>>> {
    return itemManagementGetAllFeatureWithContent$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllFeatureWithContent$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFeatureWithContent$Plain(params?: ItemManagementGetAllFeatureWithContent$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllFeatureWithContent>> {
    return this.itemManagementGetAllFeatureWithContent$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFeatureWithContent>>): Array<OutputGetAllFeatureWithContent> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllFeatureWithContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFeatureWithContent$Response(params?: ItemManagementGetAllFeatureWithContent$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllFeatureWithContent>>> {
    return itemManagementGetAllFeatureWithContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllFeatureWithContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFeatureWithContent(params?: ItemManagementGetAllFeatureWithContent$Params, context?: HttpContext): Observable<Array<OutputGetAllFeatureWithContent>> {
    return this.itemManagementGetAllFeatureWithContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFeatureWithContent>>): Array<OutputGetAllFeatureWithContent> => r.body)
    );
  }

}
