/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { OutputGetAboutUsDto } from '../models/output-get-about-us-dto';
import { OutputGetAllBlogsDto } from '../models/output-get-all-blogs-dto';
import { OutPutGetAllCategoryAdsDto } from '../models/out-put-get-all-category-ads-dto';
import { OutPutGetAllHeaderAddsDto } from '../models/out-put-get-all-header-adds-dto';
import { OutputGetAllHomeSectionsDto } from '../models/output-get-all-home-sections-dto';
import { OutputGetAllSliderDto } from '../models/output-get-all-slider-dto';
import { OutputGetAllTeamMemberDto } from '../models/output-get-all-team-member-dto';
import { OutputGetBlogDetailsDto } from '../models/output-get-blog-details-dto';
import { OutputGetFaqDto } from '../models/output-get-faq-dto';
import { OutputGetGenderAdsDto } from '../models/output-get-gender-ads-dto';
import { OutputGetGeneralContent } from '../models/output-get-general-content';
import { OutPutGetHeaderLinkCategoryDto } from '../models/out-put-get-header-link-category-dto';
import { OutPutGetHeaderLinkDto } from '../models/out-put-get-header-link-dto';
import { OutputGetPrivacyPolicyDto } from '../models/output-get-privacy-policy-dto';
import { OutputGetTermsDto } from '../models/output-get-terms-dto';
import { siteContentManagementCreateBlog } from '../fn/site-content-management/site-content-management-create-blog';
import { SiteContentManagementCreateBlog$Params } from '../fn/site-content-management/site-content-management-create-blog';
import { siteContentManagementCreateBlog$Plain } from '../fn/site-content-management/site-content-management-create-blog-plain';
import { SiteContentManagementCreateBlog$Plain$Params } from '../fn/site-content-management/site-content-management-create-blog-plain';
import { siteContentManagementCreateCategoryAds } from '../fn/site-content-management/site-content-management-create-category-ads';
import { SiteContentManagementCreateCategoryAds$Params } from '../fn/site-content-management/site-content-management-create-category-ads';
import { siteContentManagementCreateCategoryAds$Plain } from '../fn/site-content-management/site-content-management-create-category-ads-plain';
import { SiteContentManagementCreateCategoryAds$Plain$Params } from '../fn/site-content-management/site-content-management-create-category-ads-plain';
import { siteContentManagementCreateHeaderAdds } from '../fn/site-content-management/site-content-management-create-header-adds';
import { SiteContentManagementCreateHeaderAdds$Params } from '../fn/site-content-management/site-content-management-create-header-adds';
import { siteContentManagementCreateHeaderAdds$Plain } from '../fn/site-content-management/site-content-management-create-header-adds-plain';
import { SiteContentManagementCreateHeaderAdds$Plain$Params } from '../fn/site-content-management/site-content-management-create-header-adds-plain';
import { siteContentManagementCreateHeaderLink } from '../fn/site-content-management/site-content-management-create-header-link';
import { SiteContentManagementCreateHeaderLink$Params } from '../fn/site-content-management/site-content-management-create-header-link';
import { siteContentManagementCreateHeaderLink$Plain } from '../fn/site-content-management/site-content-management-create-header-link-plain';
import { SiteContentManagementCreateHeaderLink$Plain$Params } from '../fn/site-content-management/site-content-management-create-header-link-plain';
import { siteContentManagementCreateHomeSection } from '../fn/site-content-management/site-content-management-create-home-section';
import { SiteContentManagementCreateHomeSection$Params } from '../fn/site-content-management/site-content-management-create-home-section';
import { siteContentManagementCreateHomeSection$Plain } from '../fn/site-content-management/site-content-management-create-home-section-plain';
import { SiteContentManagementCreateHomeSection$Plain$Params } from '../fn/site-content-management/site-content-management-create-home-section-plain';
import { siteContentManagementCreateSlider } from '../fn/site-content-management/site-content-management-create-slider';
import { SiteContentManagementCreateSlider$Params } from '../fn/site-content-management/site-content-management-create-slider';
import { siteContentManagementCreateSlider$Plain } from '../fn/site-content-management/site-content-management-create-slider-plain';
import { SiteContentManagementCreateSlider$Plain$Params } from '../fn/site-content-management/site-content-management-create-slider-plain';
import { siteContentManagementCreateTeamMember } from '../fn/site-content-management/site-content-management-create-team-member';
import { SiteContentManagementCreateTeamMember$Params } from '../fn/site-content-management/site-content-management-create-team-member';
import { siteContentManagementCreateTeamMember$Plain } from '../fn/site-content-management/site-content-management-create-team-member-plain';
import { SiteContentManagementCreateTeamMember$Plain$Params } from '../fn/site-content-management/site-content-management-create-team-member-plain';
import { siteContentManagementDeleteBlog } from '../fn/site-content-management/site-content-management-delete-blog';
import { SiteContentManagementDeleteBlog$Params } from '../fn/site-content-management/site-content-management-delete-blog';
import { siteContentManagementDeleteBlog$Plain } from '../fn/site-content-management/site-content-management-delete-blog-plain';
import { SiteContentManagementDeleteBlog$Plain$Params } from '../fn/site-content-management/site-content-management-delete-blog-plain';
import { siteContentManagementDeleteCategoryAds } from '../fn/site-content-management/site-content-management-delete-category-ads';
import { SiteContentManagementDeleteCategoryAds$Params } from '../fn/site-content-management/site-content-management-delete-category-ads';
import { siteContentManagementDeleteCategoryAds$Plain } from '../fn/site-content-management/site-content-management-delete-category-ads-plain';
import { SiteContentManagementDeleteCategoryAds$Plain$Params } from '../fn/site-content-management/site-content-management-delete-category-ads-plain';
import { siteContentManagementDeleteHeaderAdds } from '../fn/site-content-management/site-content-management-delete-header-adds';
import { SiteContentManagementDeleteHeaderAdds$Params } from '../fn/site-content-management/site-content-management-delete-header-adds';
import { siteContentManagementDeleteHeaderAdds$Plain } from '../fn/site-content-management/site-content-management-delete-header-adds-plain';
import { SiteContentManagementDeleteHeaderAdds$Plain$Params } from '../fn/site-content-management/site-content-management-delete-header-adds-plain';
import { siteContentManagementDeleteHeaderLink } from '../fn/site-content-management/site-content-management-delete-header-link';
import { SiteContentManagementDeleteHeaderLink$Params } from '../fn/site-content-management/site-content-management-delete-header-link';
import { siteContentManagementDeleteHeaderLink$Plain } from '../fn/site-content-management/site-content-management-delete-header-link-plain';
import { SiteContentManagementDeleteHeaderLink$Plain$Params } from '../fn/site-content-management/site-content-management-delete-header-link-plain';
import { siteContentManagementDeleteHomeSection } from '../fn/site-content-management/site-content-management-delete-home-section';
import { SiteContentManagementDeleteHomeSection$Params } from '../fn/site-content-management/site-content-management-delete-home-section';
import { siteContentManagementDeleteHomeSection$Plain } from '../fn/site-content-management/site-content-management-delete-home-section-plain';
import { SiteContentManagementDeleteHomeSection$Plain$Params } from '../fn/site-content-management/site-content-management-delete-home-section-plain';
import { siteContentManagementDeleteSlider } from '../fn/site-content-management/site-content-management-delete-slider';
import { SiteContentManagementDeleteSlider$Params } from '../fn/site-content-management/site-content-management-delete-slider';
import { siteContentManagementDeleteSlider$Plain } from '../fn/site-content-management/site-content-management-delete-slider-plain';
import { SiteContentManagementDeleteSlider$Plain$Params } from '../fn/site-content-management/site-content-management-delete-slider-plain';
import { siteContentManagementDeleteTeamMember } from '../fn/site-content-management/site-content-management-delete-team-member';
import { SiteContentManagementDeleteTeamMember$Params } from '../fn/site-content-management/site-content-management-delete-team-member';
import { siteContentManagementDeleteTeamMember$Plain } from '../fn/site-content-management/site-content-management-delete-team-member-plain';
import { SiteContentManagementDeleteTeamMember$Plain$Params } from '../fn/site-content-management/site-content-management-delete-team-member-plain';
import { siteContentManagementGetAboutUs } from '../fn/site-content-management/site-content-management-get-about-us';
import { SiteContentManagementGetAboutUs$Params } from '../fn/site-content-management/site-content-management-get-about-us';
import { siteContentManagementGetAboutUs$Plain } from '../fn/site-content-management/site-content-management-get-about-us-plain';
import { SiteContentManagementGetAboutUs$Plain$Params } from '../fn/site-content-management/site-content-management-get-about-us-plain';
import { siteContentManagementGetAllBlogs } from '../fn/site-content-management/site-content-management-get-all-blogs';
import { SiteContentManagementGetAllBlogs$Params } from '../fn/site-content-management/site-content-management-get-all-blogs';
import { siteContentManagementGetAllBlogs$Plain } from '../fn/site-content-management/site-content-management-get-all-blogs-plain';
import { SiteContentManagementGetAllBlogs$Plain$Params } from '../fn/site-content-management/site-content-management-get-all-blogs-plain';
import { siteContentManagementGetAllCategoryAds } from '../fn/site-content-management/site-content-management-get-all-category-ads';
import { SiteContentManagementGetAllCategoryAds$Params } from '../fn/site-content-management/site-content-management-get-all-category-ads';
import { siteContentManagementGetAllCategoryAds$Plain } from '../fn/site-content-management/site-content-management-get-all-category-ads-plain';
import { SiteContentManagementGetAllCategoryAds$Plain$Params } from '../fn/site-content-management/site-content-management-get-all-category-ads-plain';
import { siteContentManagementGetAllHeaderAdds } from '../fn/site-content-management/site-content-management-get-all-header-adds';
import { SiteContentManagementGetAllHeaderAdds$Params } from '../fn/site-content-management/site-content-management-get-all-header-adds';
import { siteContentManagementGetAllHeaderAdds$Plain } from '../fn/site-content-management/site-content-management-get-all-header-adds-plain';
import { SiteContentManagementGetAllHeaderAdds$Plain$Params } from '../fn/site-content-management/site-content-management-get-all-header-adds-plain';
import { siteContentManagementGetAllHeaderLinks } from '../fn/site-content-management/site-content-management-get-all-header-links';
import { SiteContentManagementGetAllHeaderLinks$Params } from '../fn/site-content-management/site-content-management-get-all-header-links';
import { siteContentManagementGetAllHeaderLinks$Plain } from '../fn/site-content-management/site-content-management-get-all-header-links-plain';
import { SiteContentManagementGetAllHeaderLinks$Plain$Params } from '../fn/site-content-management/site-content-management-get-all-header-links-plain';
import { siteContentManagementGetAllHomeSection } from '../fn/site-content-management/site-content-management-get-all-home-section';
import { SiteContentManagementGetAllHomeSection$Params } from '../fn/site-content-management/site-content-management-get-all-home-section';
import { siteContentManagementGetAllHomeSection$Plain } from '../fn/site-content-management/site-content-management-get-all-home-section-plain';
import { SiteContentManagementGetAllHomeSection$Plain$Params } from '../fn/site-content-management/site-content-management-get-all-home-section-plain';
import { siteContentManagementGetAllSlider } from '../fn/site-content-management/site-content-management-get-all-slider';
import { SiteContentManagementGetAllSlider$Params } from '../fn/site-content-management/site-content-management-get-all-slider';
import { siteContentManagementGetAllSlider$Plain } from '../fn/site-content-management/site-content-management-get-all-slider-plain';
import { SiteContentManagementGetAllSlider$Plain$Params } from '../fn/site-content-management/site-content-management-get-all-slider-plain';
import { siteContentManagementGetAllTeamMember } from '../fn/site-content-management/site-content-management-get-all-team-member';
import { SiteContentManagementGetAllTeamMember$Params } from '../fn/site-content-management/site-content-management-get-all-team-member';
import { siteContentManagementGetAllTeamMember$Plain } from '../fn/site-content-management/site-content-management-get-all-team-member-plain';
import { SiteContentManagementGetAllTeamMember$Plain$Params } from '../fn/site-content-management/site-content-management-get-all-team-member-plain';
import { siteContentManagementGetBlogDetails } from '../fn/site-content-management/site-content-management-get-blog-details';
import { SiteContentManagementGetBlogDetails$Params } from '../fn/site-content-management/site-content-management-get-blog-details';
import { siteContentManagementGetBlogDetails$Plain } from '../fn/site-content-management/site-content-management-get-blog-details-plain';
import { SiteContentManagementGetBlogDetails$Plain$Params } from '../fn/site-content-management/site-content-management-get-blog-details-plain';
import { siteContentManagementGetFaq } from '../fn/site-content-management/site-content-management-get-faq';
import { SiteContentManagementGetFaq$Params } from '../fn/site-content-management/site-content-management-get-faq';
import { siteContentManagementGetFaq$Plain } from '../fn/site-content-management/site-content-management-get-faq-plain';
import { SiteContentManagementGetFaq$Plain$Params } from '../fn/site-content-management/site-content-management-get-faq-plain';
import { siteContentManagementGetGenderAds } from '../fn/site-content-management/site-content-management-get-gender-ads';
import { SiteContentManagementGetGenderAds$Params } from '../fn/site-content-management/site-content-management-get-gender-ads';
import { siteContentManagementGetGenderAds$Plain } from '../fn/site-content-management/site-content-management-get-gender-ads-plain';
import { SiteContentManagementGetGenderAds$Plain$Params } from '../fn/site-content-management/site-content-management-get-gender-ads-plain';
import { siteContentManagementGetHeaderLinksCategories } from '../fn/site-content-management/site-content-management-get-header-links-categories';
import { SiteContentManagementGetHeaderLinksCategories$Params } from '../fn/site-content-management/site-content-management-get-header-links-categories';
import { siteContentManagementGetHeaderLinksCategories$Plain } from '../fn/site-content-management/site-content-management-get-header-links-categories-plain';
import { SiteContentManagementGetHeaderLinksCategories$Plain$Params } from '../fn/site-content-management/site-content-management-get-header-links-categories-plain';
import { siteContentManagementGetPrivacyPolicy } from '../fn/site-content-management/site-content-management-get-privacy-policy';
import { SiteContentManagementGetPrivacyPolicy$Params } from '../fn/site-content-management/site-content-management-get-privacy-policy';
import { siteContentManagementGetPrivacyPolicy$Plain } from '../fn/site-content-management/site-content-management-get-privacy-policy-plain';
import { SiteContentManagementGetPrivacyPolicy$Plain$Params } from '../fn/site-content-management/site-content-management-get-privacy-policy-plain';
import { siteContentManagementGetSiteContent } from '../fn/site-content-management/site-content-management-get-site-content';
import { SiteContentManagementGetSiteContent$Params } from '../fn/site-content-management/site-content-management-get-site-content';
import { siteContentManagementGetSiteContent$Plain } from '../fn/site-content-management/site-content-management-get-site-content-plain';
import { SiteContentManagementGetSiteContent$Plain$Params } from '../fn/site-content-management/site-content-management-get-site-content-plain';
import { siteContentManagementGetTerms } from '../fn/site-content-management/site-content-management-get-terms';
import { SiteContentManagementGetTerms$Params } from '../fn/site-content-management/site-content-management-get-terms';
import { siteContentManagementGetTerms$Plain } from '../fn/site-content-management/site-content-management-get-terms-plain';
import { SiteContentManagementGetTerms$Plain$Params } from '../fn/site-content-management/site-content-management-get-terms-plain';
import { siteContentManagementUodateTerms } from '../fn/site-content-management/site-content-management-uodate-terms';
import { SiteContentManagementUodateTerms$Params } from '../fn/site-content-management/site-content-management-uodate-terms';
import { siteContentManagementUodateTerms$Plain } from '../fn/site-content-management/site-content-management-uodate-terms-plain';
import { SiteContentManagementUodateTerms$Plain$Params } from '../fn/site-content-management/site-content-management-uodate-terms-plain';
import { siteContentManagementUpdateAboutUs } from '../fn/site-content-management/site-content-management-update-about-us';
import { SiteContentManagementUpdateAboutUs$Params } from '../fn/site-content-management/site-content-management-update-about-us';
import { siteContentManagementUpdateAboutUs$Plain } from '../fn/site-content-management/site-content-management-update-about-us-plain';
import { SiteContentManagementUpdateAboutUs$Plain$Params } from '../fn/site-content-management/site-content-management-update-about-us-plain';
import { siteContentManagementUpdateBlog } from '../fn/site-content-management/site-content-management-update-blog';
import { SiteContentManagementUpdateBlog$Params } from '../fn/site-content-management/site-content-management-update-blog';
import { siteContentManagementUpdateBlog$Plain } from '../fn/site-content-management/site-content-management-update-blog-plain';
import { SiteContentManagementUpdateBlog$Plain$Params } from '../fn/site-content-management/site-content-management-update-blog-plain';
import { siteContentManagementUpdateCategoryAds } from '../fn/site-content-management/site-content-management-update-category-ads';
import { SiteContentManagementUpdateCategoryAds$Params } from '../fn/site-content-management/site-content-management-update-category-ads';
import { siteContentManagementUpdateCategoryAds$Plain } from '../fn/site-content-management/site-content-management-update-category-ads-plain';
import { SiteContentManagementUpdateCategoryAds$Plain$Params } from '../fn/site-content-management/site-content-management-update-category-ads-plain';
import { siteContentManagementUpdateFaq } from '../fn/site-content-management/site-content-management-update-faq';
import { SiteContentManagementUpdateFaq$Params } from '../fn/site-content-management/site-content-management-update-faq';
import { siteContentManagementUpdateFaq$Plain } from '../fn/site-content-management/site-content-management-update-faq-plain';
import { SiteContentManagementUpdateFaq$Plain$Params } from '../fn/site-content-management/site-content-management-update-faq-plain';
import { siteContentManagementUpdateGenderAds } from '../fn/site-content-management/site-content-management-update-gender-ads';
import { SiteContentManagementUpdateGenderAds$Params } from '../fn/site-content-management/site-content-management-update-gender-ads';
import { siteContentManagementUpdateGenderAds$Plain } from '../fn/site-content-management/site-content-management-update-gender-ads-plain';
import { SiteContentManagementUpdateGenderAds$Plain$Params } from '../fn/site-content-management/site-content-management-update-gender-ads-plain';
import { siteContentManagementUpdateHeaderAdds } from '../fn/site-content-management/site-content-management-update-header-adds';
import { SiteContentManagementUpdateHeaderAdds$Params } from '../fn/site-content-management/site-content-management-update-header-adds';
import { siteContentManagementUpdateHeaderAdds$Plain } from '../fn/site-content-management/site-content-management-update-header-adds-plain';
import { SiteContentManagementUpdateHeaderAdds$Plain$Params } from '../fn/site-content-management/site-content-management-update-header-adds-plain';
import { siteContentManagementUpdateHeaderLink } from '../fn/site-content-management/site-content-management-update-header-link';
import { SiteContentManagementUpdateHeaderLink$Params } from '../fn/site-content-management/site-content-management-update-header-link';
import { siteContentManagementUpdateHeaderLink$Plain } from '../fn/site-content-management/site-content-management-update-header-link-plain';
import { SiteContentManagementUpdateHeaderLink$Plain$Params } from '../fn/site-content-management/site-content-management-update-header-link-plain';
import { siteContentManagementUpdateHeaderLinkIndexes } from '../fn/site-content-management/site-content-management-update-header-link-indexes';
import { SiteContentManagementUpdateHeaderLinkIndexes$Params } from '../fn/site-content-management/site-content-management-update-header-link-indexes';
import { siteContentManagementUpdateHeaderLinkIndexes$Plain } from '../fn/site-content-management/site-content-management-update-header-link-indexes-plain';
import { SiteContentManagementUpdateHeaderLinkIndexes$Plain$Params } from '../fn/site-content-management/site-content-management-update-header-link-indexes-plain';
import { siteContentManagementUpdateHomeSection } from '../fn/site-content-management/site-content-management-update-home-section';
import { SiteContentManagementUpdateHomeSection$Params } from '../fn/site-content-management/site-content-management-update-home-section';
import { siteContentManagementUpdateHomeSection$Plain } from '../fn/site-content-management/site-content-management-update-home-section-plain';
import { SiteContentManagementUpdateHomeSection$Plain$Params } from '../fn/site-content-management/site-content-management-update-home-section-plain';
import { siteContentManagementUpdatePrivacy } from '../fn/site-content-management/site-content-management-update-privacy';
import { SiteContentManagementUpdatePrivacy$Params } from '../fn/site-content-management/site-content-management-update-privacy';
import { siteContentManagementUpdatePrivacy$Plain } from '../fn/site-content-management/site-content-management-update-privacy-plain';
import { SiteContentManagementUpdatePrivacy$Plain$Params } from '../fn/site-content-management/site-content-management-update-privacy-plain';
import { siteContentManagementUpdateSiteContent } from '../fn/site-content-management/site-content-management-update-site-content';
import { SiteContentManagementUpdateSiteContent$Params } from '../fn/site-content-management/site-content-management-update-site-content';
import { siteContentManagementUpdateSiteContent$Plain } from '../fn/site-content-management/site-content-management-update-site-content-plain';
import { SiteContentManagementUpdateSiteContent$Plain$Params } from '../fn/site-content-management/site-content-management-update-site-content-plain';
import { siteContentManagementUpdateSlider } from '../fn/site-content-management/site-content-management-update-slider';
import { SiteContentManagementUpdateSlider$Params } from '../fn/site-content-management/site-content-management-update-slider';
import { siteContentManagementUpdateSlider$Plain } from '../fn/site-content-management/site-content-management-update-slider-plain';
import { SiteContentManagementUpdateSlider$Plain$Params } from '../fn/site-content-management/site-content-management-update-slider-plain';
import { siteContentManagementUpdateTeam } from '../fn/site-content-management/site-content-management-update-team';
import { SiteContentManagementUpdateTeam$Params } from '../fn/site-content-management/site-content-management-update-team';
import { siteContentManagementUpdateTeam$Plain } from '../fn/site-content-management/site-content-management-update-team-plain';
import { SiteContentManagementUpdateTeam$Plain$Params } from '../fn/site-content-management/site-content-management-update-team-plain';

@Injectable({ providedIn: 'root' })
export class SiteContentManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `siteContentManagementGetGenderAds()` */
  static readonly SiteContentManagementGetGenderAdsPath = '/api/admin/SiteContentManagement/GetGenderAds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetGenderAds$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetGenderAds$Plain$Response(params?: SiteContentManagementGetGenderAds$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetGenderAdsDto>> {
    return siteContentManagementGetGenderAds$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetGenderAds$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetGenderAds$Plain(params?: SiteContentManagementGetGenderAds$Plain$Params, context?: HttpContext): Observable<OutputGetGenderAdsDto> {
    return this.siteContentManagementGetGenderAds$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetGenderAdsDto>): OutputGetGenderAdsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetGenderAds()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetGenderAds$Response(params?: SiteContentManagementGetGenderAds$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetGenderAdsDto>> {
    return siteContentManagementGetGenderAds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetGenderAds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetGenderAds(params?: SiteContentManagementGetGenderAds$Params, context?: HttpContext): Observable<OutputGetGenderAdsDto> {
    return this.siteContentManagementGetGenderAds$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetGenderAdsDto>): OutputGetGenderAdsDto => r.body)
    );
  }

  /** Path part for operation `siteContentManagementUpdateGenderAds()` */
  static readonly SiteContentManagementUpdateGenderAdsPath = '/api/admin/SiteContentManagement/UpdateGenderAds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateGenderAds$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateGenderAds$Plain$Response(params?: SiteContentManagementUpdateGenderAds$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateGenderAds$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateGenderAds$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateGenderAds$Plain(params?: SiteContentManagementUpdateGenderAds$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateGenderAds$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateGenderAds()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateGenderAds$Response(params?: SiteContentManagementUpdateGenderAds$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateGenderAds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateGenderAds$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateGenderAds(params?: SiteContentManagementUpdateGenderAds$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateGenderAds$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementUpdateAboutUs()` */
  static readonly SiteContentManagementUpdateAboutUsPath = '/api/admin/SiteContentManagement/UpdateAboutUs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateAboutUs$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateAboutUs$Plain$Response(params?: SiteContentManagementUpdateAboutUs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateAboutUs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateAboutUs$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateAboutUs$Plain(params?: SiteContentManagementUpdateAboutUs$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateAboutUs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateAboutUs()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateAboutUs$Response(params?: SiteContentManagementUpdateAboutUs$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateAboutUs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateAboutUs$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateAboutUs(params?: SiteContentManagementUpdateAboutUs$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateAboutUs$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementGetAboutUs()` */
  static readonly SiteContentManagementGetAboutUsPath = '/api/admin/SiteContentManagement/GetAboutUs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAboutUs$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAboutUs$Plain$Response(params?: SiteContentManagementGetAboutUs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetAboutUsDto>> {
    return siteContentManagementGetAboutUs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAboutUs$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAboutUs$Plain(params?: SiteContentManagementGetAboutUs$Plain$Params, context?: HttpContext): Observable<OutputGetAboutUsDto> {
    return this.siteContentManagementGetAboutUs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetAboutUsDto>): OutputGetAboutUsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAboutUs()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAboutUs$Response(params?: SiteContentManagementGetAboutUs$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetAboutUsDto>> {
    return siteContentManagementGetAboutUs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAboutUs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAboutUs(params?: SiteContentManagementGetAboutUs$Params, context?: HttpContext): Observable<OutputGetAboutUsDto> {
    return this.siteContentManagementGetAboutUs$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetAboutUsDto>): OutputGetAboutUsDto => r.body)
    );
  }

  /** Path part for operation `siteContentManagementUodateTerms()` */
  static readonly SiteContentManagementUodateTermsPath = '/api/admin/SiteContentManagement/UodateTerms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUodateTerms$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUodateTerms$Plain$Response(params?: SiteContentManagementUodateTerms$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUodateTerms$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUodateTerms$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUodateTerms$Plain(params?: SiteContentManagementUodateTerms$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUodateTerms$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUodateTerms()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUodateTerms$Response(params?: SiteContentManagementUodateTerms$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUodateTerms(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUodateTerms$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUodateTerms(params?: SiteContentManagementUodateTerms$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUodateTerms$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementGetTerms()` */
  static readonly SiteContentManagementGetTermsPath = '/api/admin/SiteContentManagement/GetTerms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetTerms$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetTerms$Plain$Response(params?: SiteContentManagementGetTerms$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetTermsDto>> {
    return siteContentManagementGetTerms$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetTerms$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetTerms$Plain(params?: SiteContentManagementGetTerms$Plain$Params, context?: HttpContext): Observable<OutputGetTermsDto> {
    return this.siteContentManagementGetTerms$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetTermsDto>): OutputGetTermsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetTerms()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetTerms$Response(params?: SiteContentManagementGetTerms$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetTermsDto>> {
    return siteContentManagementGetTerms(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetTerms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetTerms(params?: SiteContentManagementGetTerms$Params, context?: HttpContext): Observable<OutputGetTermsDto> {
    return this.siteContentManagementGetTerms$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetTermsDto>): OutputGetTermsDto => r.body)
    );
  }

  /** Path part for operation `siteContentManagementUpdatePrivacy()` */
  static readonly SiteContentManagementUpdatePrivacyPath = '/api/admin/SiteContentManagement/UpdatePrivacy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdatePrivacy$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdatePrivacy$Plain$Response(params?: SiteContentManagementUpdatePrivacy$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdatePrivacy$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdatePrivacy$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdatePrivacy$Plain(params?: SiteContentManagementUpdatePrivacy$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdatePrivacy$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdatePrivacy()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdatePrivacy$Response(params?: SiteContentManagementUpdatePrivacy$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdatePrivacy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdatePrivacy$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdatePrivacy(params?: SiteContentManagementUpdatePrivacy$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdatePrivacy$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementGetPrivacyPolicy()` */
  static readonly SiteContentManagementGetPrivacyPolicyPath = '/api/admin/SiteContentManagement/GetPrivacyPolicy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetPrivacyPolicy$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetPrivacyPolicy$Plain$Response(params?: SiteContentManagementGetPrivacyPolicy$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetPrivacyPolicyDto>> {
    return siteContentManagementGetPrivacyPolicy$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetPrivacyPolicy$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetPrivacyPolicy$Plain(params?: SiteContentManagementGetPrivacyPolicy$Plain$Params, context?: HttpContext): Observable<OutputGetPrivacyPolicyDto> {
    return this.siteContentManagementGetPrivacyPolicy$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetPrivacyPolicyDto>): OutputGetPrivacyPolicyDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetPrivacyPolicy()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetPrivacyPolicy$Response(params?: SiteContentManagementGetPrivacyPolicy$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetPrivacyPolicyDto>> {
    return siteContentManagementGetPrivacyPolicy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetPrivacyPolicy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetPrivacyPolicy(params?: SiteContentManagementGetPrivacyPolicy$Params, context?: HttpContext): Observable<OutputGetPrivacyPolicyDto> {
    return this.siteContentManagementGetPrivacyPolicy$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetPrivacyPolicyDto>): OutputGetPrivacyPolicyDto => r.body)
    );
  }

  /** Path part for operation `siteContentManagementUpdateFaq()` */
  static readonly SiteContentManagementUpdateFaqPath = '/api/admin/SiteContentManagement/UpdateFAQ';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateFaq$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateFaq$Plain$Response(params?: SiteContentManagementUpdateFaq$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateFaq$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateFaq$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateFaq$Plain(params?: SiteContentManagementUpdateFaq$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateFaq$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateFaq()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateFaq$Response(params?: SiteContentManagementUpdateFaq$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateFaq(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateFaq$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateFaq(params?: SiteContentManagementUpdateFaq$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateFaq$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementGetFaq()` */
  static readonly SiteContentManagementGetFaqPath = '/api/admin/SiteContentManagement/GetFAQ';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetFaq$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetFaq$Plain$Response(params?: SiteContentManagementGetFaq$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetFaqDto>> {
    return siteContentManagementGetFaq$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetFaq$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetFaq$Plain(params?: SiteContentManagementGetFaq$Plain$Params, context?: HttpContext): Observable<OutputGetFaqDto> {
    return this.siteContentManagementGetFaq$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetFaqDto>): OutputGetFaqDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetFaq()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetFaq$Response(params?: SiteContentManagementGetFaq$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetFaqDto>> {
    return siteContentManagementGetFaq(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetFaq$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetFaq(params?: SiteContentManagementGetFaq$Params, context?: HttpContext): Observable<OutputGetFaqDto> {
    return this.siteContentManagementGetFaq$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetFaqDto>): OutputGetFaqDto => r.body)
    );
  }

  /** Path part for operation `siteContentManagementGetSiteContent()` */
  static readonly SiteContentManagementGetSiteContentPath = '/api/admin/SiteContentManagement/GetSiteContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetSiteContent$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetSiteContent$Plain$Response(params?: SiteContentManagementGetSiteContent$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetGeneralContent>> {
    return siteContentManagementGetSiteContent$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetSiteContent$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetSiteContent$Plain(params?: SiteContentManagementGetSiteContent$Plain$Params, context?: HttpContext): Observable<OutputGetGeneralContent> {
    return this.siteContentManagementGetSiteContent$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetGeneralContent>): OutputGetGeneralContent => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetSiteContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetSiteContent$Response(params?: SiteContentManagementGetSiteContent$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetGeneralContent>> {
    return siteContentManagementGetSiteContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetSiteContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetSiteContent(params?: SiteContentManagementGetSiteContent$Params, context?: HttpContext): Observable<OutputGetGeneralContent> {
    return this.siteContentManagementGetSiteContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetGeneralContent>): OutputGetGeneralContent => r.body)
    );
  }

  /** Path part for operation `siteContentManagementUpdateSiteContent()` */
  static readonly SiteContentManagementUpdateSiteContentPath = '/api/admin/SiteContentManagement/UpdateSiteContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateSiteContent$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateSiteContent$Plain$Response(params?: SiteContentManagementUpdateSiteContent$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateSiteContent$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateSiteContent$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateSiteContent$Plain(params?: SiteContentManagementUpdateSiteContent$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateSiteContent$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateSiteContent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateSiteContent$Response(params?: SiteContentManagementUpdateSiteContent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateSiteContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateSiteContent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateSiteContent(params?: SiteContentManagementUpdateSiteContent$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateSiteContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementUpdateTeam()` */
  static readonly SiteContentManagementUpdateTeamPath = '/api/admin/SiteContentManagement/UpdateTeam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateTeam$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateTeam$Plain$Response(params?: SiteContentManagementUpdateTeam$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateTeam$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateTeam$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateTeam$Plain(params?: SiteContentManagementUpdateTeam$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateTeam$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateTeam()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateTeam$Response(params?: SiteContentManagementUpdateTeam$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateTeam(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateTeam$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateTeam(params?: SiteContentManagementUpdateTeam$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementCreateTeamMember()` */
  static readonly SiteContentManagementCreateTeamMemberPath = '/api/admin/SiteContentManagement/CreateTeamMember';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateTeamMember$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateTeamMember$Plain$Response(params?: SiteContentManagementCreateTeamMember$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementCreateTeamMember$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateTeamMember$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateTeamMember$Plain(params?: SiteContentManagementCreateTeamMember$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementCreateTeamMember$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateTeamMember()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateTeamMember$Response(params?: SiteContentManagementCreateTeamMember$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementCreateTeamMember(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateTeamMember$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateTeamMember(params?: SiteContentManagementCreateTeamMember$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementCreateTeamMember$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementGetAllTeamMember()` */
  static readonly SiteContentManagementGetAllTeamMemberPath = '/api/admin/SiteContentManagement/GetAllTeamMember';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllTeamMember$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllTeamMember$Plain$Response(params?: SiteContentManagementGetAllTeamMember$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllTeamMemberDto>>> {
    return siteContentManagementGetAllTeamMember$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllTeamMember$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllTeamMember$Plain(params?: SiteContentManagementGetAllTeamMember$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllTeamMemberDto>> {
    return this.siteContentManagementGetAllTeamMember$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllTeamMemberDto>>): Array<OutputGetAllTeamMemberDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllTeamMember()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllTeamMember$Response(params?: SiteContentManagementGetAllTeamMember$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllTeamMemberDto>>> {
    return siteContentManagementGetAllTeamMember(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllTeamMember$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllTeamMember(params?: SiteContentManagementGetAllTeamMember$Params, context?: HttpContext): Observable<Array<OutputGetAllTeamMemberDto>> {
    return this.siteContentManagementGetAllTeamMember$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllTeamMemberDto>>): Array<OutputGetAllTeamMemberDto> => r.body)
    );
  }

  /** Path part for operation `siteContentManagementDeleteTeamMember()` */
  static readonly SiteContentManagementDeleteTeamMemberPath = '/api/admin/SiteContentManagement/DeleteTeamMember';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteTeamMember$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteTeamMember$Plain$Response(params?: SiteContentManagementDeleteTeamMember$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementDeleteTeamMember$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteTeamMember$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteTeamMember$Plain(params?: SiteContentManagementDeleteTeamMember$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementDeleteTeamMember$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteTeamMember()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteTeamMember$Response(params?: SiteContentManagementDeleteTeamMember$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementDeleteTeamMember(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteTeamMember$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteTeamMember(params?: SiteContentManagementDeleteTeamMember$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementDeleteTeamMember$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementCreateHomeSection()` */
  static readonly SiteContentManagementCreateHomeSectionPath = '/api/admin/SiteContentManagement/CreateHomeSection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateHomeSection$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHomeSection$Plain$Response(params?: SiteContentManagementCreateHomeSection$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementCreateHomeSection$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateHomeSection$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHomeSection$Plain(params?: SiteContentManagementCreateHomeSection$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementCreateHomeSection$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateHomeSection()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHomeSection$Response(params?: SiteContentManagementCreateHomeSection$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementCreateHomeSection(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateHomeSection$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHomeSection(params?: SiteContentManagementCreateHomeSection$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementCreateHomeSection$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementUpdateHomeSection()` */
  static readonly SiteContentManagementUpdateHomeSectionPath = '/api/admin/SiteContentManagement/UpdateHomeSection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHomeSection$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHomeSection$Plain$Response(params?: SiteContentManagementUpdateHomeSection$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateHomeSection$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHomeSection$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHomeSection$Plain(params?: SiteContentManagementUpdateHomeSection$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateHomeSection$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHomeSection()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHomeSection$Response(params?: SiteContentManagementUpdateHomeSection$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateHomeSection(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHomeSection$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHomeSection(params?: SiteContentManagementUpdateHomeSection$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateHomeSection$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementGetAllHomeSection()` */
  static readonly SiteContentManagementGetAllHomeSectionPath = '/api/admin/SiteContentManagement/GetAllHomeSection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllHomeSection$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHomeSection$Plain$Response(params?: SiteContentManagementGetAllHomeSection$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllHomeSectionsDto>>> {
    return siteContentManagementGetAllHomeSection$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllHomeSection$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHomeSection$Plain(params?: SiteContentManagementGetAllHomeSection$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllHomeSectionsDto>> {
    return this.siteContentManagementGetAllHomeSection$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllHomeSectionsDto>>): Array<OutputGetAllHomeSectionsDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllHomeSection()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHomeSection$Response(params?: SiteContentManagementGetAllHomeSection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllHomeSectionsDto>>> {
    return siteContentManagementGetAllHomeSection(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllHomeSection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHomeSection(params?: SiteContentManagementGetAllHomeSection$Params, context?: HttpContext): Observable<Array<OutputGetAllHomeSectionsDto>> {
    return this.siteContentManagementGetAllHomeSection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllHomeSectionsDto>>): Array<OutputGetAllHomeSectionsDto> => r.body)
    );
  }

  /** Path part for operation `siteContentManagementDeleteHomeSection()` */
  static readonly SiteContentManagementDeleteHomeSectionPath = '/api/admin/SiteContentManagement/DeleteHomeSection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteHomeSection$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHomeSection$Plain$Response(params?: SiteContentManagementDeleteHomeSection$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementDeleteHomeSection$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteHomeSection$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHomeSection$Plain(params?: SiteContentManagementDeleteHomeSection$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementDeleteHomeSection$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteHomeSection()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHomeSection$Response(params?: SiteContentManagementDeleteHomeSection$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementDeleteHomeSection(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteHomeSection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHomeSection(params?: SiteContentManagementDeleteHomeSection$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementDeleteHomeSection$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementCreateSlider()` */
  static readonly SiteContentManagementCreateSliderPath = '/api/admin/SiteContentManagement/CreateSlider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateSlider$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateSlider$Plain$Response(params?: SiteContentManagementCreateSlider$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementCreateSlider$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateSlider$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateSlider$Plain(params?: SiteContentManagementCreateSlider$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementCreateSlider$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateSlider()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateSlider$Response(params?: SiteContentManagementCreateSlider$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementCreateSlider(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateSlider$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateSlider(params?: SiteContentManagementCreateSlider$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementCreateSlider$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementDeleteSlider()` */
  static readonly SiteContentManagementDeleteSliderPath = '/api/admin/SiteContentManagement/DeleteSlider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteSlider$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementDeleteSlider$Plain$Response(params?: SiteContentManagementDeleteSlider$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementDeleteSlider$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteSlider$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementDeleteSlider$Plain(params?: SiteContentManagementDeleteSlider$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementDeleteSlider$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteSlider()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementDeleteSlider$Response(params?: SiteContentManagementDeleteSlider$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementDeleteSlider(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteSlider$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementDeleteSlider(params?: SiteContentManagementDeleteSlider$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementDeleteSlider$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementUpdateSlider()` */
  static readonly SiteContentManagementUpdateSliderPath = '/api/admin/SiteContentManagement/UpdateSlider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateSlider$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateSlider$Plain$Response(params?: SiteContentManagementUpdateSlider$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateSlider$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateSlider$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateSlider$Plain(params?: SiteContentManagementUpdateSlider$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateSlider$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateSlider()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateSlider$Response(params?: SiteContentManagementUpdateSlider$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateSlider(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateSlider$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateSlider(params?: SiteContentManagementUpdateSlider$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateSlider$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementGetAllSlider()` */
  static readonly SiteContentManagementGetAllSliderPath = '/api/admin/SiteContentManagement/GetAllSlider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllSlider$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllSlider$Plain$Response(params?: SiteContentManagementGetAllSlider$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllSliderDto>>> {
    return siteContentManagementGetAllSlider$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllSlider$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllSlider$Plain(params?: SiteContentManagementGetAllSlider$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllSliderDto>> {
    return this.siteContentManagementGetAllSlider$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSliderDto>>): Array<OutputGetAllSliderDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllSlider()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllSlider$Response(params?: SiteContentManagementGetAllSlider$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllSliderDto>>> {
    return siteContentManagementGetAllSlider(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllSlider$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllSlider(params?: SiteContentManagementGetAllSlider$Params, context?: HttpContext): Observable<Array<OutputGetAllSliderDto>> {
    return this.siteContentManagementGetAllSlider$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSliderDto>>): Array<OutputGetAllSliderDto> => r.body)
    );
  }

  /** Path part for operation `siteContentManagementGetAllBlogs()` */
  static readonly SiteContentManagementGetAllBlogsPath = '/api/admin/SiteContentManagement/GetAllBlogs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllBlogs$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllBlogs$Plain$Response(params?: SiteContentManagementGetAllBlogs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllBlogsDto>>> {
    return siteContentManagementGetAllBlogs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllBlogs$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllBlogs$Plain(params?: SiteContentManagementGetAllBlogs$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllBlogsDto>> {
    return this.siteContentManagementGetAllBlogs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBlogsDto>>): Array<OutputGetAllBlogsDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllBlogs()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllBlogs$Response(params?: SiteContentManagementGetAllBlogs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllBlogsDto>>> {
    return siteContentManagementGetAllBlogs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllBlogs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllBlogs(params?: SiteContentManagementGetAllBlogs$Params, context?: HttpContext): Observable<Array<OutputGetAllBlogsDto>> {
    return this.siteContentManagementGetAllBlogs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBlogsDto>>): Array<OutputGetAllBlogsDto> => r.body)
    );
  }

  /** Path part for operation `siteContentManagementGetBlogDetails()` */
  static readonly SiteContentManagementGetBlogDetailsPath = '/api/admin/SiteContentManagement/GetBlogDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetBlogDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetBlogDetails$Plain$Response(params?: SiteContentManagementGetBlogDetails$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetBlogDetailsDto>> {
    return siteContentManagementGetBlogDetails$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetBlogDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetBlogDetails$Plain(params?: SiteContentManagementGetBlogDetails$Plain$Params, context?: HttpContext): Observable<OutputGetBlogDetailsDto> {
    return this.siteContentManagementGetBlogDetails$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetBlogDetailsDto>): OutputGetBlogDetailsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetBlogDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetBlogDetails$Response(params?: SiteContentManagementGetBlogDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetBlogDetailsDto>> {
    return siteContentManagementGetBlogDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetBlogDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetBlogDetails(params?: SiteContentManagementGetBlogDetails$Params, context?: HttpContext): Observable<OutputGetBlogDetailsDto> {
    return this.siteContentManagementGetBlogDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetBlogDetailsDto>): OutputGetBlogDetailsDto => r.body)
    );
  }

  /** Path part for operation `siteContentManagementCreateBlog()` */
  static readonly SiteContentManagementCreateBlogPath = '/api/admin/SiteContentManagement/CreateBlog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateBlog$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateBlog$Plain$Response(params?: SiteContentManagementCreateBlog$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementCreateBlog$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateBlog$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateBlog$Plain(params?: SiteContentManagementCreateBlog$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementCreateBlog$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateBlog()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateBlog$Response(params?: SiteContentManagementCreateBlog$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementCreateBlog(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateBlog$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateBlog(params?: SiteContentManagementCreateBlog$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementCreateBlog$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementUpdateBlog()` */
  static readonly SiteContentManagementUpdateBlogPath = '/api/admin/SiteContentManagement/UpdateBlog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateBlog$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateBlog$Plain$Response(params?: SiteContentManagementUpdateBlog$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateBlog$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateBlog$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateBlog$Plain(params?: SiteContentManagementUpdateBlog$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateBlog$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateBlog()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateBlog$Response(params?: SiteContentManagementUpdateBlog$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateBlog(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateBlog$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateBlog(params?: SiteContentManagementUpdateBlog$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateBlog$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementDeleteBlog()` */
  static readonly SiteContentManagementDeleteBlogPath = '/api/admin/SiteContentManagement/DeleteBlog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteBlog$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteBlog$Plain$Response(params?: SiteContentManagementDeleteBlog$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementDeleteBlog$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteBlog$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteBlog$Plain(params?: SiteContentManagementDeleteBlog$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementDeleteBlog$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteBlog()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteBlog$Response(params?: SiteContentManagementDeleteBlog$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementDeleteBlog(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteBlog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteBlog(params?: SiteContentManagementDeleteBlog$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementDeleteBlog$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementCreateHeaderAdds()` */
  static readonly SiteContentManagementCreateHeaderAddsPath = '/api/admin/SiteContentManagement/CreateHeaderAdds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateHeaderAdds$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHeaderAdds$Plain$Response(params?: SiteContentManagementCreateHeaderAdds$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementCreateHeaderAdds$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateHeaderAdds$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHeaderAdds$Plain(params?: SiteContentManagementCreateHeaderAdds$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementCreateHeaderAdds$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateHeaderAdds()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHeaderAdds$Response(params?: SiteContentManagementCreateHeaderAdds$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementCreateHeaderAdds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateHeaderAdds$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHeaderAdds(params?: SiteContentManagementCreateHeaderAdds$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementCreateHeaderAdds$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementUpdateHeaderAdds()` */
  static readonly SiteContentManagementUpdateHeaderAddsPath = '/api/admin/SiteContentManagement/UpdateHeaderAdds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHeaderAdds$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderAdds$Plain$Response(params?: SiteContentManagementUpdateHeaderAdds$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateHeaderAdds$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHeaderAdds$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderAdds$Plain(params?: SiteContentManagementUpdateHeaderAdds$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateHeaderAdds$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHeaderAdds()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderAdds$Response(params?: SiteContentManagementUpdateHeaderAdds$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateHeaderAdds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHeaderAdds$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderAdds(params?: SiteContentManagementUpdateHeaderAdds$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateHeaderAdds$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementGetAllHeaderAdds()` */
  static readonly SiteContentManagementGetAllHeaderAddsPath = '/api/admin/SiteContentManagement/GetAllHeaderAdds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllHeaderAdds$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHeaderAdds$Plain$Response(params?: SiteContentManagementGetAllHeaderAdds$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutPutGetAllHeaderAddsDto>>> {
    return siteContentManagementGetAllHeaderAdds$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllHeaderAdds$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHeaderAdds$Plain(params?: SiteContentManagementGetAllHeaderAdds$Plain$Params, context?: HttpContext): Observable<Array<OutPutGetAllHeaderAddsDto>> {
    return this.siteContentManagementGetAllHeaderAdds$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetAllHeaderAddsDto>>): Array<OutPutGetAllHeaderAddsDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllHeaderAdds()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHeaderAdds$Response(params?: SiteContentManagementGetAllHeaderAdds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutPutGetAllHeaderAddsDto>>> {
    return siteContentManagementGetAllHeaderAdds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllHeaderAdds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHeaderAdds(params?: SiteContentManagementGetAllHeaderAdds$Params, context?: HttpContext): Observable<Array<OutPutGetAllHeaderAddsDto>> {
    return this.siteContentManagementGetAllHeaderAdds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetAllHeaderAddsDto>>): Array<OutPutGetAllHeaderAddsDto> => r.body)
    );
  }

  /** Path part for operation `siteContentManagementDeleteHeaderAdds()` */
  static readonly SiteContentManagementDeleteHeaderAddsPath = '/api/admin/SiteContentManagement/DeleteHeaderAdds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteHeaderAdds$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHeaderAdds$Plain$Response(params?: SiteContentManagementDeleteHeaderAdds$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementDeleteHeaderAdds$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteHeaderAdds$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHeaderAdds$Plain(params?: SiteContentManagementDeleteHeaderAdds$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementDeleteHeaderAdds$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteHeaderAdds()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHeaderAdds$Response(params?: SiteContentManagementDeleteHeaderAdds$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementDeleteHeaderAdds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteHeaderAdds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHeaderAdds(params?: SiteContentManagementDeleteHeaderAdds$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementDeleteHeaderAdds$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementCreateCategoryAds()` */
  static readonly SiteContentManagementCreateCategoryAdsPath = '/api/admin/SiteContentManagement/CreateCategoryAds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateCategoryAds$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateCategoryAds$Plain$Response(params?: SiteContentManagementCreateCategoryAds$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementCreateCategoryAds$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateCategoryAds$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateCategoryAds$Plain(params?: SiteContentManagementCreateCategoryAds$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementCreateCategoryAds$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateCategoryAds()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateCategoryAds$Response(params?: SiteContentManagementCreateCategoryAds$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementCreateCategoryAds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateCategoryAds$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateCategoryAds(params?: SiteContentManagementCreateCategoryAds$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementCreateCategoryAds$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementUpdateCategoryAds()` */
  static readonly SiteContentManagementUpdateCategoryAdsPath = '/api/admin/SiteContentManagement/UpdateCategoryAds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateCategoryAds$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateCategoryAds$Plain$Response(params?: SiteContentManagementUpdateCategoryAds$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateCategoryAds$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateCategoryAds$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateCategoryAds$Plain(params?: SiteContentManagementUpdateCategoryAds$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateCategoryAds$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateCategoryAds()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateCategoryAds$Response(params?: SiteContentManagementUpdateCategoryAds$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateCategoryAds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateCategoryAds$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateCategoryAds(params?: SiteContentManagementUpdateCategoryAds$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateCategoryAds$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementGetAllCategoryAds()` */
  static readonly SiteContentManagementGetAllCategoryAdsPath = '/api/admin/SiteContentManagement/GetAllCategoryAds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllCategoryAds$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllCategoryAds$Plain$Response(params?: SiteContentManagementGetAllCategoryAds$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutPutGetAllCategoryAdsDto>>> {
    return siteContentManagementGetAllCategoryAds$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllCategoryAds$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllCategoryAds$Plain(params?: SiteContentManagementGetAllCategoryAds$Plain$Params, context?: HttpContext): Observable<Array<OutPutGetAllCategoryAdsDto>> {
    return this.siteContentManagementGetAllCategoryAds$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetAllCategoryAdsDto>>): Array<OutPutGetAllCategoryAdsDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllCategoryAds()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllCategoryAds$Response(params?: SiteContentManagementGetAllCategoryAds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutPutGetAllCategoryAdsDto>>> {
    return siteContentManagementGetAllCategoryAds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllCategoryAds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllCategoryAds(params?: SiteContentManagementGetAllCategoryAds$Params, context?: HttpContext): Observable<Array<OutPutGetAllCategoryAdsDto>> {
    return this.siteContentManagementGetAllCategoryAds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetAllCategoryAdsDto>>): Array<OutPutGetAllCategoryAdsDto> => r.body)
    );
  }

  /** Path part for operation `siteContentManagementDeleteCategoryAds()` */
  static readonly SiteContentManagementDeleteCategoryAdsPath = '/api/admin/SiteContentManagement/DeleteCategoryAds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteCategoryAds$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteCategoryAds$Plain$Response(params?: SiteContentManagementDeleteCategoryAds$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementDeleteCategoryAds$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteCategoryAds$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteCategoryAds$Plain(params?: SiteContentManagementDeleteCategoryAds$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementDeleteCategoryAds$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteCategoryAds()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteCategoryAds$Response(params?: SiteContentManagementDeleteCategoryAds$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementDeleteCategoryAds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteCategoryAds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteCategoryAds(params?: SiteContentManagementDeleteCategoryAds$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementDeleteCategoryAds$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementCreateHeaderLink()` */
  static readonly SiteContentManagementCreateHeaderLinkPath = '/api/admin/SiteContentManagement/CreateHeaderLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateHeaderLink$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHeaderLink$Plain$Response(params?: SiteContentManagementCreateHeaderLink$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementCreateHeaderLink$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateHeaderLink$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHeaderLink$Plain(params?: SiteContentManagementCreateHeaderLink$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementCreateHeaderLink$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateHeaderLink()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHeaderLink$Response(params?: SiteContentManagementCreateHeaderLink$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementCreateHeaderLink(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateHeaderLink$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHeaderLink(params?: SiteContentManagementCreateHeaderLink$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementCreateHeaderLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementGetAllHeaderLinks()` */
  static readonly SiteContentManagementGetAllHeaderLinksPath = '/api/admin/SiteContentManagement/GetAllHeaderLinks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllHeaderLinks$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHeaderLinks$Plain$Response(params?: SiteContentManagementGetAllHeaderLinks$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutPutGetHeaderLinkDto>>> {
    return siteContentManagementGetAllHeaderLinks$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllHeaderLinks$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHeaderLinks$Plain(params?: SiteContentManagementGetAllHeaderLinks$Plain$Params, context?: HttpContext): Observable<Array<OutPutGetHeaderLinkDto>> {
    return this.siteContentManagementGetAllHeaderLinks$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetHeaderLinkDto>>): Array<OutPutGetHeaderLinkDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllHeaderLinks()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHeaderLinks$Response(params?: SiteContentManagementGetAllHeaderLinks$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutPutGetHeaderLinkDto>>> {
    return siteContentManagementGetAllHeaderLinks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllHeaderLinks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHeaderLinks(params?: SiteContentManagementGetAllHeaderLinks$Params, context?: HttpContext): Observable<Array<OutPutGetHeaderLinkDto>> {
    return this.siteContentManagementGetAllHeaderLinks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetHeaderLinkDto>>): Array<OutPutGetHeaderLinkDto> => r.body)
    );
  }

  /** Path part for operation `siteContentManagementGetHeaderLinksCategories()` */
  static readonly SiteContentManagementGetHeaderLinksCategoriesPath = '/api/admin/SiteContentManagement/GetHeaderLinksCategories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetHeaderLinksCategories$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetHeaderLinksCategories$Plain$Response(params?: SiteContentManagementGetHeaderLinksCategories$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutPutGetHeaderLinkCategoryDto>>> {
    return siteContentManagementGetHeaderLinksCategories$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetHeaderLinksCategories$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetHeaderLinksCategories$Plain(params?: SiteContentManagementGetHeaderLinksCategories$Plain$Params, context?: HttpContext): Observable<Array<OutPutGetHeaderLinkCategoryDto>> {
    return this.siteContentManagementGetHeaderLinksCategories$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetHeaderLinkCategoryDto>>): Array<OutPutGetHeaderLinkCategoryDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetHeaderLinksCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetHeaderLinksCategories$Response(params?: SiteContentManagementGetHeaderLinksCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutPutGetHeaderLinkCategoryDto>>> {
    return siteContentManagementGetHeaderLinksCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetHeaderLinksCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetHeaderLinksCategories(params?: SiteContentManagementGetHeaderLinksCategories$Params, context?: HttpContext): Observable<Array<OutPutGetHeaderLinkCategoryDto>> {
    return this.siteContentManagementGetHeaderLinksCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetHeaderLinkCategoryDto>>): Array<OutPutGetHeaderLinkCategoryDto> => r.body)
    );
  }

  /** Path part for operation `siteContentManagementDeleteHeaderLink()` */
  static readonly SiteContentManagementDeleteHeaderLinkPath = '/api/admin/SiteContentManagement/DeleteHeaderLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteHeaderLink$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHeaderLink$Plain$Response(params?: SiteContentManagementDeleteHeaderLink$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementDeleteHeaderLink$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteHeaderLink$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHeaderLink$Plain(params?: SiteContentManagementDeleteHeaderLink$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementDeleteHeaderLink$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteHeaderLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHeaderLink$Response(params?: SiteContentManagementDeleteHeaderLink$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementDeleteHeaderLink(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteHeaderLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHeaderLink(params?: SiteContentManagementDeleteHeaderLink$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementDeleteHeaderLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementUpdateHeaderLink()` */
  static readonly SiteContentManagementUpdateHeaderLinkPath = '/api/admin/SiteContentManagement/UpdateHeaderLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHeaderLink$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderLink$Plain$Response(params?: SiteContentManagementUpdateHeaderLink$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateHeaderLink$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHeaderLink$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderLink$Plain(params?: SiteContentManagementUpdateHeaderLink$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateHeaderLink$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHeaderLink()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderLink$Response(params?: SiteContentManagementUpdateHeaderLink$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateHeaderLink(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHeaderLink$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderLink(params?: SiteContentManagementUpdateHeaderLink$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateHeaderLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `siteContentManagementUpdateHeaderLinkIndexes()` */
  static readonly SiteContentManagementUpdateHeaderLinkIndexesPath = '/api/admin/SiteContentManagement/UpdateHeaderLinkIndexes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHeaderLinkIndexes$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderLinkIndexes$Plain$Response(params?: SiteContentManagementUpdateHeaderLinkIndexes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateHeaderLinkIndexes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHeaderLinkIndexes$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderLinkIndexes$Plain(params?: SiteContentManagementUpdateHeaderLinkIndexes$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateHeaderLinkIndexes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHeaderLinkIndexes()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderLinkIndexes$Response(params?: SiteContentManagementUpdateHeaderLinkIndexes$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return siteContentManagementUpdateHeaderLinkIndexes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHeaderLinkIndexes$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderLinkIndexes(params?: SiteContentManagementUpdateHeaderLinkIndexes$Params, context?: HttpContext): Observable<boolean> {
    return this.siteContentManagementUpdateHeaderLinkIndexes$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
